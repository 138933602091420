interface IIconButton extends React.HTMLAttributes<HTMLButtonElement> {
  color?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}
const IconButton = (props: IIconButton) => {
  const { icon, disabled, color, loading, ...rest } = props;
  return (
    <button className="icon-button" at-accent={color} disabled={disabled} {...rest}>
      {icon}
    </button>
  );
};

export default IconButton;
