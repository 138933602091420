import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

declare global  {
  var token : string;
  var userId : string;
  var host : string;
  var flowId : string;
  var title : string;
}

const params:any = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop:string) => searchParams.get(prop),
});

const { token, host, flowId, title } = params;
if ( host ){
  if ( !token ){
    window.location.href = host;
  } else {
    try {
      globalThis.token = atob(token)?.split(":")[1] ?? "";
      globalThis.userId = atob(token)?.split(":")[0] ?? "";
      globalThis.title = title.replace("Convert Omnichannel", "").replace("Omnichannel", "").replace("OmniChannel", "").replace("omnichannel", "").replace("•", "").replace("|", "").replace("-", "").replace(/\((.\d)\)/g, "") ?? "Empresa";
    } catch ( err ){
      globalThis.title = "Empresa"
      console.log(err);
    }
    globalThis.host = host;
    globalThis.flowId = flowId;
  }
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

/** Medir performance do app **/
reportWebVitals();
