interface IButton extends React.HTMLAttributes<HTMLButtonElement> {
  label?: string;
  outlined?: boolean;
  background?: string;
  shadow?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}
const Button = (props: IButton) => {
  const { label, outlined, background, shadow, loading, icon, disabled, ...rest } = props;
  return (
    <button className="at-button" at-bg={background ? background : null} at-outlined={outlined ? "true" : null} at-shadow={shadow ? background : null} at-loading={loading ? "true" : null} disabled={disabled} data-icon={icon && !label ? "true" : null} {...rest}>
      {icon}
      {label}
    </button>
  );
};

export default Button;
