import type { Node, Edge, ReactFlowJsonObject } from "reactflow";
import { log } from "../../services/utils";
import type { INodeMenuItem } from "../../components/Nodes";
import type { IFlowConfig } from "../../App";

const uuid = () => {
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  return uuid;
};

const dialog = ({ title = "", description = "", label = "", placeholder = "", buttonText = "OK", cancelText = "Cancelar", rawContent = "" }) => {
  return new Promise((resolve, reject) => {
    const dialog = document.createElement("div");
    dialog.setAttribute("at-active", "true");
    dialog.className = "at-modal";
    dialog.innerHTML = /*html*/ `
            <div class="at-modal-container app-dialog">
                <h2 class="at-u-mb1">${title}</h2>
                <p class="at-u-line-height-4">${description}</p>
                <div class="at-field">
                    <input type="text" placeholder="${placeholder}"/>
                    <label>${label}</label>
                </div>
                <div class="at-button-group">
                    <button class="at-button close" at-outlined="true">${cancelText}</button>
                    <button class="at-button next" at-bg="primary">${buttonText}</button>
                </div>
            </div>
        `;
    if (rawContent) {
      dialog.innerHTML = /*html*/ `
                <div class="at-modal-container app-dialog">
                    ${rawContent}
                    <div class="at-button-group at-u-mt2">
                        <button class="at-button close" at-outlined="true">${cancelText}</button>
                        <button class="at-button next" at-bg="primary">${buttonText}</button>
                    </div>
                </div>
            `;
    }
    const close = () => {
      dialog.removeAttribute("at-active");
      setTimeout(() => {
        dialog.remove();
      }, 0);
    };
    document.body.appendChild(dialog);
    dialog.querySelector("input")?.focus();
    dialog.querySelector("input")?.addEventListener("keydown", (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        close();
        const target = e?.target as HTMLInputElement;
        const value = target?.value;
        resolve(value);
      }
    });
    dialog.querySelector(".close")?.addEventListener("click", () => {
      close();
      resolve(null);
    });
    dialog.querySelector(".next")?.addEventListener("click", () => {
      close();
      const value = dialog.querySelector("input")?.value;
      resolve(value);
    });
  });
};

const contextMenu = (e: any, { title, description, displayHeader, items }: { title?: string; description?: string; displayHeader?: boolean; items: { type?: string; label?: string; icon?: string; onclick?: () => void }[] }) => {
  if (!e) return;
  e.stopPropagation();
  const configuration = {
    title: title !== undefined ? title : "Menu",
    description: description !== undefined ? description : "Por favor selecione alguma opção",
    displayHeader: displayHeader !== undefined ? displayHeader : false,
    items: items !== undefined ? items : [],
  };
  /** Criando o elemento */
  const template = configuration.displayHeader
    ? `
      <div class="at-context-menu-header">
          <h3 at-tx="white">${configuration.title}</h3>
          <small>${configuration.description}</small>
      </div>
        `
    : "";
  document.querySelectorAll(".at-context-menu")?.forEach((menu) => menu?.remove());
  let element = document.createElement("div");
  element.className = "at-context-menu";
  element.innerHTML = template;

  if (e.clientX >= window.innerWidth - 235) {
    element.style.right = window.innerWidth - e.clientX + "px";
  } else {
    element.style.left = e.clientX + 10 + "px";
  }
  element.style.top = e.clientY + 10 + "px";
  const backdrop = document.createElement("div");
  backdrop.className = "backdrop-effect";
  element.appendChild(backdrop);
  document.body.appendChild(element);
  document.addEventListener("click", (event: any) => {
    if (!event.target?.matches(".at-context-menu, .at-context-menu *")) {
      document.querySelectorAll(".at-context-menu")?.forEach((menu) => menu?.remove());
    }
  });
  const renderItems = () => {
    if (!configuration.items.length) return document.createElement("div");
    const template = document.createElement("div");
    template.className = "at-context-menu-items";
    configuration.items.forEach((item: any) => {
      const z = document.createElement("div");
      if (item.disabled) {
        z.setAttribute("data-disabled", "true");
      }
      if (item.type === "separator") {
        z.className = "at-context-menu-separator";
      } else {
        z.className = "at-context-menu-item";
        z.innerHTML = item.label;
        if (item.background) {
          z.setAttribute("at-bg", item.background);
        }
        if (item.icon) {
          const i = document.createElement("span");
          i.innerHTML = item.icon;
          z.prepend(i);
        }
        if (item.color) {
          z.setAttribute("at-tx", item.color);
        }
        z.addEventListener("click", () => {
          close();
          setTimeout(() => {
            item.onclick();
          }, 200);
        });
      }
      template.appendChild(z);
    });
    return template;
  };
  const close = () => {
    element?.remove();
  };
  if (configuration.items.length) {
    element.appendChild(renderItems());
  }
};

const showHelpModal = () => {
  dialog({
    rawContent: /*html*/ `
            <div>
                <h2>Atalhos</h2><hr>
                <div class="shortcut-container">
                  <div class="shortcut">
                      <div>
                          <label>Copiar </label>
                          <small>Copia bloco ou seleção</small>
                      </div>
                      <span><kbd>Ctrl</kbd> + <kbd>C</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Colar </label>
                          <small>Cola bloco ou seleção</small>
                      </div>
                      <span><kbd>Ctrl</kbd> + <kbd>V</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Desfazer </label>
                          <small>Desfaz a ultima ação</small>
                      </div>
                      <span><kbd>Ctrl</kbd> + <kbd>Z</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Refazer </label>
                          <small>Volta o ultimo "desfazer"</small>
                      </div>
                      <span><kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>Z</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Busca / Comandos</label>
                          <small>Abre o menu de busca e comandos</small>
                      </div>
                      <span><kbd>Ctrl</kbd> + <kbd>K</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Salvar</label>
                          <small>Salva o fluxo atual</small>
                      </div>
                      <span><kbd>Ctrl</kbd> + <kbd>S</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Publicar</label>
                          <small>Salva e publica o fluxo atual</small>
                      </div>
                      <span><kbd>Ctrl</kbd>+ <kbd>Shift</kbd> + <kbd>S</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Download</label>
                          <small>Salva o fluxo atual como imagem</small>
                      </div>
                      <span><kbd>Ctrl</kbd>+ <kbd>Shift</kbd> + <kbd>P</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Testar no WhatsApp</label>
                          <small>Abre o fluxo atual no WhatsApp Web</small>
                      </div>
                      <span><kbd>Ctrl</kbd>+ <kbd>Shift</kbd> + <kbd>1</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Testar no Telegram</label>
                          <small>Abre o fluxo atual no Telegram Web</small>
                      </div>
                      <span><kbd>Ctrl</kbd>+ <kbd>Shift</kbd> + <kbd>2</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Visualizar Erros</label>
                          <small>Abre o painel de erros e alertas</small>
                      </div>
                      <span><kbd>Ctrl</kbd>+ <kbd>Shift</kbd> + <kbd>E</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Painel de Ajuda</label>
                          <small>Abre o painel de ajuda e dicas</small>
                      </div>
                      <span><kbd>Ctrl</kbd>+ <kbd>Shift</kbd> + <kbd>H</kbd></span>
                  </div>
                  
                  <div class="shortcut">
                      <div>
                          <label>Deletar Bloco</label>
                          <small>Com um bloco selecionado</small>
                      </div>
                      <span><kbd>Delete</kbd> ou <kbd>Backspace</kbd></span>
                  </div>
                  <div class="shortcut">
                      <div>
                          <label>Modo de seleção</label>
                          <small>Selecionar enquanto pressiona Shift</small>
                      </div>
                      <span><kbd>Shift</kbd></span>
                  </div>
                  <div class="shortcut at-u-border-none">
                      <div>
                          <label>Editar bloco ao soltar</label>
                          <small>Soltar bloco enquanto pressiona Shift</small>
                      </div>
                      <span><kbd>Shift</kbd></span>
                  </div>
                </div>

            </div>
        `,
  });
};

const getVariables = async () => {
  try {
    const response = await fetch("https://" + globalThis.host + "/api/v1/livechat/all-fields", {
      headers: {
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
    });
    if (response.status !== 200) {
      throw response;
    }
    const data = await response.json();
    if (data?.success) {
      return data?.customFields;
    } else {
      return [];
    }
  } catch (err) {
    log("error", "Erro ao buscar variáveis!", err);
    return [];
  }
};

const getFlowById = async (id: string = "") => {
  try {
    const response = await fetch("https://" + globalThis.host + `/api/v1/livechat/flows/${id}`, {
      headers: {
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
    });
    const data = await response.json();
    if (response.status !== 200) {
      throw response;
    }
    updatePageTitle(data.flows);
    return data.flows;
  } catch (err) {
    log("error", "Erro ao buscar fluxo!", err);
    return null;
  }
};

const getUsers = async (q: string = "") => {
  try {
    const response = await fetch("https://" + globalThis.host + `/api/v1/users.autocomplete/?selector={"term":"${q}","conditions":{"statusLivechat":{"$in":["available","unavailable"]}}}`, {
      headers: {
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
    });
    if (response.status !== 200) {
      throw response;
    }
    const data = await response.json();
    if (data?.success) {
      return data?.items.sort((a: any, b: any) => a?.name.localeCompare(b?.name));
    } else {
      return [];
    }
  } catch (err) {
    log("error", "Erro ao buscar usuários!", err);
    return [];
  }
};

const getDepartments = async (q: string = "") => {
  try {
    const response = await fetch("https://" + globalThis.host + `/api/v1/livechat/department/?text=${q}&count=1000`, {
      headers: {
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
    });
    const data = await response.json();
    if (response.status !== 200) {
      throw response;
    }
    if (data?.success) {
      return data?.departments;
    } else {
      return [];
    }
  } catch (err) {
    log("error", "Erro ao buscar departamentos!", err);
    return [];
  }
};

const getFlows = async () => {
  try {
    const response = await fetch("https://" + globalThis.host + "/api/v1/livechat/flows?fields={%22_id%22:1,%22nome%22:1,%22descricao%22:1,%22_updatedAt%22:1}&text=&sort={%22_updatedAt%22:1,%22usernames%22:1}&count=1000", {
      headers: {
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
    });
    if (response.status !== 200) {
      throw response;
    }
    const data = await response.json();
    if (data?.success) {
      return data?.flows;
    } else {
      return [];
    }
  } catch (err) {
    log("error", "Erro ao buscar fluxos!", err);
    return [];
  }
};

const getTabulacoes = async () => {
  try {
    const response = await fetch("https://" + globalThis.host + "/api/v1/livechat/tabulacao?fields={%22_id%22:1,%22tabulacao%22:1,%22statusTabulacao%22:1}&text=&sort={%22tabulacao%22:1,%22usernames%22:1}&count=1000", {
      headers: {
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
    });
    if (response.status !== 200) {
      throw response;
    }
    const data = await response.json();
    if (data?.success) {
      return data?.tabulacao;
    } else {
      return [];
    }
  } catch (err) {
    log("error", "Erro ao buscar tabulações!", err);
    return [];
  }
};

const getChannels = async () => {
  try {
    const response = await fetch("https://" + globalThis.host + "/api/v1/livechat/channels?fields={%22_id%22:1,%22descricao%22:1,%22identificador%22:1,%22tipo%22:1,%22status%22:1,%22_updatedAt%22:1}&text=&sort={%22identificador%22:1}&count=1000", {
      headers: {
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
    });
    if (response.status !== 200) {
      throw response;
    }
    const data = await response.json();
    if (data?.success) {
      return data?.channels;
    } else {
      return [];
    }
  } catch (err) {
    log("error", "Erro ao buscar fluxos!", err);
    return [];
  }
};

const getTimegroups = async () => {
  try {
    const response = await fetch("https://" + globalThis.host + "/api/v1/livechat/timegroups?fields={%22_id%22:1,%22setor%22:1,%22grupo%22:1,%22titulo%22:1}&text=&sort={%22grupo%22:1,%22usernames%22:1}&count=1000", {
      headers: {
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
    });
    if (response.status !== 200) {
      throw response;
    }
    const data = await response.json();
    if (data?.success) {
      return data?.timegroups;
    } else {
      return [];
    }
  } catch (err) {
    log("error", "Erro ao buscar grupos de horário!", err);
    return [];
  }
};

const saveFlow = async (store: any, flowData: IFlowConfig, publish: boolean = false) => {
  if (!publish) {
    try {
      const flow = await getFlowById(flowId);
      const { data } = JSON.parse(flow?.objeto);
      store.data = data;
    } catch (err) {
      store.data = {};
    }
  }
  const payload = {
    msg: "method",
    id: "22",
    method: "livechat:saveFlows",
    params: [
      globalThis?.flowId,
      {
        nome: flowData.nome,
        descricao: flowData.descricao,
        objeto: JSON.stringify(store),
      },
    ],
  };
  try {
    const response = await fetch("https://" + globalThis.host + "/api/v1/method.call/livechat%3AsaveFlows", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
      body: JSON.stringify({
        message: JSON.stringify(payload),
      }),
    });
    if (response.status !== 200) {
      throw response;
    }
    updatePageTitle(flowData);
    const data = await response.json();
    const message = JSON.parse(data.message);
    if (message.error) {
      throw message;
    } else {
      return message?.result || true;
    }
  } catch (err) {
    log("error", "Erro ao salvar fluxo!", err);
    return null;
  }
};

const createVariable = async (variable: any) => {
  const payload = {
    msg: "method",
    id: "20",
    method: "livechat:saveCustomField",
    params: [
      null,
      {
        ...variable,
        regexp: "",
        options: "",
        visibility: variable?.visibility ? "visible" : "hidden",
      },
    ],
  };
  try {
    const response = await fetch("https://" + globalThis.host + "/api/v1/method.call/livechat%3AsaveCustomField", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": globalThis.token,
        "X-User-Id": globalThis.userId,
      },
      body: JSON.stringify({
        message: JSON.stringify(payload),
      }),
    });
    if (response.status !== 200) {
      throw response;
    }
    const data = await response.json();
    const message = JSON.parse(data.message);
    if (message.error) {
      throw message;
    } else {
      return message?.result || true;
    }
  } catch (err) {
    log("error", "Erro ao cadastrar variável!", err);
    return null;
  }
};

const exportFlow = (flow: ReactFlowJsonObject) => {
  try {
    const data: any = {};
    const errors: any = [];
    document.querySelectorAll(".node-status")?.forEach((node) => {
      node?.removeAttribute("data-error-count");
      node?.removeAttribute("data-warning-count");
    });
    const remove = ["user", "department", "anchor", "node", "flow", "timegroup"];
    flow?.nodes?.forEach((n: Node) => {
      const node = JSON.parse(JSON.stringify(n));
      if (node?.data?.id === "comment-node") return;
      const connection = getNodeConnection(node?.id, flow);
      let props = node?.data?.props ?? {};
      const subhandles = ["user-input", "new-http-request", "close-chat", "multiple-conditions", "condition-initial-message", "date-hour-block", "transfer", "menu", "send-email"];
      if (!connection && !subhandles.includes(node?.data?.id) && !subhandles.includes(node?.data?.category)) {
        errors.push({
          level: 2,
          message: `O bloco '${props?.nodeTitle}' não foi conectado à nenhum bloco`,
          nodeId: node?.id,
          node: node,
          handleId: null,
        });
      }
      if (props?.text) {
        props.text = convertToMarkdown(props?.text) ?? props.text;
      }
      if (node?.id === "start") {
        data[node?.id] = {
          goto: connection?.target,
          type: "start",
        };
      } else {
        if (node?.data?.category === "action") {
          props.goto = connection?.target;
          if (node?.data?.id === "user-input") {
            props.type = "input";
            props.goto = getHandleConnection(node?.id, flow, "response")?.target;
            props.gotoTimeout = getHandleConnection(node?.id, flow, "timeout")?.target;
            if (!props.goto) {
              errors.push({
                level: 2,
                message: `O evento 'Respondido' não foi conectado à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-response`,
              });
            }
            if (!props.gotoTimeout) {
              errors.push({
                level: 2,
                message: `O evento 'Timeout' não foi conectado à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-timeout`,
              });
            }
          } else if (node?.data?.id === "new-http-request") {
            props.goto = getHandleConnection(node?.id, flow, "request-ok")?.target;
            props.exception = getHandleConnection(node?.id, flow, "request-failed")?.target;
            props.gotoTimeout = getHandleConnection(node?.id, flow, "request-timeout")?.target;
            if (!props.goto) {
              errors.push({
                level: 2,
                message: `O evento 'Sucesso' não foi conectado à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-request-success`,
              });
            }
            if (!props.exception) {
              errors.push({
                level: 2,
                message: `O evento 'Falhou' não foi conectado à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-request-failed`,
              });
            }
            if (!props.gotoTimeout) {
              errors.push({
                level: 2,
                message: `O evento 'Timeout' não foi conectado à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-request-timeout`,
              });
            }
            try {
              if (props.contentType === "raw") {
                props.data = JSON.parse(props.rawData);
              }
            } catch (err) {
              errors.push({
                level: 2,
                message: `O JSON no bloco de request é invalido`,
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
              log("error", "O JSON no bloco de request é invalido", err);
            }
            //Requisição HTTP
          } else if (node?.data?.id === "close-chat") {
            //Encerrar o chat
            props.type = "close";
          } else if (node?.data?.id === "send-email") {
            //Enviar email para o contato
            props.type = "send-email";
            props.goto = getHandleConnection(node?.id, flow, "email-sent")?.target;
            props.exception = getHandleConnection(node?.id, flow, "email-failed")?.target;
            if (!props.goto) {
              errors.push({
                level: 2,
                message: `O evento 'Email enviado' não foi conectado à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-email-sent`,
              });
            }
            if (!props.exception) {
              errors.push({
                level: 2,
                message: `O evento 'Falha no envio' não foi conectado à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-email-failed`,
              });
            }
            if (!props.subject.replace(/\s/g, "")) {
              errors.push({
                level: 1,
                message: "Nenhum assunto foi informado para o e-mail",
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
            }
            if (!props.body.replace(/\s/g, "")) {
              errors.push({
                level: 1,
                message: "O corpo do e-mail está vazio",
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
            }
            if (!props.to?.length) {
              errors.push({
                level: 1,
                message: "Nenhum destinatário foi informado para o e-mail",
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
            }
          } else if (node?.data?.id === "set-variable") {
            //Definir um valor para uma variável
            props.type = "set-variable";
            if (props.variable?.type === "textarea") {
              props.value = convertToMarkdown(props.value) ?? props.value;
            }
            if (!props.key) {
              errors.push({
                level: 1,
                message: `Nenhuma variável está sendo definida no bloco`,
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
            }
          } else if (node?.data?.id === "wait-delay") {
            //Definir um valor para uma variável
            props.type = "delay";
            props.gotoTimeout = props.goto;
          } else if (node?.data?.id === "record-log") {
            //Definir um valor para uma variável
            props.type = "log";
          } else if (node?.data?.id === "send-attachment-image" || node?.data?.id === "send-attachment-file") {
            props.type = "send-attachment";
          } else if (node?.data?.id === "send-new-message") {
            props.type = "message";
            if (props.text.replace(/\s/g, "") === "") {
              errors.push({
                level: 1,
                message: `Nenhuma mensagem foi preenchida no bloco`,
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
            }
          } else if (node?.data?.id === "add-tags") {
            if (!props.tags.length) {
              errors.push({
                level: 1,
                message: `Nenhuma tag foi adicionada`,
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
            }
            props = {
              ...props,
              ...{
                type: "set-variable",
                key: "$tags",
                value: props?.tags?.join(","),
                stringAction: "none",
                stringActionValue: "",
              },
            };
          } else if (node?.data?.id === "set-tabulacao") {
            if (!props.tabulacaoId) {
              errors.push({
                level: 1,
                message: `Nenhuma tabulação foi selecionada`,
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
            }
            props = {
              ...props,
              ...{
                type: "set-tabulacao",
              },
            };
          } else if (node?.data?.id === "send-location") {
            if (props.latitude === "" || props.longitude === "") {
              errors.push({
                level: 1,
                message: `Informe latitude e longitude`,
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
            }
            props = {
              ...props,
              ...{
                type: "send-location",
              },
            };
          }
        } else if (node?.data?.category === "transfer") {
          let errorArea = "";
          props.type = "transfer";

          if (node?.data?.id === "transfer-to-user") {
            if (props?.userId === "variable") props.userId = props?.variableUserId;
            if (!props?.userId) errorArea = "Usuário";
          } else if (node?.data?.id === "transfer-to-department") {
            if (props?.userId === "variable") props.userId = props?.variableUserId;
            if (props?.departmentId === "variable") props.departmentId = props?.variableDepartmentId;
            if (!props?.departmentId) errorArea = "Departamento";
          } else if (node?.data?.id === "transfer-to-anchor") {
            props.type = "transfer-to-anchor";
            props.goto = props?.anchorId ?? "";
            if (!props?.anchorId) errorArea = "Âncora";
          } else if (node?.data?.id === "transfer-to-flow") {
            if (!props?.flowId) errorArea = "Fluxo";
          } else if (node?.data?.id === "transfer-to-node") {
            props.type = "transfer-to-node";
            props.goto = props?.nodeId ?? "";
            if (!props?.nodeId) errorArea = "Bloco";
          }
          if (props.userId === "none") {
            props.userId = "";
            props.agentId = props.userId;
          }
          if (props.userId) {
            props.agentId = props.userId;
          }
          if (errorArea) {
            errors.push({
              level: 2,
              message: `Nenhum(a) ${errorArea} foi selecionado(a) no bloco '${props?.nodeTitle}'`,
              nodeId: node?.id,
              node: node,
              handleId: null,
            });
          }
        } else if (node?.data?.category === "menu") {
          props.type = "menu";
          props.invalidOptionText = convertToMarkdown(props.invalidOptionText) ?? props.invalidOptionText;
          if (props.text.replace(/\s/g, "") === "") {
            errors.push({
              level: 1,
              message: `Nenhuma mensagem foi preenchida para à prévia do menu`,
              nodeId: node?.id,
              node: node,
              handleId: null,
            });
          }
          props.options = props?.options?.map((option: INodeMenuItem, index: number) => {
            const menuConnection = getHandleConnection(node?.id, flow, `menu-option-${option.id}`);
            if (!menuConnection) {
              // A opção nao leva a lugar algum, nao foi conectada
              errors.push({
                level: 2,
                message: `A opção '${option.text}' não foi conectada à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-menu-option-${option.id}`,
              });
            }
            const keywords = option.keywords || [];
            if (option.id && !keywords.includes(option.id as string)) {
              keywords.unshift(option.id as string);
              if (option.static && option.staticIndex !== undefined) {
                keywords.unshift(String(option.staticIndex));
              } else {
                keywords.unshift(String(index + 1));
              }
            }
            return {
              id: option.id,
              text: (props?.mode === "text" ? `${option.static ? option.staticIndex : index + 1} • ` : "") + option?.text,
              palavrasChave: keywords,
              goto: menuConnection?.target,
              description: option.description ? option.description : false,
            };
          });
          if (props.timeout > 0) {
            const menuConnection = getHandleConnection(node?.id, flow, "menu-timeout");
            if (!menuConnection) {
              // Tem timeout mas nao conectou o node
              errors.push({
                level: 2,
                message: `Caso o usuário não selecionar nada após ${props?.timeout} segundos, o atendimento não será transferido pois o bloco (Timeout) não foi conectado`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-menu-timeout`,
              });
            }
            props.gotoTimeout = menuConnection?.target;
          }
          if (props.maxRetries > 0) {
            const menuConnection = getHandleConnection(node?.id, flow, "menu-invalid");
            if (!menuConnection) {
              // Tem um maximo de tentativas mas nao conectou o node
              errors.push({
                level: 2,
                message: `Caso o usuário selecionar uma opção inválida mais do que ${props.maxRetries} ${props.maxRetries > 1 ? "vezes" : "vez"}, o atendimento não será transferido pois o bloco (Inválido) não foi conectado`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-menu-invalid`,
              });
            }
            props.gotoInvalid = menuConnection?.target;
          }
        } else if (node?.data?.category === "condition") {
          if (node?.data?.id === "multiple-conditions" || node?.data?.id === "condition-initial-message") {
            props.type = "condition";
            props.options = props?.options?.map((option: any, index: number) => {
              const menuConnection = getHandleConnection(node?.id, flow, `condition-${index}`);
              if (!menuConnection) {
                // A opção nao leva a lugar algum, nao foi conectada
                errors.push({
                  level: 2,
                  message: `A condição '${option.text}' não foi conectada à nenhum bloco`,
                  nodeId: node?.id,
                  node: node,
                  handleId: `handle-${node?.id}-condition-${index}`,
                });
              }
              return {
                palavrasChave: option.condition !== "regex" ? option?.keywords : [option.regex],
                regex: option.condition === "regex",
                goto: menuConnection?.target,
                condition: option.condition,
              };
            });
            props.fallback = getHandleConnection(node?.id, flow, `false-condition`)?.target;
          } else if (node?.data?.id === "date-hour-block") {
            props.type = "condition_time";
            props.eval = props.timegroupId ?? "";
            if (!props.timegroupId) {
              errors.push({
                level: 2,
                message: `Nenhuma regra de horário foi selecionada no bloco '${props?.nodeTitle}'`,
                nodeId: node?.id,
                node: node,
                handleId: null,
              });
            }
            props.goto = getHandleConnection(node?.id, flow, `condition-0`)?.target;
            props.fallback = getHandleConnection(node?.id, flow, `false-condition`)?.target;
            if (!props.goto) {
              errors.push({
                level: 2,
                message: `A condição 'Verdadeiro' não foi conectado à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-condition-0`,
              });
            }
            if (!props.fallback) {
              errors.push({
                level: 2,
                message: `A condição 'Falso' não foi conectado à nenhum bloco`,
                nodeId: node?.id,
                node: node,
                handleId: `handle-${node?.id}-false-condition`,
              });
            }
          }
        } else if (node?.data?.category === "other") {
          if (node?.data?.id === "anchor-node") {
            props.goto = connection?.target;
            props.type = "anchor";
          }
        }
        if (node?.data?.id !== "comment-node") {
          remove.forEach((key: string) => delete props[key]);
          data[node?.id] = props;
        }
      }
    });
    return { data, errors };
  } catch (err) {
    log("error", "Erro ao converter fluxo de atendimento!", err);
    return { data: { error: true }, errors: [] };
  }
};

const convertToMarkdown = (text: string) => {
  if (!text) return;
  let msg = text
    .replaceAll("<strong>", "*/inicio_bold/")
    .replaceAll("</strong>", "*/fim_bold/")
    .replaceAll(" */fim_bold/", "* ")
    .replaceAll("*/fim_bold/", "*")
    .replaceAll("*/inicio_bold/ ", " *")
    .replaceAll("*/inicio_bold/", "*")
    .replaceAll("<em>", "_")
    .replaceAll("</em>", "_")
    .replaceAll("<p></p>", "\n")
    .replaceAll("</p>", "\n")
    .replaceAll("&amp;", "&")
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">");
  if (msg.includes("\n")) {
    msg = msg.substring(0, msg.lastIndexOf("\n"));
  }

  return msg;
};

const getNodeConnection = (nodeId: string, flow: ReactFlowJsonObject) => {
  const edge = flow?.edges?.find((edge: Edge) => edge?.source === nodeId);
  if (edge) return edge;
  return null;
};

const getHandleConnection = (nodeId: string, flow: ReactFlowJsonObject, handleId: string) => {
  const edge = flow?.edges?.find((edge: Edge) => edge?.source === nodeId && edge?.sourceHandle === `handle-${nodeId}-${handleId}`);
  if (edge) return edge;
  return null;
};

const updatePageTitle = (flow: IFlowConfig) => {
  document.title = `Flow Builder ${flow?.nome ? "|" : ""} ${flow?.nome}`;
};

export { uuid, dialog, contextMenu, getVariables, getUsers, getDepartments, getTimegroups, getFlowById, getFlows, getTabulacoes, getChannels, exportFlow, createVariable, saveFlow, showHelpModal };
