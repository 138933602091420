import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

interface ICustomSearchableSelectItem {
  id: string;
  label?: string;
  filterBy?: string;
  description?: string;
  value: string;
  leftItem?: ReactNode;
  rightItem?: ReactNode;
  data?: any;
}

interface ICustomSearchableSelect {
  value?: string;
  onChange: (value: string, option: ICustomSearchableSelectItem) => void;
  options?: Array<ICustomSearchableSelectItem>;
}

const CustomSearchableSelect = (props: ICustomSearchableSelect) => {
  const { value, onChange, options = [] } = props;
  const [selectedOption, setSelectedOption] = useState<ICustomSearchableSelectItem>({ id: "", value: "" });
  const [query, setQuery] = useState("");
  const searchInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value) {
      const o = options.find((option: ICustomSearchableSelectItem) => option.value === value);
      if (o) setSelectedOption(o);
    }
  }, []);

  useEffect(() => {
    if (value) {
      const o = options.find((option: ICustomSearchableSelectItem) => option.value === value);
      if (o) setSelectedOption(o);
    }
  }, [options.length]);

  useEffect(() => {
    onChange(selectedOption.value, selectedOption);
  }, [selectedOption]);

  return (
    <div className="at-select at-select-searchable">
      <Combobox
        value={selectedOption}
        onChange={(option: ICustomSearchableSelectItem) => {
          setSelectedOption(option);
          searchInput?.current?.blur();
          onChange(option.value, option);
        }}
      >
        {({ open }) => (
          <>
            <Combobox.Button
              as="div"
              onClick={(e: MouseEvent) => {
                if (open) {
                  e.preventDefault();
                }
              }}
              className="at-select-input-container"
            >
              <Combobox.Input
                ref={searchInput}
                onChange={(event) => setQuery(event.target.value)}
                onFocus={() => setQuery("")}
                displayValue={(option: ICustomSearchableSelectItem) => {
                  if (open || !option?.label) {
                    return "";
                  }
                  return option?.label;
                }}
                key={open as any}
                className="at-select-input"
                placeholder={"Buscar"}
              />
              <ChevronUpDownIcon className="chevron" />
            </Combobox.Button>
            {open && !options.length ? (
              <motion.div className="at-select-options" initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <li data-disabled="true">Nenhum resultado encontrado!</li>
              </motion.div>
            ) : (
              <Combobox.Options className="at-select-options">
                {options
                  .filter((option: ICustomSearchableSelectItem) => option?.filterBy?.includes(query.toLowerCase().replace(/\s/g, "")) || query === "" || option.id === selectedOption.id)
                  .map((option: ICustomSearchableSelectItem) => (
                    <Combobox.Option key={option.id} value={option} as="div">
                      {({ active, selected }) => (
                        <>
                          <li data-selected={selected} data-active={active}>
                            {option.leftItem && option.leftItem}
                            <div className="at-u-flex-grow">
                              <span>{option.label}</span>
                              <small>{option.description}</small>
                            </div>
                            {selected && <CheckIcon className="selected-icon" />}
                            {option.rightItem && <div>{option.rightItem}</div>}
                          </li>
                        </>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            )}
          </>
        )}
      </Combobox>
    </div>
  );
};

export default CustomSearchableSelect;
