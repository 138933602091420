import type { ReactNode } from "react";
import type { INodeData } from "./";
import { createElement } from "react";
import { cards } from "../../services/constants";
import { TbCopy, TbEdit, TbTrash, TbDotsVertical, TbClipboard } from "react-icons/tb";
import { Dropdown } from "../";
import { BiErrorAlt } from "react-icons/bi";
import { TiWarningOutline } from "react-icons/ti";

export interface ITitle {
  data: INodeData;
  preTitle?: ReactNode | null;
}
const Title = (props: ITitle) => {
  const { data, preTitle } = props;
  const menuItems = [
    { label: "Editar Bloco", icon: <TbEdit /> },
    { label: "Duplicar Bloco", icon: <TbCopy /> },
    { label: "Copiar Bloco", icon: <TbClipboard /> },
    { label: "Deletar Bloco", icon: <TbTrash /> },
  ];
  return (
    <>
      <div className="node-title">
        <div className="head">
          {data?.integration ? <img className="icon" alt={data?.integration?.origin} src={require(`../../assets/icons/${data?.integration?.origin}.png`)} /> : <div className="icon">{cards.find((c: INodeData) => c.id === data?.id)?.icon && createElement(cards.find((c: INodeData) => c.id === data?.id)?.icon, { color: "#FFF" })}</div>}
          {preTitle}
          <small>{data?.props?.nodeTitle}</small>
          <Dropdown
            items={menuItems}
            button={
              <div className="dots">
                <TbDotsVertical />
              </div>
            }
          />
        </div>
        <div className="status">
          <div className="at-badge node-status data-error-count" at-bg="danger">
            <BiErrorAlt />
          </div>
          <div className="at-badge node-status data-warning-count" at-bg="amber-300" at-tx="slate-800">
            <TiWarningOutline />
          </div>
        </div>
      </div>
    </>
  );
};

export default Title;
