import type { ReactNode } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { addHighlight, dropBlock, removeHighlights } from ".";

export interface IItemHandle {
  label?: string;
  icon?: ReactNode;
  nodeId: string;
  background?: string;
  textColor?: string;
  handleId?: string;
}
const ItemHandle = (props: IItemHandle) => {
  const { label, icon, nodeId, background, textColor, handleId } = props;
  const flow = useReactFlow();
  return (
    <>
      <div className="node-item" data-item-handle-id={`handle-${nodeId}-${handleId}`} at-bg={background} at-tx={textColor} onDragEnter={(e) => addHighlight(e, nodeId, "handle", handleId)} onDragLeave={(e) => removeHighlights(e, nodeId, "handle", handleId)} onDrop={(e) => dropBlock(e, nodeId, "handle", flow, handleId)}>
        {icon} <small>{label}</small>
        <Handle id={`handle-${nodeId}-${handleId}`} type="source" position={Position.Bottom} />
      </div>
    </>
  );
};

export default ItemHandle;
