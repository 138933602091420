import type { ReactNode } from "react";
import type { Edge, Node, ReactFlowInstance } from "reactflow";
import { MarkerType } from "reactflow";
import { uuid } from "../../services/methods";
import { log } from "../../services/utils";
/** Importando todos os nodes */
import Action from "./Action";
import Start from "./Start";
import Menu from "./Menu";
import Transfer from "./Transfer";
import Condition from "./Condition";
import Anchor from "./Anchor";
import Comment from "./Comment";
/** Importando utils de nodes */
import Title from "./Title";
import ItemHandle from "./ItemHandle";
import { IconType } from "react-icons/lib";

/** Adiciona um highlight no elemento */
const addHighlight = (e: any, nodeId: string, typeId: string, handleId?: string) => {
  const target = e?.target as HTMLElement;
  const ignore = ["new-http-request", "close-chat", "user-input", "send-email"];
  if (ignore.includes(typeId)) return;
  target?.setAttribute("data-highlighted", "true");
};

/** Remove um highlight no elemento */
const removeHighlights = (e: any, nodeId: string, typeId: string, handleId?: string) => {
  const ignore = ["new-http-request", "close-chat", "user-input", "send-email"];
  if (ignore.includes(typeId)) return;
  document.querySelectorAll("[data-highlighted]")?.forEach((el) => el.removeAttribute("data-highlighted"));
};

/** Cria o bloco e vincula ele ao node */
const dropBlock = (e: any, nodeId: string, typeId: string, flow: ReactFlowInstance, handleId?: string) => {
  /** Testando se o node ja foi conectado com outro */
  const checkConnection = (edges: Edge[], node: INode, handleId: string = "bottom") => {
    const connections = edges.filter((edge: Edge) => {
      return edge?.source === node?.id;
    });
    const hasHandles = ["user-input", "new-http-request", "multiple-conditions", "condition-initial-message", "date-hour-block", "yes-or-no-menu", "inactive-test-menu", "custom-menu", "button-menu", "list-menu", "text-menu", "send-email"];
    if (hasHandles.includes(node?.data?.id)) {
      const handleConnections = edges.filter((edge: Edge) => {
        return edge?.sourceHandle === `handle-${nodeId}-${handleId}`;
      });
      return handleConnections.length > 0;
    } else {
      return connections.length > 0;
    }
  };
  removeHighlights(e, nodeId, typeId, handleId);
  const ignore = ["new-http-request", "close-chat", "user-input", "send-email"];
  if (ignore.includes(typeId)) return;
  e.stopPropagation();
  const reactFlowWrapper = document.querySelector(".flowbuilder-container");
  if (flow && nodeId && e.dataTransfer && reactFlowWrapper) {
    const { setNodes, getNode, setEdges, getEdges } = flow;
    const edges = getEdges();
    const sourceNode = getNode(nodeId);
    if (!sourceNode) return;
    const reactFlowBounds = reactFlowWrapper.getBoundingClientRect();
    let type = e.dataTransfer.getData("application/reactflow");
    const item = JSON.parse(e.dataTransfer.getData("application/reactflow/item"));

    if (typeof type === "undefined" || !type) return;
    if (type === "other-node") {
      type = item.id;
    }

    const dropzone = getNode(nodeId);
    if (!dropzone) return;

    const w = dropzone?.width ? dropzone?.width : 0;
    const position = flow.project({
      x: e?.clientX - reactFlowBounds.left - (w > 400 ? 400 : w) / 2,
      y: 0,
    });

    position.y = dropzone?.position?.y + (dropzone?.height || 0) + 125;
    if (!handleId) {
      position.x = dropzone?.position?.x;
    }

    const node = {
      id: uuid(),
      type,
      position: position,
      positionAbsolute: position,
      data: item,
      ...item.options,
    };

    setNodes((nds) => nds.concat(node));
    log("flowbuilder", "Novo node adicionado ao Flow", node);
    if (handleId) {
      const ed = {
        id: `edge-${uuid()}`,
        source: nodeId,
        target: node.id,
        sourceHandle: `handle-${nodeId}-${handleId}`,
        targetHandle: `handle-${node.id}-top`,
        animated: true,
        type: "smoothstep",
        markerEnd: {
          type: MarkerType.Arrow,
        },
      };
      if (!checkConnection(edges, sourceNode, handleId)) {
        setEdges((eds) => eds.concat(ed as Edge));
        log("success", "Dois blocos foram conectados\n", nodeId + " => ", node.id);
      } else {
        log("warning", "Usuário tentou conectar um bloco já conectado", nodeId + " => ", node.id);
      }
    } else {
      const ed = {
        id: `edge-${uuid()}`,
        source: nodeId,
        target: node.id,
        sourceHandle: `handle-${nodeId}-bottom`,
        targetHandle: `handle-${node.id}-top`,
        animated: true,
        type: "smoothstep",
        markerEnd: {
          type: MarkerType.Arrow,
        },
      };
      if (!checkConnection(edges, sourceNode)) {
        log("success", "Dois blocos foram conectados\n", nodeId + " => ", node.id);
        setEdges((eds) => eds.concat(ed as Edge));
      } else {
        log("warning", "Usuário tentou conectar um bloco já conectado", nodeId + " => ", node.id);
      }
    }
  }
};

export { addHighlight, removeHighlights, dropBlock };

export { Title, ItemHandle };

export { Action, Menu, Transfer, Condition, Anchor, Comment, Start };

export interface INode extends Node {
  data: INodeData;
}

export interface INodeData {
  id: string;
  label: string;
  description?: string;
  category: "action" | "transfer" | "menu" | "condition" | "other";
  icon: ReactNode | IconType | any;
  integration?: {
    id: string;
    origin: string;
    configure: boolean;
  };
  props: INodeProps;
  options?: {
    dragHandle?: string;
  };
}

export interface INodeProps {
  nodeTitle?: string;
  text?: string;
  type?: string;
  url?: string;
  headers?: any;
  contentType?: "form-data" | "raw" | "x-www-form-urlencoding";
  method?: "GET" | "POST" | "PUT" | "DELETE";
  query?: any;
  data?: any;
  rawData?: string;
  retorno?: any;
  key?: string;
  value?: string;
  variable?: any;
  stringAction?: "none" | "replace" | "match" | "subtract" | "sum" | "toUpperCase" | "toLowerCase";
  stringActionValue?: string;
  tags?: string[];
  tabulacao?: any;
  tabulacaoId?: string;
  latitude?: string | number;
  longitude?: string | number;
  name?: string;
  address?: string;
  status?: "INFO" | "SUCESSO" | "ALERTA" | "ERRO";
  departmentId?: string;
  userId?: string;
  department?: any;
  user?: any;
  anchorId?: string;
  anchor?: any;
  nodeId?: string;
  node?: any;
  menuTitle?: string;
  mode?: "quickreply" | "list" | "text";
  invalidOptionText?: string;
  timeout?: number;
  maxRetries?: number;
  eval?: string;
  options?: INodeMenuItem[];
  timegroupId?: string;
  timegroup?: any;
  description?: string;
  comment?: string;
  accent?: "slate" | "red" | "purple" | "rose" | "violet" | "indigo" | "fuchsia" | "yellow" | "green" | "amber" | "lime" | "pink" | "teal" | "emerald" | "sky" | "orange" | "blue" | "cyan";
  solid?: boolean;
  size?: number;
  variableDepartmentId?: string;
  variableUserId?: string;
  file?: string;
  fileName?: string;
  to?: string[];
  subject?: string;
  body?: string;
  cc?: string[];
  cco?: string[];
  updatedAt?: string;
}

export type INodeMenuItem = {
  id: string | number;
  text: string;
  keywords?: string[];
  keyword?: string;
  mode?: string;
  currentId?: number | string;
  static?: boolean;
  staticIndex?: number;
  condition?: string;
  regex?: string;
  description?: string;
};
