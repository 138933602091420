import { useState } from "react";
import { ListBulletIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button, RichPreview } from ".";
import { INodeMenuItem, INodeProps } from "./Nodes";

interface IWhatsAppMessage {
  type: "received" | "received-location" | "list" | "image" | "quickreply" | "text" | "pre-list" | "pre-quickreply" | "pre-text" | "button" | "file";
  message?: string;
  submessage?: string;
  response?: boolean;
  node?: INodeProps;
  richText?: boolean;
  latitude?: number | string;
  longitude?: number | string;
  address?: string;
  addressName?: string;
}

const WhatsAppMessage = (props: IWhatsAppMessage) => {
  const { type, message, submessage, response, node, richText = false, latitude, longitude, address, addressName } = props;
  const [showList, setShowList] = useState(false);
  return (
    <div className="whatsapp-message-container" data-type={type}>
      {type === "image" || type === "file" ? (
        <div className="message">
          <div className="message-content">
            {type === "image" ? (
              <img src={message} alt="fake-preview" width="100%" onError={(e: any) => (e.target.src = "https://cdn.letteldata.com.br/images/error-image.png")} />
            ) : (
              <div className="file-container">
                <div>
                  <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.5 32.5C29.5 34.1569 28.1569 35.5 26.5 35.5H3C1.34315 35.5 0 34.1569 0 32.5V3C0 1.34315 1.34315 0 3 0H19.5L29.5 10V32.5Z" fill="#F03F41" />
                    <path d="M29.5 10H22.5C20.8431 10 19.5 8.65685 19.5 7V0L29.5 10Z" fill="#F57474" />
                    <path d="M19.6404 13.2811C17.8288 11.573 14.8789 11.573 13.0692 13.2811L8.05002 18.0104C8.01733 18.0412 8.00002 18.0829 8.00002 18.1264C8.00002 18.1699 8.01733 18.2116 8.05002 18.2425L8.75963 18.9116C8.79207 18.9421 8.83598 18.9591 8.88175 18.9591C8.92752 18.9591 8.97143 18.9421 9.00386 18.9116L14.0231 14.1824C14.6462 13.5948 15.475 13.2721 16.3558 13.2721C17.2365 13.2721 18.0654 13.5948 18.6865 14.1824C19.3096 14.7699 19.6519 15.5515 19.6519 16.3802C19.6519 17.2107 19.3096 17.9904 18.6865 18.5779L13.5712 23.3997L12.7423 24.1812C11.9673 24.912 10.7077 24.912 9.93271 24.1812C9.55771 23.8276 9.35194 23.3579 9.35194 22.8575C9.35194 22.357 9.55771 21.8873 9.93271 21.5337L15.0077 16.7501C15.1365 16.6304 15.3058 16.5633 15.4865 16.5633H15.4885C15.6692 16.5633 15.8365 16.6304 15.9635 16.7501C16.0923 16.8716 16.1615 17.0312 16.1615 17.2016C16.1615 17.3702 16.0904 17.5298 15.9635 17.6495L11.8154 21.5573C11.7827 21.5881 11.7654 21.6298 11.7654 21.6733C11.7654 21.7169 11.7827 21.7586 11.8154 21.7894L12.525 22.4585C12.5574 22.489 12.6014 22.5061 12.6471 22.5061C12.6929 22.5061 12.7368 22.489 12.7692 22.4585L16.9154 18.5489C17.2981 18.1881 17.5077 17.7093 17.5077 17.1998C17.5077 16.6902 17.2962 16.2097 16.9154 15.8507C16.125 15.1054 14.8404 15.1072 14.05 15.8507L13.5577 16.3167L8.97694 20.6343C8.66604 20.9257 8.41959 21.2725 8.25189 21.6544C8.08419 22.0364 7.99858 22.4459 8.00002 22.8593C8.00002 23.6989 8.34809 24.4877 8.97694 25.0806C9.62886 25.6935 10.4827 26 11.3366 26C12.1904 26 13.0442 25.6935 13.6942 25.0806L19.6404 19.4774C20.5154 18.6505 21 17.5498 21 16.3802C21.0019 15.2087 20.5173 14.108 19.6404 13.2811Z" fill="white" />
                  </svg>
                </div>
                <div>
                  <span>{node?.fileName || "Arquivo"}</span>
                  <small>
                    <small>{`${node?.file?.split(".").pop()} •`}</small> Arquivo • 200KB
                  </small>
                </div>
                <div>
                  <svg viewBox="0 0 34 34" height="34" width="34" preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px">
                    <path fill="currentColor" d="M17,2c8.3,0,15,6.7,15,15s-6.7,15-15,15S2,25.3,2,17S8.7,2,17,2 M17,1C8.2,1,1,8.2,1,17 s7.2,16,16,16s16-7.2,16-16S25.8,1,17,1L17,1z"></path>
                    <path fill="currentColor" d="M22.4,17.5h-3.2v-6.8c0-0.4-0.3-0.7-0.7-0.7h-3.2c-0.4,0-0.7,0.3-0.7,0.7v6.8h-3.2 c-0.6,0-0.8,0.4-0.4,0.8l5,5.3c0.5,0.7,1,0.5,1.5,0l5-5.3C23.2,17.8,23,17.5,22.4,17.5z"></path>
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {((richText && message?.replace(/(<([^>]+)>)/gi, "")) || !richText || address || node?.mode) && (
            <div className="message">
              <div className="message-content">
                {response && <strong>Convert Company</strong>}
                {message?.replace(/(<([^>]+)>)/gi, "") && !richText && <span>{message}</span>}
                {richText && message?.replace(/(<([^>]+)>)/gi, "") && <RichPreview content={message} />}
              </div>
              {submessage && (
                <div className="message-subcontent">
                  <span>{submessage}</span>
                </div>
              )}
              {response && (
                <div className="message-response">
                  <span>{response}</span>
                </div>
              )}
              {type === "list" && (
                <>
                  <div
                    className="list-menu-button"
                    onClick={() => {
                      setShowList(true);
                    }}
                  >
                    <ListBulletIcon width={20} /> {node?.menuTitle}
                  </div>
                </>
              )}
              {type === "text" && (
                <div>
                  {node?.options?.map((option: INodeMenuItem, index: number) => (
                    <strong className="at-u-block" key={index}>
                      {option.static ? option.staticIndex : index + 1} • {option.text}
                    </strong>
                  ))}
                </div>
              )}
              {type === "quickreply" && (
                <>
                  <small>{node?.menuTitle}</small>
                </>
              )}
              {type === "received-location" && (
                <>
                  <div className="location-container">
                    <iframe title="location" width="100%" height="400" src={`https://maps.google.com/maps?width=100%25&height=600&hl=pt-br&q=${latitude},${longitude}+(${latitude},${longitude})&ie=UTF8&iwloc=B&output=embed`}></iframe>
                  </div>
                  <label className="address-name">{addressName}</label>
                  <span className="address-subtitle">{address}</span>
                </>
              )}
            </div>
          )}
        </>
      )}
      {type === "list" && (
        <div className="list-menu-container" data-active={showList ? "true" : ""}>
          <div className="at-u-flex at-u-items-center at-u-mb2" at-tx="title">
            <XMarkIcon width={20} onClick={() => setShowList(false)} />
            <p at-center="true" className="at-u-flex-grow" at-tx="title">
              {node?.menuTitle}
            </p>
          </div>
          {node?.options?.map((option: INodeMenuItem, index: number) => (
            <label className="list-option" key={index}>
              <div>
                <span>{option.text}</span>
                <br />
                <small>{option?.description}</small>
              </div>
              <input type="radio" name="option" />
            </label>
          ))}
          <div className="at-button-group at-u-mt3">
            <Button onClick={() => setShowList(false)} label="Enviar" background="green-500" />
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsAppMessage;
