import type { FC } from "react";
import type { NodeProps } from "reactflow";
import { TbMessage } from "react-icons/tb";
import { useReactFlow } from "reactflow";
import { Field } from "../";
import { Editor } from "@tiptap/react";
import { INode } from ".";

const CommentNode: FC<NodeProps> = ({ data, id }) => {
  const { setNodes } = useReactFlow();
  const handleChange = (editor: Editor) => {
    const value = editor?.getHTML();
    setNodes((nds: INode[]) =>
      nds.map((n: INode) => {
        if (n.id === id) {
          if (n.data) {
            n.data.props = {
              ...data?.props,
              ...{
                comment: value,
              },
            };
          }
        }
        return n;
      })
    );
  };
  return (
    <div className="custom-node" at-accent={"slate"} data-solid={data?.props?.solid ? "true" : null}>
      <div className="header">
        <TbMessage /> Comentario
      </div>
      <div className="node-body">
        <Field type="text-editor" value={data?.props?.comment} onBlur={handleChange} />
      </div>
    </div>
  );
};

export default CommentNode;
