import type { FC } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { ItemHandle, Title } from "./";
import Tippy from "@tippyjs/react";
import { BiCheckCircle, BiErrorAlt } from "react-icons/bi";
import { TbCalendarStats, TbChevronLeft, TbChevronRight, TbFile, TbFileText, TbSearch } from "react-icons/tb";
import { PiEquals } from "react-icons/pi";
import { LuChevronFirst, LuChevronLast, LuRegex } from "react-icons/lu";

const ConditionNode: FC<NodeProps> = ({ data, id }) => {
  const formattedTypes: any = {
    includes: "incluir",
    equals: "ser igual à",
    startsWith: "começar com",
    endsWith: "terminar com",
    superior: "ser maior que",
    inferior: "ser menor que",
    regex: "passar no teste",
    notEmpty: "não ser vazio",
    isEmpty: "ser vazio",
  };
  return (
    <div className="custom-node" at-accent="emerald">
      <div className="node-body">
        <Title data={data} />
        {data?.id === "date-hour-block" ? (
          <>
            {data?.props?.timegroupId ? (
              <>
                <div className="transfer-card at-u-my1">
                  <label>{data?.props?.timegroup?.label}</label>
                  <TbCalendarStats />
                </div>
                <div className="node-bar">
                  <ItemHandle label="Verdadeiro" icon={<BiCheckCircle />} nodeId={id} background="emerald-500" textColor="white" handleId={`condition-0`} />
                  <ItemHandle label="Falso" icon={<BiErrorAlt />} nodeId={id} background="rose-500" textColor="white" handleId={"false-condition"} />
                </div>
              </>
            ) : (
              <small>Selecione um grupo de horários</small>
            )}
          </>
        ) : data?.id === "multiple-conditions" ? (
          <>
            {data?.props?.options.length > 1 ? (
              <div className="node-bar">
                {data?.props?.options.map((option: any, index: number) => {
                  return (
                    <div key={index}>
                      <Tippy
                        content={
                          <span at-tx="white">
                            {data?.props?.eval} deve {formattedTypes[option.condition]} {option?.keywords?.join(" ou ")}
                          </span>
                        }
                        className={"at-tooltip"}
                        duration={0}
                      >
                        <ItemHandle label={option?.text} key={index} nodeId={id} background="emerald-500" textColor="white" handleId={`condition-${index}`} />
                      </Tippy>
                    </div>
                  );
                })}
                <ItemHandle label="Exceção" icon={<BiErrorAlt />} nodeId={id} background="rose-500" textColor="white" handleId={"false-condition"} />
              </div>
            ) : data?.props?.options.length === 1 ? (
              <>
                <div className="at-u-flex at-u-items-center">
                  <div className="transfer-card at-u-mt1">
                    <div at-left="true">
                      <small className="!font-mono font-medium">{data?.props?.eval}</small>
                    </div>
                  </div>
                  <div className="grid place-items-center p-3">
                    {data?.props?.options[0]?.condition === "equals" ? (
                      <>
                        <PiEquals />
                        <small className="text-[10px]">Deve ser igual à</small>
                      </>
                    ) : data?.props?.options[0]?.condition === "includes" ? (
                      <>
                        <TbSearch />
                        <small className="text-[10px]">Deve incluir</small>
                      </>
                    ) : data?.props?.options[0]?.condition === "regex" ? (
                      <>
                        <LuRegex />
                        <small className="text-[10px]">Deve passar no teste</small>
                      </>
                    ) : data?.props?.options[0]?.condition === "startsWith" ? (
                      <>
                        <LuChevronFirst />
                        <small className="text-[10px]">Deve começar com</small>
                      </>
                    ) : data?.props?.options[0]?.condition === "endsWith" ? (
                      <>
                        <LuChevronLast />
                        <small className="text-[10px]">Deve terminar com</small>
                      </>
                    ) : data?.props?.options[0]?.condition === "superior" ? (
                      <>
                        <TbChevronLeft />
                        <small className="text-[10px]">Deve ser maior que</small>
                      </>
                    ) : data?.props?.options[0]?.condition === "inferior" ? (
                      <>
                        <TbChevronRight />
                        <small className="text-[10px]">Deve ser menor que</small>
                      </>
                    ) : data?.props?.options[0]?.condition === "notEmpty" ? (
                      <>
                        <TbFileText />
                        <small className="text-[10px]">Deve não ser vazio</small>
                      </>
                    ) : data?.props?.options[0]?.condition === "isEmpty" ? (
                      <>
                        <TbFile />
                        <small className="text-[10px]">Deve ser vazio</small>
                      </>
                    ) : null}
                  </div>
                  {data?.props?.options[0]?.condition !== "isEmpty" && data?.props?.options[0]?.condition !== "notEmpty" ? (
                    <div className="transfer-card">
                      <div at-left="true">
                        <small className="!font-mono font-medium">{data?.props?.options[0]?.keywords?.join(" ou ")}</small>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="node-bar">
                  <ItemHandle label="Verdadeiro" icon={<BiCheckCircle />} nodeId={id} background="emerald-500" textColor="white" handleId={"condition-0"} />
                  <ItemHandle label="Falso" icon={<BiErrorAlt />} nodeId={id} background="rose-500" textColor="white" handleId={"false-condition"} />
                </div>
              </>
            ) : (
              <>
                <small>Cadastre ao menos uma condição</small>
              </>
            )}
          </>
        ) : data.id === "condition-initial-message" ? (
          <>
            <small>
              Valida se a mensagem inicial do usuário foi
              <br /> uma resposta ao fim do ultimo atendimento
            </small>
            <div className="node-bar">
              <ItemHandle label="Sim" icon={<BiCheckCircle />} nodeId={id} background="emerald-500" textColor="white" handleId={"condition-0"} />
              <ItemHandle label="Não" icon={<BiErrorAlt />} nodeId={id} background="rose-500" textColor="white" handleId={"false-condition"} />
            </div>
          </>
        ) : null}
      </div>
      <Handle id={`handle-${id}-top`} type="target" position={Position.Top} />
    </div>
  );
};

export default ConditionNode;
