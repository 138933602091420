export interface ITabItem {
  id: string;
  label: string;
}

export interface ITabs extends React.HTMLAttributes<HTMLDivElement> {
  tabs: Array<ITabItem>;
  active: string;
  setActive: (id: string) => void;
}

const Tabs = (props: ITabs) => {
  const { tabs, active, setActive, ...rest } = props;
  return (
    <div className="tabs" {...rest}>
      {tabs.map((tab: ITabItem, index: number) => {
        return (
          <div className="tab" key={index} onClick={() => setActive(tab.id)} data-active={tab.id === active ? "true" : null}>
            {tab.label}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
