import type { FC } from "react";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import { Title, ItemHandle, INodeMenuItem } from "./";
import { WhatsAppMessage } from "../";
import { TbHourglassHigh } from "react-icons/tb";
import { BiErrorAlt } from "react-icons/bi";

const MenuNode: FC<NodeProps> = ({ data, id }) => {
  return (
    <div className="custom-node" at-accent="rose">
      <div className="node-body">
        <Title data={data} />
        <div className="whatsapp-mockup at-u-lg-rounded at-u-p1 at-u-px2 at-u-mt1">
          <WhatsAppMessage richText={true} message={data?.props?.text} type={data?.props?.mode} node={data?.props} />
        </div>
        <div className="node-bar">
          {data?.props?.options?.map((option: INodeMenuItem, index: number) => {
            return <ItemHandle label={`${option.static ? option.staticIndex : index + 1} • ${option?.text}`} nodeId={id} background="slate-800" textColor="white" handleId={`menu-option-${option.id}`} key={`${option.id}-${index}`} />;
          })}
          {data?.props?.timeout > 0 && <ItemHandle label="Timeout" icon={<TbHourglassHigh />} nodeId={id} background="slate" textColor="slate-500" handleId={"menu-timeout"} />}
          {data?.props?.maxRetries > 0 && <ItemHandle label={`Excedeu ${data?.props?.maxRetries} Tentativa(s)`} icon={<BiErrorAlt />} nodeId={id} background="rose-500" textColor="white" handleId={"menu-invalid"} />}
        </div>
      </div>
      <Handle id={`handle-${id}-top`} type="target" position={Position.Top} />
    </div>
  );
};

export default MenuNode;
