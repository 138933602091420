import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Field, Button } from "./";
import { TbX } from "react-icons/tb";
import { regexSuggestions, regexTestSuggestions, regexMatchSuggestions } from "../services/constants";

interface IRegexModalProps {
  isOpen: boolean;
  method?: "test" | "replace" | "match";
  onClose: (value?: string) => void;
}

const RegexModal = (props: IRegexModalProps) => {
  const { isOpen, onClose, method } = props;
  const [regex, setRegex] = useState("");
  const [text, setText] = useState("");
  const [result, setResult] = useState<boolean | string>("");
  const closeModal = (value?: string) => {
    onClose(value);
  };
  const handleRegexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegex(e.target.value);
  };
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };
  useEffect(() => {
    if (regex && text) {
      try {
        if (method === "replace") {
          const regexObj = new RegExp(regex, "gi");
          const result = text.replace(regexObj, "");
          setResult(result);
        }
        if (method === "match") {
          const regexObj = new RegExp(regex);
          const result = text.match(regexObj);
          if (result && result[1]) {
            setResult(result[1]);
          } else {
            setResult("Nenhum match encontrado");
          }
        }
        if (method === "test") {
          const regexObj = new RegExp(regex, "gi");
          const result = regexObj.test(text);
          setResult(result);
        }
      } catch (error) {
        setResult("Erro ao testar regex");
      }
    } else {
      setResult("");
    }
  }, [regex, text]);

  useEffect(() => {
    setRegex("");
    setText("");
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[99999999999] dialog-modal" onClose={() => closeModal()}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-lg transform relative overflow-hidden rounded-2xl bg-modal p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-semibold leading-6 mb-4 ">
                  {method === "test" ? "Testar Expressão Regular" : method === "replace" ? "Substituir Valores" : method === "match" ? "Extrair Valores" : ""}
                  <div className="close" onClick={() => closeModal()}>
                    <TbX />
                  </div>
                </Dialog.Title>
                <div>
                  <Field label="Expressão Regular" autofocus={true} quickValues={method === "replace" ? regexSuggestions : method === "test" ? regexTestSuggestions : method === "match" ? regexMatchSuggestions : []} placeholder="\d+5" className="monospace-field" type="input" value={regex} onChange={handleRegexChange} />
                  <Field label="Valor para testes" placeholder="Olá meu CPF é 014.151.312-43" className="monospace-field" type="textarea" value={text} onChange={handleTextChange} />
                  {method === "replace" ? (
                    <div>
                      <Field value={result} label="Resultado" className="monospace-field" description="O resultado corresponde ao texto com as substituições mapeadas pela expressão regular" type="textarea" disabled={true} />
                    </div>
                  ) : method === "match" ? (
                    <Field value={result} label="Resultado" className="monospace-field" description="O resultado corresponde ao valor extraido de um texto por um grupo de captura pela expressão regular" type="textarea" disabled={true} />
                  ) : method === "test" ? (
                    <div>
                      <div className="flex items-center py-3 px-1 rounded-xl">
                        <div className="grow flex flex-col leading-5">
                          <label>Resultado</label>
                          <small>O resultado corresponde se o valor informado passa no teste definido pela expressão regular</small>
                        </div>
                        <div className="w-[100px] flex justify-end">
                          <div className="badge">
                            <div className="circle" at-bg={result ? "emerald-400" : "rose-400"}></div>
                            <span>{result ? "Verdadeiro" : "Falso"}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <hr at-bg="slate" />
                  <div className="flex items-center justify-end gap-2 mt-2">
                    <Button label="Fechar Janela" outlined onClick={() => closeModal()} />
                    <Button label="Salvar" background="primary" onClick={() => closeModal(regex)} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RegexModal;
