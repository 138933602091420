import { CursorArrowRippleIcon, ListBulletIcon, PencilSquareIcon, QueueListIcon, TrashIcon } from "@heroicons/react/24/outline";
import { createElement, useEffect, useState } from "react";
import { BiAnchor, BiBoltCircle, BiCheckCircle, BiErrorCircle, BiInfoCircle, BiPurchaseTag } from "react-icons/bi";
import { IoText } from "react-icons/io5";
import { TbArrowUpCircle, TbExternalLink, TbX } from "react-icons/tb";
import { useReactFlow } from "reactflow";
import { Button, CodeEditor, Field, IconButton, NodePreview, RegexModal, Tabs, WhatsAppMessage } from "../";
import { ICategory, categories, timeSuggestions } from "../../services/constants";
import { createVariable, getDepartments, getTabulacoes, getTimegroups, getUsers, uuid } from "../../services/methods";
import { log } from "../../services/utils";
import { Reorder } from "framer-motion";
import type { INode, INodeMenuItem, INodeProps } from "../Nodes";
import toast from "react-hot-toast";
import AdvancedTextEditor from "../AdvancedTextEditor";

interface ISidebar {
  data?: INode | null | any;
  show?: boolean;
  hideSidebar: () => void;
  onSaveNode: (id: string, node: INode | any) => void;
}

interface ISidebarProps {
  node: INodeProps & INode;
  setNode: (node: INodeProps) => void;
  update: (e: KeyboardEvent | null, key: string, setState: any, _state: any, value?: string | number | boolean | object) => void;
  nodeId: string;
  showVariableForm?: boolean;
  setShowVariableForm?: (show: boolean) => void;
  templateId?: string;
}

const NodeSidebar = (props: ISidebar) => {
  const { data, show, hideSidebar, onSaveNode } = props;
  const [showVariableForm, setShowVariableForm] = useState(false);
  const [node, setNode] = useState(data?.data?.props);
  const [nodeId, setNodeId] = useState(data?.id);

  useEffect(() => {
    setNode(data?.data?.props);
    setNodeId(data?.id);
  }, [data?.id]);

  if (!data) return null;

  /** Atualiza keys dentro de um state de objeto */
  const update = (e: KeyboardEvent | null, key: string, setState: any, _state: any, value?: string | number | boolean | object) => {
    if (key && e) {
      const target = e.target as HTMLInputElement;
      setState((st: any) => ({ ...st, ...{ [key]: target?.value } }));
    } else if (key && value !== undefined) {
      setState((st: any) => ({ ...st, ...{ [key]: value } }));
    }
  };

  const showMockup = ["send-new-message", "user-input", "close-chat", "transfer-to-user", "transfer-to-department", "send-location", "send-attachment-image", "send-attachment-file"];

  return (
    <div className="at-offcanvas app-node-sidebar animate__fadeIn animate__animated" at-active={show ? "true" : null} at-accent={categories?.find((category: any) => category?.id === data?.data?.category)?.color}>
      <div className="preview">
        {data?.data?.id === "transfer-to-node" && node?.nodeId && (
          <div className="transfer-to-node-preview">
            <h3 className=" at-u-semibold">Preview do Bloco</h3>
            <NodePreview nodeId={node?.nodeId} />
            <svg className="react-flow__background background">
              <pattern id="pattern-1" x="0" y="0" width="24" height="24" patternUnits="userSpaceOnUse" patternTransform="translate(-1,-1)">
                <circle cx="1" cy="1" r="1" fill="#95a7b899"></circle>
              </pattern>
              <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-1)"></rect>
            </svg>
          </div>
        )}
        {data?.data?.id === "send-email" && <EmailMockup node={node} update={update} setNode={setNode} nodeId={node?.nodeId} />}
        {showVariableForm && <VariableForm onCancel={() => setShowVariableForm(false)} onCreate={() => setShowVariableForm(false)} />}
        {(data?.data?.category === "menu" || showMockup.includes(data?.data?.id)) && !showVariableForm && <PhoneMockup node={node} data={data} />}
      </div>
      <div className="at-offcanvas-container animate__fadeInRight animate__animated">
        <div className="header">
          <div className="title">
            {categories?.find((category: ICategory) => category?.id === data?.data?.category) && (
              <>
                <div className="icon">{createElement(categories.find((category: ICategory) => category?.id === data?.data?.category)?.icon)}</div>
                <label>{categories.find((category: ICategory) => category?.id === data?.data?.category)?.label}</label>
              </>
            )}
            <div className="close" onClick={hideSidebar}>
              <TbX />
            </div>
          </div>
          <h3 className="at-u-my2">{node?.nodeTitle}</h3>
        </div>
        <div className="body">
          {data?.data?.category === "menu" ? (
            <>
              <MenuNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "other" && data?.data?.id === "anchor-node" ? (
            <>
              <AnchorNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "send-new-message" ? (
            <>
              <ActionMessageNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "new-http-request" ? (
            <>
              <ActionRequestNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "set-variable" ? (
            <>
              <ActionSetVariableNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "user-input" ? (
            <>
              <ActionInputNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "record-log" ? (
            <>
              <ActionLogNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "wait-delay" ? (
            <>
              <ActionDelayNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "close-chat" ? (
            <>
              <ActionCloseNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "send-location" ? (
            <>
              <ActionLocationSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "add-tags" ? (
            <>
              <ActionTagsNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "set-tabulacao" ? (
            <>
              <ActionTabulacaoNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "send-attachment-image" ? (
            <>
              <ActionAttachmentImage node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "send-attachment-file" ? (
            <>
              <ActionAttachmentFile node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "action" && data?.data?.id === "send-email" ? (
            <>
              <ActionSendEmail node={node} setNode={setNode} update={update} nodeId={nodeId} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "condition" ? (
            <>
              <ConditionNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} templateId={data?.data?.id} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : data?.data?.category === "transfer" ? (
            <>
              <TransferNodeSidebar node={node} setNode={setNode} update={update} nodeId={nodeId} templateId={data?.data?.id} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            </>
          ) : null}
        </div>
        <div className="footer">
          <div className="at-button-group">
            <Button label="Cancelar" outlined={true} onClick={hideSidebar} />
            <Button label="Salvar" id="button-sidebar-save" background="slate-900" onClick={() => onSaveNode(data?.id, node)} />
          </div>
        </div>
      </div>
    </div>
  );
};

const MenuNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const [activeTab, setActiveTab] = useState<any>(null);

  const tabs = [
    {
      id: "configuracoes",
      label: "Configurações",
    },
    {
      id: "options",
      label: "Cadastro de Opções",
    },
  ];

  const forms = {
    option: {
      id: 1 as number | string,
      text: "" as string,
      keyword: "" as string,
      keywords: [] as string[],
      mode: "create" as string,
      currentId: "" as string | number,
      static: false as boolean,
      staticIndex: 0 as number,
      description: "" as string,
    },
  };

  const options = [
    { id: 1, label: "Botões (Quick Reply)", description: "Menu com resposta em um clique, máximo (3 Opções)", icon: <CursorArrowRippleIcon />, value: "quickreply" },
    { id: 2, label: "Lista", description: "Menu em formato de lista, máximo (10 Opções)", icon: <ListBulletIcon />, value: "list" },
    { id: 3, label: "Normal (Texto)", description: "Menu em formato de texto, sem limites", icon: <IoText />, value: "text" },
  ];

  useEffect(() => {
    setActiveTab(tabs[0]?.id);
  }, [node?.id]);

  const [optionForm, setOptionForm] = useState(forms.option);

  /** Cria nova palavra chave na opção */
  const pushKeyword = (values: string[] = []) => {
    if (optionForm.keyword.replace(/\s/g, "") !== "") {
      if (!optionForm.keywords.includes(optionForm.keyword)) {
        if (!node.options?.find((option: INodeMenuItem) => option.keywords?.includes(optionForm.keyword))) {
          if (optionForm.keyword?.includes(";;")) {
            const keywords = optionForm.keyword?.split(";;");
            keywords.forEach((keyword: string) => {
              if (!optionForm.keywords.includes(keyword)) {
                optionForm.keywords.push(keyword);
              }
            });
          } else {
            optionForm.keywords.push(optionForm.keyword);
          }
          update(null, "keyword", setOptionForm, optionForm, "");
        } else {
          toast.error("Essa palavra chave já está cadastrada!");
        }
      }
    }
    if (values.length) {
      values.forEach((value: string) => {
        if (!node.options?.find((option: INodeMenuItem) => option.keywords?.includes(value))) {
          if (!optionForm.keywords.includes(value)) {
            optionForm.keywords.push(value);
          }
        }
      });
    }
  };

  /** Deleta palavra chave na opção */
  const deleteKeyword = (index: number) => {
    setOptionForm({ ...optionForm, ...{ keywords: optionForm.keywords.filter((_w, i) => i !== index) } });
  };

  /** Cadastra / Salva opção do menu */
  const saveOption = () => {
    if (optionForm.text.replace(/\s/g, "") === "") {
      toast.error("Preencha um nome para opção!");
      return;
    }
    const limit = node.mode === "quickreply" ? 3 : node.mode === "list" ? 10 : 50;
    if (node.options?.length && node.options?.length >= limit && optionForm.mode === "create") {
      toast.error(`Esse menu suporta apenas ${limit} opções!`);
      return;
    }
    if (node.options?.find((option: INodeMenuItem) => option.text === optionForm.text && (optionForm.mode === "create" || option.id !== optionForm.id))) {
      toast.error("Essa opção já foi cadastrada!");
      return;
    }
    if (node.mode === "text" && optionForm.static) {
      if (optionForm.staticIndex <= (node.options?.filter((o: INodeMenuItem) => !o.static).length || -1) || node.options?.filter((o: INodeMenuItem) => o.static).find((o: INodeMenuItem) => o.staticIndex === optionForm.staticIndex)?.id) {
        toast.error("Esse número de opção já está cadastrado!");
        return;
      }
    }
    pushKeyword([optionForm.text]);
    const option = JSON.parse(JSON.stringify(optionForm));
    if (optionForm.mode === "create") {
      option.id = uuid();
      node.options = node.options ? [...node.options, option] : [option];
      update(null, "options", setNode, node, node.options);
      setOptionForm({ ...forms.option });
      toast.success("Opção cadastrada!");
    } else if (optionForm.mode === "edit") {
      node.options = node.options?.map((o: INodeMenuItem) => {
        if (o.id === optionForm.currentId) return option;
        return o;
      });
      update(null, "options", setNode, node, node.options);
      setOptionForm({ ...forms.option });
      toast.success("Opção salva!");
    }
  };

  /** Deleta opção do menu */
  const deleteOption = (option: INodeMenuItem) => {
    setNode({ ...node, ...{ options: node.options?.filter((o: INodeMenuItem) => option.id !== o.id) } });
    toast.success("Opção deletada!");
  };

  /** Edita uma opção do menu */
  const editOption = (option: INodeMenuItem) => {
    setOptionForm({
      ...optionForm,
      ...option,
      ...{
        currentId: option.id,
        mode: "edit",
      },
    });
  };

  /** Gerencia reordenamento de nodes */
  const handleReorder = (order: INodeMenuItem[]) => {
    update(null, "options", setNode, node, order);
  };

  return (
    <>
      <Tabs tabs={tabs} active={activeTab} setActive={setActiveTab} />
      {activeTab === "configuracoes" ? (
        <>
          <Field id="id-node" label="ID do Node" type="" description={nodeId} />
          <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Menu de técnicos" />
          <Field id="menu-type" value={node?.mode} onChange={(value: any) => update(null, "mode", setNode, node, value)} label="Tipo do Menu" type="select" placeholder="Selecione um tipo para o menu" options={options} />
          {(node?.mode === "list" || node?.mode === "quickreply") && <Field id="menu-title" value={node?.menuTitle} onChange={(e: KeyboardEvent) => update(e, "menuTitle", setNode, node)} label="Titulo do Menu" type="input" placeholder="Ex: Setor desejado" />}
          <Field id="menu-max-retries" value={node?.maxRetries} onChange={(e: KeyboardEvent) => update(e, "maxRetries", setNode, node)} label="Quantidade Máxima de Tentativas" type="number" min={1} max={100} placeholder="Ex: 10" />
          <Field id="menu-timeout" value={node?.timeout} description="O timeout pode ter um delay de até 15 segundos" onChange={(e: KeyboardEvent) => update(e, "timeout", setNode, node)} label="Timeout Máximo (em segundos)" type="number" min={30} placeholder="Ex: 10" quickValues={timeSuggestions} />
          <Field id="menu-text" value={node?.text} onChange={(editor: any) => update(null, "text", setNode, node, editor.getHTML())} label="Mensagem" type="text-editor" min={0} max={node?.mode !== "text" ? 60 : 4096} placeholder="Ex: Olá, bem vindo" setShowVariableForm={setShowVariableForm} />
          <Field id="menu-invalid-text" value={node?.invalidOptionText} onChange={(editor: any) => update(null, "invalidOptionText", setNode, node, editor.getHTML())} label="Mensagem (Opção Inválida)" type="text-editor" min={0} max={4096} placeholder="Ex: Opção inválida, tente novamente" setShowVariableForm={setShowVariableForm} />
        </>
      ) : activeTab === "options" ? (
        <>
          {node?.mode === "text" ? <Field checked={optionForm.static} onChange={() => update(null, "static", setOptionForm, optionForm, !optionForm.static)} id="static-option" type="checkbox" label="Opção estática?" description="Define se a opção vai ter um número estático"></Field> : null}
          <div className="at-u-flex at-u-gap">
            {node?.mode === "text" && <>{!!optionForm.static && <Field id="menu-option-index" value={optionForm.staticIndex} onChange={(e: KeyboardEvent) => update(e, "staticIndex", setOptionForm, optionForm)} label="Número" type="number" min={0} max={100} placeholder="Ex : 1" />}</>}
            <Field id="menu-option-text" className="at-u-flex-grow" value={optionForm.text} max={node?.mode !== "text" ? 20 : null} onChange={(e: KeyboardEvent) => update(e, "text", setOptionForm, optionForm)} label="Nome da Opção" type="input" placeholder="Ex : Comercial" onKeyEnter={saveOption} />
          </div>
          {node?.mode === "list" && (
            <>
              <Field id="menu-option-description" className="at-u-flex-grow" value={optionForm.description} max={70} onChange={(e: KeyboardEvent) => update(e, "description", setOptionForm, optionForm)} label="Descrição da Opção" type="input" placeholder="Ex : Falar com o time de vendas" onKeyEnter={saveOption} />
            </>
          )}
          <div className="input-group">
            <Field id="menu-option-keyword" value={optionForm.keyword} onChange={(e: KeyboardEvent) => update(e, "keyword", setOptionForm, optionForm)} onKeyEnter={pushKeyword} label="Palavras Chave" type="input" placeholder="Ex : Venda" />
            <Button label="Adicionar" onClick={pushKeyword} />
          </div>
          {optionForm.keywords?.map((word: string, index: number) => {
            return (
              <div className="badge tag" key={index}>
                <TbX onClick={() => deleteKeyword(index)} />
                {word}
              </div>
            );
          })}
          <div className="at-button-group at-u-border-bottom at-u-pb2">
            <Button label={optionForm.mode === "edit" ? "Cancelar" : "Redefinir"} onClick={() => setOptionForm(forms.option)} />
            <Button label={optionForm.mode === "edit" ? "Salvar Opção!" : "Cadastrar Opção"} background="primary" onClick={saveOption} />
          </div>
          <div className="items-list">
            <small className="at-u-block at-u-p1" at-right="true">
              {node?.options?.length}/{node?.mode === "quickreply" ? "3" : node?.mode === "list" ? "10" : "50"}
            </small>
            <Reorder.Group axis="y" className="at-u-p0" values={node?.options as unknown[]} onReorder={handleReorder}>
              {node.options?.map((option: INodeMenuItem, index: number) => {
                return (
                  <Reorder.Item key={option.id} value={option} className="item" at-shadow="small">
                    <div className="at-u-flex-grow">
                      <strong>
                        {option.static ? option.staticIndex : index + 1} • {option.text}
                      </strong>
                      <small>{option.keywords?.join(", ")}</small>
                    </div>
                    <div className="actions">
                      <Button icon={<PencilSquareIcon />} onClick={() => editOption(option)} />
                      <Button icon={<TrashIcon />} onClick={() => deleteOption(option)} />
                    </div>
                  </Reorder.Item>
                );
              })}
            </Reorder.Group>
          </div>
        </>
      ) : null}
    </>
  );
};

const AnchorNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const colors = ["slate", "gray", "zinc", "neutral", "stone", "red", "orange", "amber", "yellow", "lime", "green", "emerald", "teal", "cyan", "sky", "blue", "indigo", "violet", "purple", "fuchsia", "pink", "rose"];
  const options = colors.map((c, i) => {
    return {
      id: i + 2,
      value: c,
      icon: (
        <div className="anchor" at-tx="white" at-accent={c}>
          <BiAnchor color="#FFF" width={12} height={12} /> {node?.nodeTitle}
        </div>
      ),
    };
  });
  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Nome da âncora" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field id="description-node" value={node?.description} onChange={(e: KeyboardEvent) => update(e, "description", setNode, node)} label="Descrição da âncora" type="textarea" placeholder="Ex : Aqui faço a validação das regras" />
      <Field id="anchor-accent" value={node?.accent} onChange={(value: any) => update(null, "accent", setNode, node, value)} label="Cor da Âncora" type="select" placeholder="Selecione uma cor" options={options} />
    </>
  );
};

const ActionMessageNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field id="message-node" value={node?.text} onChange={(editor: any) => update(null, "text", setNode, node, editor.getHTML())} label="Mensagem" type="text-editor" placeholder="Ex: Olá, bem vindo" max={4096} setShowVariableForm={setShowVariableForm} />
    </>
  );
};

const ActionInputNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const [showRegexModal, setShowRegexModal] = useState(false);
  const methods = [
    { id: 1, label: "Nenhuma...", value: "none" },
    { id: 2, label: "Substituir Valor", value: "replace" },
    { id: 3, label: "Extrair Valor", value: "match" },
    { id: 4, label: "Transformar em Maiúsculas", value: "toUpperCase" },
    { id: 5, label: "Transformar em Minúsculas", value: "toLowerCase" },
  ];

  const handleRegexModalClose = (value?: string) => {
    if (value) {
      update(null, "stringActionValue", setNode, node, value);
    }
    setShowRegexModal(false);
  };

  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field id="input-variable" value={node?.variable} onChange={(value: any) => update(null, "variable", setNode, node, value)} label="Variável para armazenar os dados" type="select-variable" placeholder="Selecione uma variável" showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
      <Field id="message-node" value={node?.text} onChange={(editor: any) => update(null, "text", setNode, node, editor.getHTML())} label="Mensagem / Pergunta" type="text-editor" placeholder="Ex: Qual o seu nome?" max={4096} setShowVariableForm={setShowVariableForm} />
      <Field id="variable-action-value" value={node?.stringAction} onChange={(value: any) => update(null, "stringAction", setNode, node, value)} label="Ação na resposta?" type="select" placeholder="Selecione um metodo" options={methods} />
      {node?.stringAction === "replace" ? (
        <div className="pb-2">
          <div className="input-group">
            <Field id="variable-action-value" className="monospace-field" value={node?.stringActionValue} onChange={(e: KeyboardEvent) => update(e, "stringActionValue", setNode, node)} label="Qual o valor para substituir (Texto ou RegEx)" type="input" placeholder="Ex : \D ou teste" />
            <IconButton icon={<TbExternalLink />} onClick={() => setShowRegexModal(true)} />
          </div>
          <RegexModal isOpen={showRegexModal} method="replace" onClose={handleRegexModalClose} />
        </div>
      ) : node?.stringAction === "match" ? (
        <div className="pb-2">
          <div className="input-group">
            <Field id="variable-action-value" className="monospace-field" value={node?.stringActionValue} onChange={(e: KeyboardEvent) => update(e, "stringActionValue", setNode, node)} label="Qual o valor para extrair (RegEx)" type="input" placeholder="Ex : (\d{4-5})" />
            <IconButton icon={<TbExternalLink />} onClick={() => setShowRegexModal(true)} />
          </div>
          <small className="block -mt-1">Para extrair valores da variável, é necessário utilizar uma expressão regular, assim como um grupo de captura</small>
          <RegexModal isOpen={showRegexModal} method="match" onClose={handleRegexModalClose} />
        </div>
      ) : null}
      <Field id="input-timeout" description="O timeout pode ter um delay de até 15 segundos" value={node?.timeout} onChange={(e: KeyboardEvent) => update(e, "timeout", setNode, node)} label="Timeout (em segundos)" type="number" min={15} quickValues={timeSuggestions} placeholder="Ex: 10" />
    </>
  );
};

const ActionCloseNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field id="message-node" value={node?.text} onChange={(editor: any) => update(null, "text", setNode, node, editor.getHTML())} label="Mensagem" type="text-editor" placeholder="Ex: Obrigado por entrar em contato!" max={4096} setShowVariableForm={setShowVariableForm} />
    </>
  );
};

const ActionLocationSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field id="latitude-node" value={node?.latitude} onChange={(e: KeyboardEvent) => update(e, "latitude", setNode, node)} label="Latitude do Endereço" type="input" placeholder="Ex : -28.2453389" />
      <Field id="longitude-node" value={node?.longitude} onChange={(e: KeyboardEvent) => update(e, "longitude", setNode, node)} label="Longitude do Endereço" type="input" placeholder="Ex : -52.4164958" />
      <Field id="address-name-node" value={node?.name} onChange={(e: KeyboardEvent) => update(e, "name", setNode, node)} label="Nome do endereço" type="input" placeholder="Ex : Convert Company" />
      <Field id="address-address-node" value={node?.address} onChange={(e: KeyboardEvent) => update(e, "address", setNode, node)} label="Endereço" type="input" placeholder="Ex : Av. Dr Alvaro Severo de Miranda, 1106, Sala 801" />
    </>
  );
};

const ActionLogNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const statusType = [
    {
      id: 1,
      label: "SUCESSO",
      value: "SUCESSO",
      icon: (
        <div className="select-icon" at-bg="emerald-500">
          <BiCheckCircle />
        </div>
      ),
    },
    {
      id: 2,
      label: "INFO",
      value: "INFO",
      icon: (
        <div className="select-icon" at-bg="sky-500">
          <BiInfoCircle />
        </div>
      ),
    },
    {
      id: 3,
      label: "ALERTA",
      value: "ALERTA",
      icon: (
        <div className="select-icon" at-bg="amber-400">
          <BiBoltCircle />
        </div>
      ),
    },
    {
      id: 4,
      label: "ERRO",
      value: "ERRO",
      icon: (
        <div className="select-icon" at-bg="rose-500">
          <BiErrorCircle />
        </div>
      ),
    },
  ];
  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field
        id="log-message-status"
        value={node?.status}
        onChange={(value: any) => {
          update(null, "status", setNode, node, value);
        }}
        label="Status do Log"
        type="select"
        placeholder="Selecione um status"
        options={statusType}
      />
      <Field id="log-message-node" value={node?.text} onChange={(e: KeyboardEvent) => update(e, "text", setNode, node)} label="Mensagem" type="textarea" placeholder="Ex : Mensagem do Log" />
    </>
  );
};

const ActionTagsNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const [tag, setTag] = useState("");

  /** Cria nova tag */
  const pushTag = () => {
    if (tag.replace(/\s/g, "") !== "") {
      if (node?.tags?.includes(tag)) {
        toast.error("Essa tag já está cadastrada!");
      } else {
        update(null, "tags", setNode, node, [...(node?.tags || []), tag]);
        setTag("");
      }
    }
  };

  /** Deleta tag */
  const deleteTag = (index: number) => {
    update(
      null,
      "tags",
      setNode,
      node,
      node.tags?.filter((tag: any, i: number) => index !== i)
    );
  };

  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <div className="input-group">
        <Field id="tag-text" value={tag} onChange={(e: any) => setTag(e?.target?.value)} onKeyEnter={pushTag} label="Tag" type="input" placeholder="Ex : Venda" />
        <Button label="Adicionar" onClick={pushTag} />
      </div>
      <Field id="id-node" label="Tags Cadastradas" type="" />
      {node.tags?.map((tag: string, index: number) => {
        return (
          <div className="badge tag" key={index}>
            <TbX
              onClick={() => {
                deleteTag(index);
              }}
            />
            {tag}
          </div>
        );
      })}
    </>
  );
};

const ActionSetVariableNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const [showRegexModal, setShowRegexModal] = useState(false);
  const methods = [
    { id: 1, label: "Nenhuma...", value: "none" },
    { id: 2, label: "Substituir Valor", value: "replace" },
    { id: 3, label: "Extrair Valor", value: "match" },
    { id: 4, label: "Transformar em Maiúsculas", value: "toUpperCase" },
    { id: 5, label: "Transformar em Minúsculas", value: "toLowerCase" },
    { id: 6, label: "Somar valor", value: "sum" },
    { id: 7, label: "Subtrair valor", value: "subtract" },
  ];

  const handleRegexModalClose = (value?: string) => {
    if (value) {
      update(null, "stringActionValue", setNode, node, value);
    }
    setShowRegexModal(false);
  };

  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field
        id="variable-to-set"
        value={node?.key}
        onChange={(value: any, obj: any) => {
          update(null, "key", setNode, node, value);
          update(null, "variable", setNode, node, obj);
        }}
        label="Variável à ser definida"
        type="select-variable"
        placeholder="Selecione uma variável"
        showVariableForm={showVariableForm}
        setShowVariableForm={setShowVariableForm}
      />
      <Field id="variable-action-value" value={node?.stringAction} onChange={(value: any) => update(null, "stringAction", setNode, node, value)} label="Ação na Variável?" type="select" placeholder="Selecione um metodo" options={methods} />
      {node?.stringAction === "none" && (
        <>
          {node?.variable?.type === "textarea" ? (
            <>
              <Field id="variable-value" value={node?.value} onChange={(editor: any) => update(null, "value", setNode, node, editor.getHTML())} label="Valor definido" type="text-editor" placeholder="Ex: Olá, bem vindo" max={4096} setShowVariableForm={setShowVariableForm} />
            </>
          ) : (
            <Field id="variable-value" value={node?.value} onChange={(e: KeyboardEvent) => update(e, "value", setNode, node)} label="Valor definido" type="input" placeholder="Ex : Teste" />
          )}
        </>
      )}
      {node?.stringAction === "replace" ? (
        <div className="pb-2">
          <div className="input-group">
            <Field id="variable-action-value" className="monospace-field" value={node?.stringActionValue} onChange={(e: KeyboardEvent) => update(e, "stringActionValue", setNode, node)} label="Qual o valor para substituir (Texto ou RegEx)" type="input" placeholder="Ex : \D ou teste" />
            <IconButton icon={<TbExternalLink />} onClick={() => setShowRegexModal(true)} />
          </div>
          <RegexModal isOpen={showRegexModal} method="replace" onClose={handleRegexModalClose} />
        </div>
      ) : node?.stringAction === "match" ? (
        <div className="pb-2">
          <div className="input-group">
            <Field id="variable-action-value" className="monospace-field" value={node?.stringActionValue} onChange={(e: KeyboardEvent) => update(e, "stringActionValue", setNode, node)} label="Qual o valor para extrair (RegEx)" type="input" placeholder="Ex : (\d{4-5})" />
            <IconButton icon={<TbExternalLink />} onClick={() => setShowRegexModal(true)} />
          </div>
          <small className="block -mt-1">Para extrair valores da variável, é necessário utilizar uma expressão regular, assim como um grupo de captura</small>
          <RegexModal isOpen={showRegexModal} method="match" onClose={handleRegexModalClose} />
        </div>
      ) : node?.stringAction === "sum" || node?.stringAction === "subtract" ? (
        <>
          <Field id="variable-action-value" value={node?.stringActionValue} onChange={(e: KeyboardEvent) => update(e, "stringActionValue", setNode, node)} label="Qual o valor para a operação " type="number" placeholder="Ex : 10" />
        </>
      ) : null}
    </>
  );
};

const ActionDelayNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field id="delay-timeout" description="O timeout pode ter um delay de até 15 segundos" value={node?.timeout} onChange={(e: KeyboardEvent) => update(e, "timeout", setNode, node)} label="Tempo em segundos do Delay" type="number" min={30} max={1800} placeholder="Ex: 30" />
    </>
  );
};

const ActionTabulacaoNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const [tabulacoes, setTabulacoes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await getTabulacoes();
      const options = data.map((d: any, i: any) => {
        return {
          id: d._id,
          label: d.tabulacao,
          description: d.statusTabulacao,
          value: d._id,
          data: d,
        };
      });
      setTabulacoes(options);
      setLoading(false);
    };

    fetchData().catch((err) => {
      log("error", "Erro ao buscar dados", err);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field
        id="tabulacao-select"
        value={node?.tabulacaoId}
        onChange={(value: any, obj: any) => {
          update(null, "tabulacaoId", setNode, node, value);
          update(null, "tabulacao", setNode, node, obj);
        }}
        label="Tabulação"
        type="select"
        placeholder={loading ? "Carregando..." : "Selecione uma Tabulação"}
        options={tabulacoes}
      />
    </>
  );
};

const ActionRequestNodeSidebar = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const tabs = [
    {
      id: "configuracoes",
      label: "Configurações",
    },
    {
      id: "headers",
      label: "Headers",
    },
    {
      id: "body",
      label: "Body",
    },
    {
      id: "result",
      label: "Retorno",
    },
  ];

  const requestMode = [
    { id: 1, label: "GET", value: "GET" },
    { id: 2, label: "POST", value: "POST" },
    { id: 3, label: "PUT", value: "PUT" },
    { id: 4, label: "DELETE", value: "DELETE" },
  ];

  const contentType = [
    { id: 1, label: "form-data", value: "form-data" },
    { id: 2, label: "x-www-form-urlencoding", value: "x-www-form-urlencoding" },
    { id: 3, label: "raw", value: "raw" },
  ];

  const forms = {
    header: {
      key: "",
      value: "",
    },
    query: {
      key: "",
      value: "",
    },
    result: {
      key: "",
      value: "",
    },
  };

  const [activeTab, setActiveTab] = useState<any>(null);
  const [headerForm, setHeaderForm] = useState(forms.header);
  const [queryForm, setQueryForm] = useState(forms.query);
  const [resultForm, setResultForm] = useState(forms.result);

  useEffect(() => {
    setActiveTab(tabs[0]?.id);
  }, [node?.id]);

  const pushHeader = () => {
    if (headerForm.key.replace(/\s/g, "") !== "") {
      if (!node?.headers[headerForm.key]) {
        node.headers[headerForm.key] = headerForm.value;
        update(null, "headers", setNode, node, node.headers);
        toast.success("Header cadastrado!");
        setHeaderForm(forms.header);
      } else {
        toast.error("Esse Header já foi cadastrado!");
      }
    } else {
      toast.error("Preencha todos os campos (Header)");
    }
  };

  const deleteHeader = (key: string) => {
    delete node?.headers?.[key];
    update(null, "headers", setNode, node, node.headers);
  };

  const pushQuery = () => {
    if (queryForm.key.replace(/\s/g, "") !== "") {
      if (!node?.query[queryForm.key]) {
        node.query[queryForm.key] = queryForm.value;
        update(null, "query", setNode, node, node.query);
        toast.success("Chave cadastrada!");
        setQueryForm(forms.query);
      } else {
        toast.error("Essa Chave já cadastrada!");
      }
    } else {
      toast.error("Preencha todos os campos (Chave)");
    }
  };

  const deleteQuery = (key: string) => {
    delete node?.query[key];
    update(null, "query", setNode, node, node.query);
  };

  const pushResult = () => {
    if (resultForm.key.replace(/\s/g, "") !== "") {
      if (!node.retorno[resultForm.key]) {
        node.retorno[resultForm.key] = resultForm.value;
        update(null, "retorno", setNode, node, node.retorno);
        toast.success("Variável mapeada!");
        setResultForm(forms.result);
      } else {
        toast.error("Essa variável já foi mapeada!");
      }
    } else {
      toast.error("Preencha todos os campos (Variável)");
    }
  };

  const deleteResult = (key: string) => {
    delete node?.retorno[key];
    update(null, "retorno", setNode, node, node.retorno);
  };

  return (
    <>
      <Tabs tabs={tabs} active={activeTab} setActive={setActiveTab} />
      {activeTab === "configuracoes" ? (
        <>
          <Field id="id-node" label="ID do Node" type="" description={nodeId} />
          <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
          <div className="at-u-flex">
            <Field id="request-method" className="method-selector" value={node?.method} onChange={(value: string) => update(null, "method", setNode, node, value)} label="Método" type="select" placeholder="Selecione o método da request" options={requestMode} />
            <Field id="request-url" className="at-u-flex-grow at-u-pl1" value={node?.url} onChange={(e: KeyboardEvent) => update(e, "url", setNode, node)} label="URL da Requisição" type="input" placeholder="Ex : https://api.convert.app.br/getUsuarios" />
          </div>
          <Field id="request-timeout" value={node?.timeout} onChange={(e: KeyboardEvent) => update(e, "timeout", setNode, node)} label="Timeout da Requisição (em segundos)" type="number" min={3} max={15} placeholder="Ex: 10" />
        </>
      ) : activeTab === "headers" ? (
        <>
          <div className="at-u-flex">
            <Field id="request-header-key" className="at-u-col-6 at-u-pr1" value={headerForm.key} onChange={(e: KeyboardEvent) => update(e, "key", setHeaderForm, headerForm)} label="Header" type="input" placeholder="Ex : Authorization" onKeyEnter={pushHeader} suggestions={["A-IM", "Accept", "Accept-Charset", "Accept-Datetime", "Accept-Encoding", "Accept-Language", "Access-Control-Request-Method", "Access-Control-Request-Headers", "Authorization", "Cache-Control", "Connection", "Permanent", "Content-Encoding", "Content-Length", "Content-MD5", "Content-Type", "Date", "Expect", "Forwarded", "From", "Pragma", "Prefer", "Proxy-Authorization", "Range", "TE"]} />
            <Field id="request-header-value" className="at-u-col-6 at-u-pl1" value={headerForm.value} onChange={(e: KeyboardEvent) => update(e, "value", setHeaderForm, headerForm)} label="Valor" type="input" placeholder="Ex : Bearer eyJhbGciOiJIUzI1NiIs" onKeyEnter={pushHeader} />
          </div>
          <div at-right="true" className="at-u-pb2 at-u-border-bottom">
            <Button label="Adicionar" onClick={pushHeader} background="primary" />
          </div>
          <div className="items-list at-u-mt2">
            {Object.keys(node?.headers).map((key, index) => {
              return (
                <div className="item" at-shadow="small" key={index}>
                  <div className="at-u-flex-grow">
                    <strong>{key}</strong>
                    <small>
                      {node?.headers[key].split(/(\$[^\s]+)/g).map((value: string) => {
                        if (value.includes("$")) {
                          return <span className="variable">{value}</span>;
                        } else {
                          return value;
                        }
                      })}
                    </small>
                  </div>
                  <div className="actions">
                    <Button icon={<TrashIcon />} onClick={() => deleteHeader(key)} />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : activeTab === "body" && node?.method === "GET" ? (
        <>
          <div className="at-u-flex">
            <Field id="request-query-key" className="at-u-col-6 at-u-pr1" value={queryForm.key} onChange={(e: KeyboardEvent) => update(e, "key", setQueryForm, queryForm)} label="Chave" type="input" placeholder="Ex : data_inicial" onKeyEnter={pushQuery} />
            <Field id="request-query-value" className="at-u-col-6 at-u-pl1" value={queryForm.value} onChange={(e: KeyboardEvent) => update(e, "value", setQueryForm, queryForm)} label="Valor" type="input" placeholder="Ex : 2022-10-10" onKeyEnter={pushQuery} />
          </div>
          <div at-right="true" className="at-u-pb2 at-u-border-bottom">
            <Button label="Adicionar" onClick={pushQuery} background="primary" />
          </div>
          <div className="items-list at-u-mt2">
            {Object.keys(node?.query).map((key, index) => {
              return (
                <div className="item" at-shadow="small" key={index}>
                  <div className="at-u-flex-grow">
                    <strong>{key}</strong>
                    <small>
                      {node?.query[key].split(/(\$[^\s]+)/g).map((value: string) => {
                        if (value.includes("$")) {
                          return <span className="variable">{value}</span>;
                        } else {
                          return value;
                        }
                      })}
                    </small>
                  </div>
                  <div className="actions">
                    <Button icon={<TrashIcon />} onClick={() => deleteQuery(key)} />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : activeTab === "body" && node?.method === "POST" ? (
        <>
          <Field
            id="request-content-type"
            value={node?.contentType}
            label="Content Type"
            type="select"
            placeholder="Selecione o tipo de conteúdo"
            options={contentType}
            onChange={(value: string) => {
              update(null, "contentType", setNode, node, value);
              if (value === "form-data") {
                node.headers["Content-Type"] = "multipart/form-data";
              } else if (value === "x-www-form-urlencoding") {
                node.headers["Content-Type"] = "application/x-www-form-urlencoded";
              } else if (value === "raw") {
                node.headers["Content-Type"] = "application/json";
              }
              update(null, "headers", setNode, node, node.headers);
            }}
          />
          {node?.contentType !== "raw" ? (
            <>
              <div className="at-u-flex">
                <Field id="request-query-content-key" className="at-u-col-6 at-u-pr1" value={queryForm.key} onChange={(e: KeyboardEvent) => update(e, "key", setQueryForm, queryForm)} label="Chave" type="input" placeholder="Ex : data_inicial" onKeyEnter={pushQuery} />
                <Field id="request-query-content-value" className="at-u-col-6 at-u-pl1" value={queryForm.value} onChange={(e: KeyboardEvent) => update(e, "value", setQueryForm, queryForm)} label="Valor" type="input" placeholder="Ex : 2022-10-10" onKeyEnter={pushQuery} />
              </div>
              <div at-right="true" className="at-u-pb2 at-u-border-bottom">
                <Button label="Adicionar" onClick={pushQuery} background="primary" />
              </div>
              <div className="items-list at-u-mt2">
                {Object.keys(node?.query).map((key, index) => {
                  return (
                    <div className="item" at-shadow="small" key={index}>
                      <div className="at-u-flex-grow">
                        <strong>{key}</strong>
                        <small>
                          {node?.query[key].split(/(\$[^\s]+)/g).map((value: string) => {
                            if (value.includes("$")) {
                              return <span className="variable">{value}</span>;
                            } else {
                              return value;
                            }
                          })}
                        </small>
                      </div>
                      <div className="actions">
                        <Button
                          icon={<TrashIcon />}
                          onClick={() => {
                            deleteQuery(key);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <CodeEditor
                language="json"
                value={node?.rawData ?? ""}
                onChange={(value: string) => {
                  update(null, "rawData", setNode, node, value);
                }}
              />
            </>
          )}
        </>
      ) : activeTab === "result" ? (
        <>
          <h4 className="at-u-pt2 at-u-pb1 at-u-semibold">Mapear Resultado da Requisição</h4>
          <small style={{ lineHeight: 1.8 }}>
            Abaixo você pode definir variáveis conforme o retorno da request, os dados estão armazenados no token{" "}
            <span className="at-badge" at-bg="reverse-primary">
              $
            </span>
            , caso o resultado seja um objeto, você pode acessar suas propriedades internas da seguinte maneira{" "}
            <span className="at-badge" at-bg="reverse-primary">
              $.propriedade
            </span>{" "}
            ou caso o valor mapeado retorne um array, você pode acessar o conteúdo da seguinte maneira{" "}
            <span className="at-badge" at-bg="reverse-primary">
              $.clientes[0].nome
            </span>
          </small>
          <div className="at-u-flex at-u-mt2">
            <Field
              id="request-result-key"
              className="at-u-col-6 at-u-pr1"
              value={resultForm.key}
              onChange={(value: any) => {
                update(null, "key", setResultForm, resultForm, value);
              }}
              label="Variável"
              type="select-variable"
              placeholder="Selecione uma variável"
              showVariableForm={showVariableForm}
              setShowVariableForm={setShowVariableForm}
            />
            <Field id="request-result-value" className="at-u-col-6 at-u-pl1" value={resultForm.value} onChange={(e: KeyboardEvent) => update(e, "value", setResultForm, resultForm)} label="Valor" type="input" placeholder="Ex : $.status" onKeyEnter={pushHeader} />
          </div>
          <div at-right="true" className="at-u-pb2 at-u-border-bottom">
            <Button label="Adicionar" onClick={pushResult} background="primary" />
          </div>
          <div className="items-list at-u-mt2">
            {Object.keys(node?.retorno).map((key, index) => {
              return (
                <div className="item" at-shadow="small" key={index}>
                  <div className="at-u-flex-grow">
                    <strong>{key}</strong>
                    <small>{node?.retorno[key]}</small>
                  </div>
                  <div className="actions">
                    <Button
                      icon={<TrashIcon />}
                      onClick={() => {
                        deleteResult(key);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </>
  );
};

const ActionAttachmentImage = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field id="attachment-image-url" value={node?.file} onChange={(e: KeyboardEvent) => update(e, "file", setNode, node)} label="URL da Imagem" type="input" placeholder="Ex : https://convert.app.br/imagem.png" />
    </>
  );
};

const ActionAttachmentFile = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field id="attachment-file-name" value={node?.fileName} onChange={(e: KeyboardEvent) => update(e, "fileName", setNode, node)} label="Nome do Arquivo" type="input" placeholder="Ex : Laudo - Exame" />
      <Field id="attachment-file-url" value={node?.file} onChange={(e: KeyboardEvent) => update(e, "file", setNode, node)} label="URL do Arquivo" type="input" placeholder="Ex : https://convert.app.br/documento.pdf" />
    </>
  );
};

const ActionSendEmail = ({ node, setNode, update, nodeId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const [form, setForm] = useState({
    to: "",
    cc: "",
    cco: "",
  });

  const pushEmail = (key: "to" | "cc" | "cco") => {
    if (form[key].replace(/\s/g, "") === "") {
      toast.error("Preencha todos os campos!");
      return;
    }
    if (!validateEmail(form[key])) {
      toast.error("Email inválido!");
      return;
    }

    const emails = node?.[key] ?? [];

    if (emails.includes(form[key])) {
      toast.error("Email já adicionado!");
      return;
    }
    setNode({
      ...node,
      [key]: [...emails, form[key]],
    });

    setForm({
      ...form,
      [key]: "",
    });
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email) || email.startsWith("$");
  };

  const deleteEmail = (key: "to" | "cc" | "cco", index: number) => {
    const emails = node?.[key] ?? [];
    emails.splice(index, 1);
    setNode({
      ...node,
      [key]: [...emails],
    });
  };

  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      <Field id="subject-node" value={node?.subject} onChange={(e: KeyboardEvent) => update(e, "subject", setNode, node)} label="Assunto do Email" type="input" placeholder="Ex : Segunda via do Boleto" />
      <div className="input-group">
        <Field id="email-to-node" value={form.to} onKeyEnter={() => pushEmail("to")} onChange={(e: KeyboardEvent) => update(e, "to", setForm, form)} label="Destinatários" type="input" placeholder="Ex : joao@convertcompany.com.br" />
        <Button label="Adicionar" onClick={() => pushEmail("to")} />
      </div>
      <div className={node?.to?.length ? "mb-4" : ""}>
        {node?.to?.map((email: string, index: number) => {
          return (
            <div className="badge tag" key={index}>
              <TbX onClick={() => deleteEmail("to", index)} />
              {email}
            </div>
          );
        })}
      </div>
      <div className="input-group">
        <Field id="email-cc-node" value={form.cc} onKeyEnter={() => pushEmail("cc")} onChange={(e: KeyboardEvent) => update(e, "cc", setForm, form)} label="CC (Com Cópia)" type="input" placeholder="Ex : joao@convertcompany.com.br" />
        <Button label="Adicionar" onClick={() => pushEmail("cc")} />
      </div>
      <div className={node?.cc?.length ? "mb-4" : ""}>
        {node?.cc?.map((email: string, index: number) => {
          return (
            <div className="badge tag" key={index}>
              <TbX onClick={() => deleteEmail("cc", index)} />
              {email}
            </div>
          );
        })}
      </div>
      <div className="input-group">
        <Field id="email-cco-node" value={form.cco} onKeyEnter={() => pushEmail("cco")} onChange={(e: KeyboardEvent) => update(e, "cco", setForm, form)} label="CCO (Com Cópia Oculta)" type="input" placeholder="Ex : joao@convertcompany.com.br" />
        <Button label="Adicionar" onClick={() => pushEmail("cco")} />
      </div>
      <div className={node?.cco?.length ? "mb-4" : ""}>
        {node?.cco?.map((email: string, index: number) => {
          return (
            <div className="badge tag" key={index}>
              <TbX onClick={() => deleteEmail("cco", index)} />
              {email}
            </div>
          );
        })}
      </div>
    </>
  );
};

const ConditionNodeSidebar = ({ node, setNode, update, nodeId, templateId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const forms = {
    option: {
      id: 1 as any,
      text: "" as string,
      keyword: "" as string,
      keywords: [] as string[],
      mode: "create" as string,
      currentIndex: 0 as number,
      condition: "includes" as string,
      regex: "" as string,
    },
  };
  const [optionForm, setOptionForm] = useState(forms.option);
  const [timegroups, setTimegroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRegexModal, setShowRegexModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (templateId === "date-hour-block") {
        const data = await getTimegroups();
        setTimegroups(data);
      }
      setLoading(false);
    };

    fetchData().catch((err) => {
      log("error", "Erro ao buscar dados", err);
      setLoading(false);
    });
  }, []);

  const timegroupsList = timegroups?.map((v: any, i) => {
    return {
      id: i + 2,
      label: v.nome,
      value: v._id,
    };
  });

  const comparisonType = [
    { id: 1, label: "Incluir", value: "includes" },
    { id: 2, label: "Ser igual à", value: "equals" },
    { id: 3, label: "Começar com", value: "startsWith" },
    { id: 4, label: "Terminar com", value: "endsWith" },
    { id: 5, label: "Ser maior que", value: "superior" },
    { id: 6, label: "Ser menor que", value: "inferior" },
    { id: 7, label: "Passar no teste", value: "regex" },
    { id: 8, label: "Ser vazio", value: "isEmpty" },
    { id: 9, label: "Não ser vazio", value: "notEmpty" },
  ];

  const formattedTypes: any = {
    includes: "incluir",
    equals: "ser igual à",
    startsWith: "começar com",
    endsWith: "terminar com",
    superior: "ser maior que",
    inferior: "ser menor que",
    regex: "passar no teste",
    notEmpty: "não ser vazio",
    isEmpty: "ser vazio",
  };

  /** Cria nova palavra chave na opção */
  const pushKeyword = (values: any[] = []) => {
    if (optionForm.keyword.replace(/\s/g, "") !== "") {
      if (!optionForm.keywords.includes(optionForm.keyword)) {
        if (!node.options?.find((option: any) => option.keywords.includes(optionForm.keyword) && optionForm.condition === option.condition)) {
          if (optionForm.keyword?.includes(";;")) {
            const keywords = optionForm.keyword?.split(";;");
            keywords.forEach((keyword: string) => {
              if (!optionForm.keywords.includes(keyword)) {
                optionForm.keywords.push(keyword);
              }
            });
          } else {
            optionForm.keywords.push(optionForm.keyword);
          }
          update(null, "keyword", setOptionForm, optionForm, "");
        } else {
          toast.error("Essa palavra chave já está cadastrada!");
        }
      } else {
        toast.error("Essa palavra chave já está cadastrada!");
      }
    }
    if (values.length) {
      values.forEach((value: any) => {
        if (!node.options?.find((option: any) => option.keywords.includes(value))) {
          if (!optionForm.keywords.includes(value)) {
            optionForm.keywords.push(value);
          }
        }
      });
    }
  };

  /** Deleta palavra chave na opção */
  const deleteKeyword = (index: number) => {
    setOptionForm({ ...optionForm, ...{ keywords: optionForm.keywords.filter((w, i) => i !== index) } });
  };

  /** Cadastra / Salva opção do menu */
  const saveOption = () => {
    if (optionForm.text.replace(/\s/g, "") !== "") {
      if (optionForm.condition === "regex") {
        pushKeyword([optionForm.regex]);
      }
      if (optionForm.keywords.length || optionForm.condition === "isEmpty" || optionForm.condition === "notEmpty") {
        if (optionForm.mode === "edit") {
          if (!node.options) return;
          node.options[optionForm.currentIndex] = JSON.parse(JSON.stringify(optionForm));
          update(null, "options", setNode, node, node.options);
          setOptionForm({ ...forms.option });
          toast.success("Condição salva!");
        } else {
          const option = JSON.parse(JSON.stringify(optionForm));
          update(null, "options", setNode, node, [...(node?.options || []), option]);
          setOptionForm({ ...forms.option });
          toast.success("Opção cadastrada!");
        }
      } else {
        toast.error("Cadastre ao menos uma palavra chave!");
      }
    } else {
      toast.error("Preencha todos os campos (Nome da Condição)!");
    }
  };

  /** Deleta opção do menu */
  const deleteOption = (index: number) => {
    setNode({ ...node, ...{ options: node.options?.filter((o: any, i: number) => index !== i) } });
    toast.success("Opção deletada!");
  };

  /** Edita uma opção do menu */
  const editOption = (o: any, index: number) => {
    setOptionForm({
      ...optionForm,
      ...o,
      ...{
        currentIndex: index,
        mode: "edit",
      },
    });
  };

  /** Sobe uma opção do menu */
  const upOption = (index: number) => {
    const ordered = JSON.parse(JSON.stringify(node?.options));
    var element = ordered[index];
    ordered.splice(index, 1);
    ordered.splice(index - 1, 0, element);
    setNode({ ...node, ...{ options: ordered } });
  };

  const handleRegexModalClose = (value?: string) => {
    if (value) {
      update(null, "regex", setOptionForm, optionForm, value);
    }
    setShowRegexModal(false);
  };

  return (
    <>
      {templateId === "date-hour-block" ? (
        <>
          <Field id="id-node" label="ID do Node" type="" description={nodeId} />
          <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Validar Nome" />
          <Field
            id="timegroup-id"
            value={node?.timegroupId}
            onChange={(value: any, obj: any) => {
              update(null, "timegroupId", setNode, node, value);
              update(null, "timegroup", setNode, node, obj);
            }}
            label="Grupo de Horário"
            type="select"
            placeholder={loading ? "Carregando..." : "Selecione um grupo"}
            options={timegroupsList}
          />
        </>
      ) : (
        <>
          <>
            <Field id="id-node" label="ID do Node" type="" description={nodeId} />
            <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Validar Nome" />
            <Field id="condition-eval" value={node?.eval} onChange={(value: any) => update(null, "eval", setNode, node, value)} label="A variável" type="select-variable" placeholder="Selecione uma variável" showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
            <hr />
            <>
              <div className="flex items-center gap-2">
                <Field id="condition-name" className="w-1/3" value={optionForm?.text} onChange={(e: KeyboardEvent) => update(e, "text", setOptionForm, optionForm)} label="Nome da Condição" type="input" placeholder="Ex: Válido" />
                <Field id="condition-type" className="w-2/3 shrink-select" value={optionForm?.condition} onChange={(value: any) => update(null, "condition", setOptionForm, optionForm, value)} label={`${node?.eval} deve`} type="select" placeholder="Selecione uma condição" options={comparisonType} />
              </div>
              {optionForm?.condition === "regex" ? (
                <div className="pb-2">
                  <div className="input-group">
                    <Field id="condition-regex" className="monospace-field" value={optionForm?.regex} onChange={(e: KeyboardEvent) => update(e, "regex", setOptionForm, optionForm)} label="Expressão Regular" type="input" placeholder="Ex: /\s/g" />
                    <IconButton icon={<TbExternalLink />} onClick={() => setShowRegexModal(true)} />
                  </div>
                  <small className="-mt-1 block">Esse campo executa um teste para verificar se o valor da variável é valido conforme a RegEx</small>
                  <RegexModal isOpen={showRegexModal} method="test" onClose={handleRegexModalClose} />
                </div>
              ) : optionForm?.condition !== "isEmpty" && optionForm?.condition !== "notEmpty" ? (
                <>
                  <div className="input-group">
                    <Field id="menu-option-keyword" value={optionForm.keyword} onChange={(e: KeyboardEvent) => update(e, "keyword", setOptionForm, optionForm)} onKeyEnter={pushKeyword} label="Palavras Chave" type="input" placeholder="Ex : Venda" />
                    <Button label="Adicionar" onClick={pushKeyword} />
                  </div>
                </>
              ) : null}

              {optionForm.keywords?.map((word: string, index: number) => {
                return (
                  <div className="badge tag" key={index}>
                    <TbX onClick={() => deleteKeyword(index)} />
                    {word}
                  </div>
                );
              })}
              <div className="at-button-group at-u-border-bottom at-u-pb2">
                <Button label={optionForm.mode === "edit" ? "Cancelar" : "Redefinir"} onClick={() => setOptionForm(forms.option)} />
                <Button label={optionForm.mode === "edit" ? "Salvar Opção!" : "Cadastrar Condição"} background="primary" onClick={saveOption} />
              </div>
              <div className="items-list at-u-mt2">
                {node?.options?.map((option: any, index: number) => {
                  return (
                    <>
                      <div className="item" at-shadow="small" key={index}>
                        <div className="at-u-flex-grow">
                          <strong>{option.text}</strong>
                          <small>
                            {node?.eval} deve {formattedTypes[option.condition]} {option.keywords.join(" ou ")}
                          </small>
                        </div>
                        <div className="actions">
                          {index > 0 && <Button icon={<TbArrowUpCircle />} onClick={() => upOption(index)} />}
                          <Button icon={<PencilSquareIcon />} onClick={() => editOption(option, index)} />
                          <Button icon={<TrashIcon />} onClick={() => deleteOption(index)} />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          </>
        </>
      )}
    </>
  );
};

const TransferNodeSidebar = ({ node, setNode, update, nodeId, templateId, showVariableForm, setShowVariableForm }: ISidebarProps) => {
  const { getNodes } = useReactFlow();
  const [nodes, setNodes] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const normalize = (s: string | undefined) => {
    if (!s) return "";
    return s
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9]/g, "");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (templateId === "transfer-to-node" || templateId === "transfer-to-anchor") {
        const nds = await getNodes();
        setNodes(nds);
      }
      setLoading(false);
    };

    fetchData().catch((err) => {
      log("error", "Erro ao buscar dados", err);
      setLoading(false);
    });
  }, []);

  const nodeList = nodes
    .map((node: any, index: number) => {
      let value = (node?.data?.props?.nodeTitle || "") + (node?.data?.props?.text?.replace(/(<([^>]+)>)/gi, "") || "") + index;
      if (node?.data?.category === "menu") {
        const options = node?.data?.props?.options?.map((option: any) => option?.text).join(",");
        value += options;
      }
      if (node?.data?.category === "transfer") {
        const sub = node?.data?.props?.anchor?.data?.nodeTitle || node?.data?.props?.user?.label || node?.data?.props?.department?.label || node?.data?.props?.anchor?.data?.nodeTitle;
        value += sub;
      }
      return {
        id: index + 2,
        title: node?.props?.nodeTitle,
        label: node?.data?.props?.nodeTitle,
        description: node?.id,
        data: node?.data?.props,
        value: node?.id,
        filterBy: normalize(value).toLowerCase() + node?.id,
      };
    })
    .filter((node: any) => node.value !== nodeId);

  const anchorList = nodes
    .filter((node: any) => node?.data?.id === "anchor-node")
    .map((node: any, index: number) => {
      return {
        id: index + 2,
        value: node?.id,
        icon: (
          <div className="anchor" at-tx="white" at-accent={node?.data?.props?.accent}>
            <BiAnchor color="#FFF" width={12} height={12} /> {node?.data?.props?.nodeTitle}
          </div>
        ),
        data: node?.data?.props,
      };
    })
    .filter((node: any) => node.value !== nodeId);

  const generic = {
    id: 1,
    label: "Nenhum",
    description: "Transferir para fila",
    value: "none",
    icon: <QueueListIcon />,
  };

  const variableOption = {
    id: 2,
    label: "Variável",
    description: "Transferir para um valor mapeado",
    value: "variable",
    icon: <BiPurchaseTag />,
  };

  const mapUsers = (data: any) => {
    return data?.map((d: any, i: any) => {
      return {
        id: d._id,
        label: d.name,
        description: d.username,
        value: d._id,
        leftItem: <img src={`https://${globalThis.host}/avatar/${d.username}`} alt={`avatar-${d.username}`} className="select-avatar" />,
        data: d,
      };
    });
  };
  const mapDepartments = (data: any) => {
    return data?.map((d: any, i: any) => {
      return {
        id: d._id,
        label: d.name,
        description: d.username,
        value: d._id,
        leftItem: <div className="select-color" style={{ backgroundColor: d.color }}></div>,
        data: d,
      };
    });
  };

  return (
    <>
      <Field id="id-node" label="ID do Node" type="" description={nodeId} />
      <Field id="title-node" value={node?.nodeTitle} onChange={(e: KeyboardEvent) => update(e, "nodeTitle", setNode, node)} label="Titulo do Bloco" type="input" placeholder="Ex : Bloco Técnicos" />
      {templateId === "transfer-to-department" ? (
        <>
          <Field
            id="department-select"
            value={node?.departmentId}
            getData={getDepartments}
            transformData={mapDepartments}
            onChange={(value: any, obj: any) => {
              update(null, "departmentId", setNode, node, value);
              update(null, "department", setNode, node, obj);
            }}
            label="Departamento"
            type="async-select"
            placeholder={loading ? "Carregando..." : "Selecione um departamento"}
            options={[variableOption]}
          />
          {node?.departmentId === "variable" && <Field id="variable-select-department" value={node?.variableDepartmentId} onChange={(value: any, obj: any) => update(null, "variableDepartmentId", setNode, node, value)} label="Variável (Departamento)" type="select-variable" showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />}
          <Field
            id="user-select"
            value={node?.userId}
            getData={getUsers}
            transformData={mapUsers}
            onChange={(value: any, obj: any) => {
              update(null, "userId", setNode, node, value);
              update(null, "user", setNode, node, obj);
            }}
            label="Para o Usuário"
            type="async-select"
            placeholder={loading ? "Carregando..." : "Selecione um usuário"}
            options={[variableOption, generic]}
          />
          {node?.userId === "variable" && <Field id="variable-select-user" value={node?.variableUserId} onChange={(value: any, obj: any) => update(null, "variableUserId", setNode, node, value)} label="Variável (Usuário)" type="select-variable" showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />}
          <Field id="message-node" value={node?.text} onChange={(editor: any) => update(null, "text", setNode, node, editor.getHTML())} label="Mensagem" type="text-editor" placeholder="Ex: Transferindo seu atendimento" max={4096} setShowVariableForm={setShowVariableForm} />
        </>
      ) : templateId === "transfer-to-user" ? (
        <>
          <Field
            id="user-select"
            value={node?.userId}
            getData={getUsers}
            transformData={mapUsers}
            onChange={(value: any, obj: any) => {
              update(null, "userId", setNode, node, value);
              update(null, "user", setNode, node, obj);
            }}
            label="Para o Usuário"
            type="async-select"
            placeholder={loading ? "Carregando..." : "Selecione um usuário"}
            options={[variableOption]}
          />
          {node?.userId === "variable" && <Field id="variable-select-user" value={node?.variableUserId} onChange={(value: any, obj: any) => update(null, "variableUserId", setNode, node, value)} label="Variável (Usuário)" type="select-variable" showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />}
          <Field id="message-node" value={node?.text} onChange={(editor: any) => update(null, "text", setNode, node, editor.getHTML())} label="Mensagem" type="text-editor" placeholder="Ex: Transferindo seu atendimento" max={4096} setShowVariableForm={setShowVariableForm} />
        </>
      ) : templateId === "transfer-to-node" ? (
        <Field
          id="node-select"
          value={node?.nodeId}
          onChange={(value: any, obj: any) => {
            update(null, "nodeId", setNode, node, value);
            update(null, "node", setNode, node, obj);
          }}
          label="Para o Bloco"
          type="search-select"
          placeholder={loading ? "Carregando..." : "Selecione um Bloco"}
          options={nodeList}
        />
      ) : templateId === "transfer-to-anchor" ? (
        <Field
          id="anchor-select"
          value={node?.anchorId}
          onChange={(value: any, obj: any) => {
            update(null, "anchorId", setNode, node, value);
            update(null, "anchor", setNode, node, obj);
          }}
          label="Para a Âncora"
          type="select"
          placeholder={loading ? "Carregando..." : "Selecione uma âncora"}
          options={anchorList}
        />
      ) : null}
    </>
  );
};

const PhoneMockup = ({ node, data }: { node: INodeProps; data: INode }) => {
  const handleImageError = ({ target }: { target: any }) => {
    target.onerror = null;
    target.src = `https://cdn.letteldata.com.br/images/convert-company-icon.png`;
  };
  return (
    <div className="phone-mockup animate__fadeInLeft animate__animated">
      <div className="whatsapp-mockup">
        <div className="whatsapp-mockup-title">
          <div className="at-u-flex at-u-items-center">
            <div className="avatar">
              <img src={`https://${globalThis.host}/assets/logo_cliente.png`} onError={handleImageError} alt={`logo-cliente`} />
            </div>
            <div>
              <h4 at-tx="white">{globalThis.title}</h4>
              <small at-tx="transparent-white">online</small>
            </div>
          </div>
        </div>
        <div className="whatsapp-mockup-body">
          {node?.mode === "quickreply" ? (
            <>
              <WhatsAppMessage type="quickreply" message={node?.text} richText={true} node={node} />
              {node?.options?.map((option: INodeMenuItem, index: number) => (
                <WhatsAppMessage type="button" key={index} message={option.text} />
              ))}
            </>
          ) : node?.mode === "list" ? (
            <WhatsAppMessage type="list" message={node?.text} richText={true} node={node} />
          ) : node?.mode === "text" ? (
            <WhatsAppMessage type="text" message={node?.text} richText={true} node={node} />
          ) : data?.data?.id === "send-attachment-image" ? (
            <WhatsAppMessage richText={true} message={node?.file} type={"image"} node={node} />
          ) : data?.data?.id === "send-attachment-file" ? (
            <WhatsAppMessage richText={true} message={node?.file} type={"file"} node={node} />
          ) : node?.longitude ? (
            <WhatsAppMessage richText={true} message={node?.text} latitude={node?.latitude} longitude={node?.longitude} address={node?.address} addressName={node?.name} type={"received-location"} node={node} />
          ) : (
            <WhatsAppMessage type="received" message={node?.text} richText={true} node={node} />
          )}
        </div>
      </div>
    </div>
  );
};

const EmailMockup = ({ node, update, setNode }: ISidebarProps) => {
  const handleImageError = ({ target }: { target: any }) => {
    target.onerror = null;
    target.src = `https://cdn.letteldata.com.br/images/convert-company-icon.png`;
  };
  return (
    <div className="email-preview">
      {node?.subject ? <h3 className="text-lg font-medium min-h-[32px] text-gray-900">{node?.subject}</h3> : <h3 className="text-md text-gray-300 font-medium min-h-[32px]">Assunto do email...</h3>}
      <header>
        <div className="flex items-center">
          <div className="avatar self-start">
            <img src={`https://${globalThis.host}/assets/logo_cliente.png`} onError={handleImageError} alt="logo-cliente" />
          </div>
          <div className="grow leading-normal">
            <div className="flex items-center">
              <h4 className="grow font-semibold text-gray-900">{globalThis.title}</h4>
              <small className="block font-medium">Hoje às {new Date()?.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" })} (agora)</small>
            </div>
            {!!node?.to?.length && <small className="block font-medium">Para : {node?.to?.join(", ")} </small>}
            {!!node?.cc?.length && <small className="block font-medium">CC : {node?.cc?.join(", ")} </small>}
            {!!node?.cco?.length && <small className="block font-medium">CCO : {node?.cco?.join(", ")} </small>}
          </div>
        </div>
      </header>
      <AdvancedTextEditor onChange={(editor: any) => update(null, "body", setNode, node, editor.getHTML())} value={node?.body} />
    </div>
  );
};

const VariableForm = ({ onCreate, onCancel }: { onCreate: (...args: any) => void; onCancel: (...args: any) => void }) => {
  const scopeOptions = [
    {
      id: 1,
      label: "Visitante",
      value: "visitor",
      description: "visitor",
      badge: (
        <div className="at-badge" data-scope="visitor" at-bg="primary">
          visitor
        </div>
      ),
    },
    {
      id: 2,
      label: "Sala",
      value: "room",
      description: "room",
      badge: (
        <div className="at-badge" data-scope="room" at-bg="primary">
          room
        </div>
      ),
    },
  ];
  const typeOptions = [
    { id: 1, label: "Texto", value: "text" },
    { id: 3, label: "Número", value: "number" },
    { id: 4, label: "Telefone", value: "phone" },
    { id: 5, label: "Data", value: "date" },
    { id: 6, label: "Texto Longo", value: "textarea" },
  ];
  const form = {
    label: "",
    field: "",
    scope: "",
    type: "",
    placeholder: "",
    visibility: true,
  };
  const [variableForm, setVariableForm] = useState(form);
  const persist = async () => {
    if (variableForm.label !== "" && variableForm.field !== "" && variableForm.scope !== "" && variableForm.type !== "") {
      const res = await createVariable(variableForm);
      if (res) {
        log("success", `Nova variável cadastrada (${variableForm.label})`, res);
        toast.success("Variável Cadastrada");
        onCreate(variableForm);
        setVariableForm(form);
      } else {
        toast.error("Erro ao cadastrar variável");
      }
    } else {
      toast.error("Preencha todos os campos");
    }
  };
  return (
    <>
      <div className="node-variable-form animate__fadeInRight animate__animated">
        <h3>Cadastrar uma variável</h3>
        <p>Por favor, preencha todos os campos abaixo</p>
        <div className="at-close" onClick={onCancel}></div>
        <div className="at-u-mt2">
          <Field value={variableForm.label} onChange={(e: any) => setVariableForm({ ...variableForm, ...{ label: e?.target?.value } })} id="variable-name" type="input" label="Nome do Campo" placeholder="Ex : Código do Ticket"></Field>
          <Field value={variableForm.field} onChange={(e: any) => setVariableForm({ ...variableForm, ...{ field: e?.target?.value } })} id="variable-id" type="input" label="ID do Campo" placeholder="Ex : codigo_ticket"></Field>
          <Field value={variableForm.scope} onChange={(value: any) => setVariableForm({ ...variableForm, ...{ scope: value } })} id="variable-scope" type="select" label="Escopo do Campo" options={scopeOptions}></Field>
          <Field value={variableForm.type} onChange={(value: any) => setVariableForm({ ...variableForm, ...{ type: value } })} id="variable-type" type="select" label="Tipo do Campo" options={typeOptions}></Field>
          <Field value={variableForm.placeholder} onChange={(e: any) => setVariableForm({ ...variableForm, ...{ placeholder: e?.target?.value } })} id="variable-placeholder" type="input" label="Placeholder" placeholder="Ex : 45920"></Field>
          <Field checked={variableForm.visibility} onChange={() => setVariableForm({ ...variableForm, ...{ visibility: !variableForm.visibility } })} id="variable-visible" type="checkbox" label="Visível"></Field>
        </div>
        <div className="at-button-group at-u-mt3">
          <Button outlined={true} label="Cancelar" onClick={onCancel} />
          <Button background="slate-900" label="Cadastrar Variável" onClick={persist} />
        </div>
      </div>
    </>
  );
};

export default NodeSidebar;
