import type { FC } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { useReactFlow } from "reactflow";
import { addHighlight, dropBlock, removeHighlights } from ".";
import { BiErrorAlt } from "react-icons/bi";
import { TiWarningOutline } from "react-icons/ti";
import { TbFlag } from "react-icons/tb";

const StartNode: FC<NodeProps> = ({ data, id }) => {
  const flow = useReactFlow();
  return (
    <div className="custom-node" onDragEnter={(e) => addHighlight(e, id, data?.id)} onDragLeave={(e) => removeHighlights(e, id, data?.id)} onDrop={(e) => dropBlock(e, id, data?.id, flow)}>
      <div className="flex items-center gap-3">
        <div className="start-icon">
          <TbFlag />
        </div>
        <h4 className="leading-4 font-semibold">Inicio do Fluxo</h4>
      </div>
      <Handle type="source" id={`handle-${id}-bottom`} position={Position.Bottom} />
      <div className="node-title">
        <div className="status">
          <div className="at-badge node-status data-error-count" at-bg="danger">
            <BiErrorAlt />
          </div>
          <div className="at-badge node-status data-warning-count" at-bg="amber-300" at-tx="slate-800">
            <TiWarningOutline />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartNode;
