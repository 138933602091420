import { Menu } from "@headlessui/react";

interface IDropdownItem {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

interface IDropdown extends React.HTMLAttributes<HTMLDivElement> {
  items: Array<IDropdownItem>;
  button?: React.ReactNode;
}

const Dropdown = (props: IDropdown) => {
  const { items, button, ...rest } = props;
  return (
    <>
      <Menu as="div" className="at-dropdown" {...rest}>
        {button ? <Menu.Button as="div">{button}</Menu.Button> : <Menu.Button as="div">Mais</Menu.Button>}
        <Menu.Items className="at-dropdown-items">
          {items.map((item: IDropdownItem, index: number) => {
            return (
              <Menu.Item as="div" className="at-dropdown-item" key={index} onClick={item.onClick && item.onClick} data-label={item.label}>
                {item.icon}
                <label>{item.label}</label>
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Menu>
    </>
  );
};

export default Dropdown;
