import { Button } from "..";
import { TiWarningOutline } from "react-icons/ti";
import { BiErrorAlt } from "react-icons/bi";

const ErrorModal = ({ errors = [], zoomToNode = () => {}, hideModal = () => {} }: any) => {
  return (
    <>
      <div className="at-modal" at-active={errors.length ? "true" : null}>
        <div className="at-modal-container at-u-col-10">
          <div className="at-close" onClick={hideModal}></div>
          <h2>
            {errors.length} Problema{errors.length > 1 ? "s" : ""} encontrado{errors.length > 1 ? "s" : ""}
          </h2>
          <p>
            Ao realizar a conversão do fluxo para um JSON foram encontrados {errors.length} erro{errors.length > 1 ? "s" : ""}, por favor corrija os erros, e salve o fluxo novamente
          </p>
          <div className="errors-list">
            {errors?.map((error: any, index: number) => {
              return (
                <div className="error" key={index}>
                  <div>
                    {error?.level === 1 ? (
                      <div className="alert-icon" at-bg="amber-300" at-tx="slate-800">
                        <TiWarningOutline />
                      </div>
                    ) : error?.level === 2 ? (
                      <div className="alert-icon" at-bg="danger">
                        <BiErrorAlt />
                      </div>
                    ) : null}
                  </div>
                  <div className="at-u-flex-grow">
                    <label>{error?.message}</label>
                    <small>
                      Local : {error?.node?.data?.props?.nodeTitle} ({error?.nodeId})
                    </small>
                  </div>
                  <div>
                    <Button label="Visualizar" background="slate-900" onClick={() => zoomToNode(error?.node)} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorModal;
