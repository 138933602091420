import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-dracula";

interface ICodeEditor {
  value: string;
  placeholder?: string;
  language?: string;
  onChange?: (value: string, event: any) => void;
}
const CodeEditor = (props: ICodeEditor) => {
  const { value, placeholder = "", language = "json", onChange = () => {} } = props;

  return (
    <>
      <div>
        <div at-shadow="small" className="at-code-block at-code-block-result">
          <div className="at-code-block-titlebar">
            <div className="at-code-block-traffic-lights">
              <div></div> <div></div> <div></div>
            </div>
          </div>
          <AceEditor mode={language} setOptions={{ useWorker : false }} defaultValue={value} theme="dracula" placeholder={placeholder} highlightActiveLine={true} onChange={onChange} />
        </div>
      </div>
    </>
  );
};

export default CodeEditor;
