import { KeyboardEvent } from "react";
import { uuid } from "../services/methods";
import { CustomSearchableSelect, PureTextEditor, SearchableSelect, Select, TextEditor, VariableSelect } from "./";

interface IField {
  className?: string;
  type?: "text" | "input" | "select" | "async-select" | "select-variable" | "text-editor" | "pure-text-editor" | "number" | "radio" | "checkbox" | "textarea" | string;
  name?: string;
  label?: string;
  description?: string;
  placeholder?: string;
  options?: any[];
  disabled?: boolean;
  min?: number | null;
  max?: number | null;
  id?: string;
  value?: any;
  suggestions?: string[] | { label: string; value: string }[];
  quickValues?: string[] | { label: string; value: string }[];
  checked?: boolean;
  showVariableForm?: boolean;
  onChange?: (...args: any) => void;
  onBlur?: (...args: any) => void;
  onKeydown?: (...args: any) => void;
  onKeyEnter?: (...args: any) => void;
  setShowVariableForm?: (show: boolean) => void;
  getData?: (...args: any) => Promise<any> | undefined;
  transformData?: (...args: any) => void;
  autofocus?: boolean;
}
const Field = (props: IField) => {
  let { id } = props;
  const { className = "", autofocus = false, type = "text", name = "", label = "", description = "", placeholder = "", options = [], disabled = false, min = null, max = null, value = "", onChange = () => {}, onBlur = () => {}, onKeydown = () => {}, onKeyEnter = () => {}, suggestions = [], checked = false, showVariableForm, setShowVariableForm, getData = () => {}, transformData = () => {}, quickValues } = props;

  if (!id) id = `app-field-${uuid()}`;

  const keypress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onKeyEnter(e);
    } else {
      onKeydown(e);
    }
  };
  return (
    <div className={"at-field " + className} data-type={type}>
      <small>{description}</small>

      {quickValues?.length ? (
        <div className="at-field-quick-values">
          {quickValues.map((v: string | { label: string; value: string }, i) => (
            <button className="badge at-u-clickable" key={i} onClick={() => onChange({ target: { value: typeof v === "object" ? v.value : v } })}>
              {typeof v === "object" ? v.label : v}
            </button>
          ))}
        </div>
      ) : null}
      {type === "select" ? (
        <Select options={options} value={value} placeholder={placeholder} onChange={onChange} key={"select-field-" + id} />
      ) : type === "search-select" ? (
        <CustomSearchableSelect value={value} onChange={onChange} key={"select-field-" + id} options={options} />
      ) : type === "async-select" ? (
        <SearchableSelect value={value} onChange={onChange} getData={getData} transformData={transformData} key={"select-field-" + id} extraOptions={options} />
      ) : type === "select-variable" ? (
        <VariableSelect value={value} onChange={onChange} key={"select-field-" + id} typeId={id} showVariableForm={showVariableForm} setShowVariableForm={setShowVariableForm} />
      ) : type === "pure-text-editor" ? (
        <PureTextEditor onChange={onChange} value={value} placeholder={placeholder} />
      ) : type === "text-editor" ? (
        <TextEditor onChange={onChange} onBlur={onBlur} value={value} placeholder={placeholder} setShowVariableForm={setShowVariableForm} />
      ) : type === "input" ? (
        <>
          <input autoFocus={autofocus} id={id} name={name} placeholder={placeholder} disabled={disabled} value={value} maxLength={max as number} onChange={onChange} onKeyDown={keypress} list={suggestions?.length ? `datalist-${id}` : ""} />
          {suggestions.length ? (
            <datalist id={`datalist-${id}`}>
              {suggestions.map((s: string | { label: string; value: string }, i) => (
                <option key={i} value={typeof s === "object" ? s.value : s}>
                  {typeof s === "object" ? s.label : s}
                </option>
              ))}
            </datalist>
          ) : null}
        </>
      ) : type === "textarea" ? (
        <textarea id={id} name={name} placeholder={placeholder} disabled={disabled} value={value} onChange={onChange} onKeyDown={keypress}></textarea>
      ) : type === "number" ? (
        <input
          id={id}
          name={name}
          min={min as number}
          max={max as number}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            let { value, min, max } = e.target;
            if (min !== null && parseInt(value) < parseInt(min)) {
              value = min;
            }
            if (max !== null && parseInt(value) > parseInt(max)) {
              value = max;
            }
            onChange({
              ...e,
              target: {
                ...e.target,
                value: value,
              },
            });
          }}
          onKeyDown={keypress}
          type="number"
        />
      ) : type === "radio" || type === "checkbox" ? (
        <>
          <label>
            <input id={id} name={name} disabled={disabled} value={value} checked={checked} onChange={onChange} type={type} />
            {label}
          </label>
        </>
      ) : null}
      {type !== "radio" && type !== "checkbox" && <label>{label}</label>}
    </div>
  );
};

export default Field;
