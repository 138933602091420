import { Node, NodePositionChange, XYPosition } from "reactflow";

const log = (level: string = "info", title: string = "Convert Flowbuilder", ...messages: any) => {
  const status = {
    info: {
      backgroundColor: "#7dd3fc",
      color: "#1c3a48",
      emoji: "💬",
    },
    success: {
      backgroundColor: "#86efac",
      color: "#223344",
      emoji: "✔️",
    },
    flowbuilder: {
      backgroundColor: "#fba0f8",
      color: "#2f112e",
      emoji: "🧠",
    },
    error: {
      backgroundColor: "#fca5a5",
      color: "#501111",
      emoji: "🚨",
    },
    warn: {
      backgroundColor: "#fcd34d",
      color: "#223344",
      emoji: "🚧",
    },
    fatal: {
      backgroundColor: "#000000",
      color: "#FFFFFF",
      emoji: "💀",
    },
  } as any;
  const styles = `
      font-weight: 600;
      padding: 1px 3px;
      border-radius: 6px;
      background: ${status[level]?.backgroundColor};
      color: ${status[level]?.color};
      margin: 1px;
      border: solid 1px ${status[level]?.color}4c;
      box-shadow : 0 0 5px 5px black;
      font-family : system-ui;
      text-align: center;
      font-size: 10px;
      margin-right: 5px;
    `;
  const timestampStyle = `
      font-weight: 500;
      color: #8899AA;
    `;
  const first = `${status[level]?.emoji}`;
  console.groupCollapsed(`%c${new Date().toLocaleTimeString()} %c${first} ${level.toUpperCase()} %c${title}`, timestampStyle, styles, "");
  console.trace(...messages);
  console.groupEnd();
};

const logger = {
  info: (title: string, ...args: any) => log("info", title, ...args),
  success: (title: string, ...args: any) => log("success", title, ...args),
  error: (title: string, ...args: any) => log("error", title, ...args),
  warn: (title: string, ...args: any) => log("warn", title, ...args),
  fatal: (title: string, ...args: any) => log("fatal", title, ...args),
  action: (title: string, ...args: any) => log("action", ` ${title}`, ...args),
};

type GetHelperLinesResult = {
  horizontal?: number;
  vertical?: number;
  snapPosition: Partial<XYPosition>;
};

const getHelperLines = (change: NodePositionChange, nodes: Node[], distance = 5): GetHelperLinesResult => {
  const defaultResult = { horizontal: undefined, vertical: undefined, snapPosition: { x: undefined, y: undefined } };
  const nodeA = nodes.find((node) => node.id === change.id);

  if (!nodeA || !change.position) {
    return defaultResult;
  }

  const nodeABounds = {
    left: change.position.x,
    right: change.position.x + (nodeA.width ?? 0),
    top: change.position.y,
    bottom: change.position.y + (nodeA.height ?? 0),
    width: nodeA.width ?? 0,
    height: nodeA.height ?? 0,
  };

  let horizontalDistance = distance;
  let verticalDistance = distance;

  return nodes
    .filter((node) => node.id !== nodeA.id)
    .reduce<GetHelperLinesResult>((result, nodeB) => {
      const nodeBBounds = {
        left: nodeB.position.x,
        right: nodeB.position.x + (nodeB.width ?? 0),
        top: nodeB.position.y,
        bottom: nodeB.position.y + (nodeB.height ?? 0),
        width: nodeB.width ?? 0,
        height: nodeB.height ?? 0,
      };

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //  |
      //  |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);

      if (distanceLeftLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceLeftLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceRightRight = Math.abs(nodeABounds.right - nodeBBounds.right);

      if (distanceRightRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceRightRight;
      }

      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     A     |
      //              |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);

      if (distanceLeftRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceLeftRight;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     B     |
      //              |___________|
      const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);

      if (distanceRightLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceRightLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|     |___________|
      const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);

      if (distanceTopTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceTopTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|_________________
      //                    |           |
      //                    |     B     |
      //                    |___________|
      const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);

      if (distanceBottomTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceBottomTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|_____|___________|
      const distanceBottomBottom = Math.abs(nodeABounds.bottom - nodeBBounds.bottom);

      if (distanceBottomBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceBottomBottom;
      }

      //                    |‾‾‾‾‾‾‾‾‾‾‾|
      //                    |     B     |
      //                    |           |
      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
      //  |     A     |
      //  |___________|
      const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);

      if (distanceTopBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceTopBottom;
      }

      //align to center of node
      if (nodeABounds.left + nodeABounds.width / 2 === nodeBBounds.left + nodeBBounds.width / 2) {
        result.snapPosition.x = nodeBBounds.left + nodeBBounds.width / 2 - nodeABounds.width / 2;
        result.vertical = nodeBBounds.left + nodeBBounds.width / 2;
      }

      return result;
    }, defaultResult);
};

export { log, logger, getHelperLines };
