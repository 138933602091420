import type { FC } from "react";
import type { NodeProps } from "reactflow";
import { BiCheckCircle, BiErrorAlt } from "react-icons/bi";
import { TbEqual, TbFilter, TbHourglassHigh, TbLetterCase, TbLetterCaseLower, TbLetterCaseUpper, TbMail, TbMinus, TbPlus, TbTextIncrease, TbZoomReplace } from "react-icons/tb";
import { useReactFlow, Handle, Position } from "reactflow";
import { addHighlight, removeHighlights, dropBlock } from ".";
import { Title, ItemHandle } from "./";
import { colors } from "../../services/constants";
import { RichPreview, WhatsAppMessage } from "../";
import { PiEquals } from "react-icons/pi";

/** Bloco com ações programáveis */
const ActionNode: FC<NodeProps> = ({ data, id }) => {
  const flow = useReactFlow();
  return (
    <div className="custom-node" at-accent="amber" data-type={data?.id} onDragEnter={(e) => addHighlight(e, id, data?.id)} onDragLeave={(e) => removeHighlights(e, id, data?.id)} onDrop={(e) => dropBlock(e, id, data?.id, flow)}>
      <div className="node-body">
        {(data?.id === "send-new-message" || data?.id === "close-chat" || data?.id === "user-input" || data?.id === "set-variable" || data?.id === "wait-delay" || data?.id === "send-location" || data?.id === "add-tags" || data?.id === "set-tabulacao" || data?.id === "send-attachment-image" || data?.id === "send-attachment-file") && (
          <>
            <Title data={data} />
            {data?.props?.text && data?.props?.text.replace(/(<([^>]+)>)/gi, "") !== "" && (
              <div className="whatsapp-mockup at-u-lg-rounded at-u-p1 at-u-px2 at-u-mt1">
                <WhatsAppMessage richText={true} message={data?.props?.text} type={"received"} node={data?.props} />
              </div>
            )}
            {data?.props?.longitude && data?.props?.latitude && (
              <div className="whatsapp-mockup at-u-lg-rounded at-u-p1 at-u-px2 at-u-mt1">
                <WhatsAppMessage richText={true} message={data?.props?.text} latitude={data?.props?.latitude} longitude={data?.props?.longitude} address={data?.props?.address} addressName={data?.props?.name} type={"received-location"} node={data?.props} />
              </div>
            )}
          </>
        )}
        {data?.id === "new-http-request" && (
          <>
            <Title
              data={data}
              preTitle={
                <span className="at-badge at-u-mr1 at-u-align-middle" data-method={data?.props?.method}>
                  {data?.props?.method}
                </span>
              }
            />
            <small className="block overflow-hidden p-1">{data?.props?.url}</small>
            <div className="node-bar">
              <ItemHandle label="Sucesso" icon={<BiCheckCircle />} nodeId={id} background="emerald-500" textColor="white" handleId={"request-ok"} />
              <ItemHandle label="Falhou" icon={<BiErrorAlt />} nodeId={id} background="rose-500" textColor="white" handleId={"request-failed"} />
              <ItemHandle label="Timeout" icon={<TbHourglassHigh />} nodeId={id} background="slate" textColor="slate-500" handleId={"request-timeout"} />
            </div>
          </>
        )}
        {data?.id === "set-variable" && (
          <>
            <div className="at-u-flex at-u-items-center">
              {data?.props?.key && (
                <>
                  <div className="transfer-card at-u-mt1" style={{ height: 45 }}>
                    <div at-left="true">
                      <h5 style={{ marginBottom: 2 }}>{data?.props?.variable?.label}</h5>
                      <small at-tx="slate">{data?.props?.variable?.value}</small>
                    </div>
                  </div>
                  <div className="grid place-items-center p-3">
                    {data?.props?.stringAction === "none" ? (
                      <>
                        <PiEquals />
                        <small className="text-[10px]">Igual</small>
                      </>
                    ) : data?.props?.stringAction === "replace" ? (
                      <>
                        <TbZoomReplace />
                        <small className="text-[10px]">Substituir</small>
                      </>
                    ) : data?.props?.stringAction === "match" ? (
                      <>
                        <TbFilter />
                        <small className="text-[10px]">Extrair</small>
                      </>
                    ) : data?.props?.stringAction === "toLowerCase" ? (
                      <>
                        <TbLetterCase />
                      </>
                    ) : data?.props?.stringAction === "toUpperCase" ? (
                      <>
                        <TbLetterCase />
                      </>
                    ) : data?.props?.stringAction === "sum" ? (
                      <>
                        <TbPlus />
                        <small className="text-[10px]">Somar</small>
                      </>
                    ) : data?.props?.stringAction === "subtract" ? (
                      <>
                        <TbMinus />
                        <small className="text-[10px]">Subtrair</small>
                      </>
                    ) : null}
                  </div>
                  {data?.props?.stringAction === "none" ? (
                    <>
                      {data?.props?.variable?.type === "textarea" ? (
                        <div className="rich-preview">
                          <RichPreview content={data?.props?.value} />
                        </div>
                      ) : (
                        <div className="transfer-card" style={{ height: 35 }}>
                          <div at-left="true">
                            <small>{data?.props?.value}</small>
                          </div>
                        </div>
                      )}
                    </>
                  ) : data?.props?.stringAction === "replace" || data?.props?.stringAction === "match" || data?.props?.stringAction === "sum" || data?.props?.stringAction === "subtract" ? (
                    <>
                      <div className="transfer-card" style={{ height: 35 }}>
                        <div at-left="true">
                          <small className="!font-mono font-medium">{data?.props?.stringActionValue}</small>
                        </div>
                      </div>
                    </>
                  ) : data?.props?.stringAction === "toLowerCase" ? (
                    <>
                      <div className="transfer-card" style={{ height: 46 }}>
                        <div at-left="true">
                          <small>
                            Transformar em <br /> Minúsculas
                          </small>
                        </div>
                      </div>
                    </>
                  ) : data?.props?.stringAction === "toUpperCase" ? (
                    <>
                      <div className="transfer-card" style={{ height: 46 }}>
                        <div at-left="true">
                          <small>
                            Transformar em <br /> Maiúsculas
                          </small>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </>
        )}
        {data?.id === "send-email" && (
          <>
            <Title data={data} />
            {!!data?.props?.subject && (
              <div className="transfer-card truncate basis-full flex gap-2 !mt-2">
                <div className="h-5">
                  <TbMail className="!w-5 !h-5" />
                </div>
                <label className="truncate leading-4">{data?.props?.subject}</label>
              </div>
            )}
            <div className="node-bar">
              <ItemHandle label="Email enviado" icon={<BiCheckCircle />} nodeId={id} background="emerald-500" textColor="white" handleId={"email-sent"} />
              <ItemHandle label="Falha no envio" icon={<BiErrorAlt />} nodeId={id} background="rose-500" textColor="white" handleId={"email-failed"} />
            </div>
          </>
        )}
        {data?.id === "user-input" && (
          <>
            <div className="node-bar">
              <ItemHandle label="Respondido" icon={<BiCheckCircle />} nodeId={id} background="emerald-500" textColor="white" handleId={"response"} />
              {data?.props?.timeout > 0 && <ItemHandle label="Timeout" icon={<TbHourglassHigh />} nodeId={id} background="slate" textColor="slate-500" handleId={"timeout"} />}
            </div>
          </>
        )}
        {data?.id === "wait-delay" && (
          <>
            <div className="at-u-flex at-u-items-center at-u-mt1">
              <div className="transfer-card at-u-items-center">
                <small className="at-u-flex-grow" at-left="true">
                  {data?.props?.timeout} segundos
                </small>
                <TbHourglassHigh />
              </div>
            </div>
          </>
        )}
        {data?.id === "set-tabulacao" && (
          <>
            <div className="at-u-flex at-u-items-center at-u-mt1">
              <div className="transfer-card at-u-items-center">
                <small className="at-u-flex-grow" at-left="true">
                  {data?.props?.tabulacao?.label}
                </small>
                <TbFilter />
              </div>
            </div>
          </>
        )}
        {data?.id === "record-log" && (
          <>
            <Title
              data={data}
              preTitle={
                <span className="at-badge at-u-mr1 at-u-align-middle" data-status={data?.props?.status}>
                  {data?.props?.status}
                </span>
              }
            />
            <small style={{ maxWidth: 180, display: "block", lineHeight: "1.5", marginRight: 0 }} className="overflow-hidden whitespace-break-spaces">
              {data?.props?.text}
            </small>
          </>
        )}
        {data?.id === "add-tags" && (
          <div className="at-u-mt1">
            {data?.props?.tags.map((tag: string, index: number) => {
              return (
                <div className="badge tag" key={index}>
                  {tag}
                  <div className="circle" at-bg={`${colors[index % data?.props?.tags.length]}-500`}></div>
                </div>
              );
            })}
          </div>
        )}
        {data?.id === "send-attachment-image" && (
          <div className="at-u-mt1">
            <div className="whatsapp-mockup at-u-lg-rounded at-u-p1 at-u-px2 at-u-mt1">
              <WhatsAppMessage richText={true} message={data.props?.file} type={"image"} node={data?.props} />
            </div>
          </div>
        )}
        {data?.id === "send-attachment-file" && (
          <div className="at-u-mt1">
            <div className="whatsapp-mockup at-u-lg-rounded at-u-p1 at-u-px2 at-u-mt1">
              <WhatsAppMessage richText={true} message={data.props?.file} type={"file"} node={data?.props} />
            </div>
          </div>
        )}
      </div>
      {!["new-http-request", "close-chat", "user-input", "send-email"].includes(data?.id) && (
        <>
          <Handle id={`handle-${id}-bottom`} type="source" position={Position.Bottom} />
          <Handle id={`handle-${id}-left`} type="source" position={Position.Left} />
          <Handle id={`handle-${id}-right`} type="source" position={Position.Right} />
        </>
      )}
      <Handle id={`handle-${id}-top`} type="source" position={Position.Top} />
    </div>
  );
};

export default ActionNode;
