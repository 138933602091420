import React, { useEffect, useRef, useState } from "react";
import { Command } from "cmdk";
import { INode, INodeData } from "./Nodes";
import { ICategory, cards, categories } from "../services/constants";
import { motion } from "framer-motion";
import { TbAnchor, TbChevronDown, TbChevronUp, TbCornerDownLeft, TbFlag, TbSun, TbWorldUpload, TbX } from "react-icons/tb";
import { Node, useReactFlow } from "reactflow";
import { BiCamera, BiHelpCircle, BiSave } from "react-icons/bi";
import { FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";

export function CommandMenu({ nodes, hide, saveFlow }: { nodes: INode[]; hide: () => void; saveFlow: (publish: boolean) => void }) {
  const [value, setValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const listRef = useRef(null);
  const { setCenter, setNodes } = useReactFlow();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const zoomToNode = (node: INode) => {
    if (node?.position) {
      const { x, y } = node.position;
      const { width, height, id } = node;
      hide();

      const zoom = Math.min(2, Math.max(0.5, 1.5 - (width || 0) / 1000));
      setCenter(x + (width || 0) / 2, y + (height || 0) / 2, { duration: 800, zoom: zoom });
      setNodes((nodes: Node[]) => {
        return nodes.map((n: Node) => {
          if (n.id === id) {
            n = { ...n, selected: true };
          } else {
            n = { ...n, selected: false };
          }
          return n;
        });
      });
    }
  };

  const commands = [
    {
      name: "Salvar Fluxo",
      description: "Salva o fluxo atual",
      shortcut: ["⌘", "S"],
      icon: BiSave,
      accent: "gray",
      onSelect: () => {
        //@ts-ignore
        document.querySelector("#button-navbar-save")?.click();
      },
    },
    {
      name: "Publicar Fluxo",
      description: "Publica o fluxo atual",
      shortcut: ["⌘", "⇧", "S"],
      icon: TbWorldUpload,
      accent: "blue",
      onSelect: () => {
        //@ts-ignore
        document.querySelector("#button-navbar-publish")?.click();
      },
    },
    {
      name: "Exportar fluxo como imagem",
      description: "Gera um documento do fluxo",
      shortcut: ["⌘", "⇧", "P"],
      icon: BiCamera,
      accent: "amber",
      onSelect: () => {
        //@ts-ignore
        document.querySelector("#button-navbar-download")?.click();
      },
    },
    {
      name: "Testar fluxo no WhatsApp",
      description: "Abre o WhatsApp Web para testar o fluxo",
      shortcut: ["⌘", "⇧", "1"],
      icon: FaWhatsapp,
      accent: "green",
      onSelect: () => {
        //@ts-ignore
        document.querySelector("#button-navbar-test-whatsapp")?.click();
      },
    },
    {
      name: "Testar fluxo no Telegram",
      description: "Abre o Telegram para testar o fluxo",
      shortcut: ["⌘", "⇧", "2"],
      icon: FaTelegramPlane,
      accent: "sky",
      onSelect: () => {
        //@ts-ignore
        document.querySelector("#button-navbar-test-telegram")?.click();
      },
    },
    {
      name: "Visualizar erros e alertas",
      description: "Problemas no Fluxo",
      shortcut: ["⌘", "⇧", "E"],
      icon: TiWarningOutline,
      accent: "rose",
      onSelect: () => {
        //@ts-ignore
        document.querySelector(".navbar-button.error-sign")?.click();
        setTimeout(() => {
          //@ts-ignore
          document.querySelector(".at-modal button")?.focus();
        }, 200);
      },
    },
    {
      name: "Alternar Tema",
      description: "Altera entre dark / light",
      icon: TbSun,
      accent: "pink",
      onSelect: () => {
        //@ts-ignore
        document.querySelector("#button-navbar-darkmode")?.click();
      },
    },
    {
      name: "Ajuda",
      description: "Abre o painel de ajuda",
      icon: BiHelpCircle,
      shortcut: ["⌘", "⇧", "H"],
      accent: "indigo",
      onSelect: () => {
        //@ts-ignore
        document.querySelector("#button-navbar-help")?.click();
      },
    },
  ];

  const filter = (value: string, search: string) => {
    const normalize = (s: string | undefined) => {
      if (!s) return "";
      return s
        .normalize("NFD")
        .toLowerCase()
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9]/g, "");
    };

    const s = normalize(search);
    return normalize(value).includes(s) ? 1 : 0;
  };

  return (
    <div className="dialog-container">
      <motion.div className="raycast" initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0 }}>
        <Command value={value} onValueChange={(v) => setValue(v)} loop={true} filter={filter}>
          <div className="backdrop-effect"></div>
          <div className="at-u-relative">
            <Command.Input ref={inputRef} autoFocus placeholder="Buscar por blocos e ações..." />
            <div className="close" onClick={hide}>
              <TbX />
            </div>
          </div>
          <hr cmdk-raycast-loader="" />
          <Command.List ref={listRef}>
            <Command.Empty>Nenhum resultado encontrado.</Command.Empty>
            <Command.Group heading="Comandos">
              {commands.map((command) => (
                <Command.Item
                  key={command.name}
                  value={command.name}
                  onSelect={() => {
                    hide();
                    command.onSelect();
                  }}
                >
                  <div className="command-icon" at-accent={command.accent}>
                    <command.icon />
                  </div>
                  {command.name}
                  <span cmdk-raycast-meta="" className="at-u-flex items-center">
                    {command.description}
                    {command.shortcut && (
                      <>
                        {command.shortcut.map((s, i) => (
                          <kbd key={i}>{s}</kbd>
                        ))}
                      </>
                    )}
                  </span>
                </Command.Item>
              ))}
            </Command.Group>
            <Command.Group heading="Âncoras">
              {nodes
                .filter((node: INode) => node.data.id === "anchor-node")
                ?.map((node, index) => {
                  const value = (node?.data?.props?.nodeTitle || "") + (node?.data?.props?.description || "") + index;
                  return (
                    <Item key={node?.id} zoomToNode={zoomToNode} node={node} value={value}>
                      <div className="icon anchor-icon" at-accent={node?.data?.props?.accent}>
                        <TbAnchor />
                      </div>
                      <div className="at-u-line-height-4 anchor-card">
                        <b at-tx="white">{node?.data?.props?.nodeTitle}</b>
                        <small className="at-u-block">{node?.data?.props?.description}</small>
                      </div>
                    </Item>
                  );
                })}
            </Command.Group>
            <Command.Group heading="Blocos">
              {nodes
                .filter((node: INode) => node.data.id !== "anchor-node")
                ?.map((node, index) => {
                  let value = (node?.data?.props?.nodeTitle || "") + (node?.data?.props?.text?.replace(/(<([^>]+)>)/gi, "") || "") + index;
                  if (node?.data?.category === "menu") {
                    const options = node?.data?.props?.options?.map((option: any) => option?.text).join(",");
                    value += options;
                  }
                  if (node?.data?.category === "transfer") {
                    const sub = node?.data?.props?.anchor?.data?.nodeTitle || node?.data?.props?.user?.label || node?.data?.props?.department?.label || node?.data?.props?.anchor?.data?.nodeTitle;
                    value += sub;
                  }
                  const Icon = cards.find((c: INodeData) => c.id === node?.data?.id)?.icon;
                  return (
                    <Item node={node} key={node?.id} zoomToNode={zoomToNode} value={value}>
                      {node?.data?.integration ? (
                        <img className="icon" alt={node?.data?.integration?.origin} src={require(`../assets/icons/${node?.data?.integration?.origin}.png`)} />
                      ) : (
                        <div className="icon" at-accent={categories.find((cat: ICategory) => cat.id === node?.data?.category)?.color || "indigo"}>
                          {Icon && <Icon />}
                          {node?.id === "start" && <TbFlag />}
                        </div>
                      )}
                      <span className="at-u-flex-grow">{node?.data?.props?.nodeTitle}</span>
                      {node?.data?.category === "transfer" && (
                        <span cmdk-raycast-meta="" className="at-u-flex items-center at-u-flex-row">
                          {node?.data?.props?.departmentId && node?.data?.props?.departmentId !== "variable" && <>{node?.data?.props?.department?.label}</>}
                          {node?.data?.props?.userId !== "none" && node?.data?.props?.userId && node?.data?.props?.userId !== "variable" && <>{node?.data?.props?.user?.label}</>}
                          {node?.data?.props?.nodeId !== "none" && node?.data?.props?.nodeId && <>{node?.data?.props?.node?.label}</>}
                          {node?.data?.props?.anchorId !== "none" && node?.data?.props?.anchorId && <>{node?.data?.props?.anchor?.data?.nodeTitle}</>}
                        </span>
                      )}
                    </Item>
                  );
                })}
            </Command.Group>
          </Command.List>

          <div cmdk-raycast-footer="">
            <button cmdk-raycast-open-trigger="">
              <div className="footer-kbd">esc</div>
              Esconder Busca
            </button>
            <button cmdk-raycast-open-trigger="">
              <div className="footer-kbd">
                <TbChevronUp />
              </div>
              <div className="footer-kbd">
                <TbChevronDown />
              </div>
              Navegar
            </button>
            <div className="at-u-flex-grow"></div>
            <button cmdk-raycast-open-trigger="">
              <div className="footer-kbd">
                <TbCornerDownLeft />
              </div>
              Visualizar Bloco
            </button>
          </div>
        </Command>
      </motion.div>
    </div>
  );
}

const Item = ({ children, value, node, zoomToNode }: { children: React.ReactNode; node: INode; value: string; zoomToNode: (node: INode) => void }) => {
  const onSelect = () => {
    zoomToNode(node);
  };
  return (
    <Command.Item value={value} onSelect={onSelect}>
      {children}
      <span cmdk-raycast-meta=""></span>
    </Command.Item>
  );
};
