import Bold from "@tiptap/extension-bold";
import Document from "@tiptap/extension-document";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import CharacterCount from "@tiptap/extension-character-count";
import History from "@tiptap/extension-history";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { EditorContent, markInputRule, markPasteRule, useEditor } from "@tiptap/react";
import { useEffect, useState } from "react";
import { getVariables } from "../services/methods";
import { log } from "../services/utils";
import Tippy from "@tippyjs/react";
import { BiBold, BiHappy, BiItalic, BiLayer, BiLayerPlus } from "react-icons/bi";
import type { Node as ProseMirrorNode } from "prosemirror-model";

/** 🚧 Gera um erro caso esteja em development mode  🚧*/
import { EditorView } from "prosemirror-view";
const oldUpdateState = EditorView.prototype.updateState;
EditorView.prototype.updateState = function (state) {
  // @ts-ignore
  if (!this?.docView) {
    return;
  }
  oldUpdateState.call(this, state);
};
/********************************************************/

interface ITextEditor {
  value: string;
  placeholder?: string;
  onChange?: (editor: any) => void;
  onBlur?: (editor: any) => void;
  max?: number;
  setShowVariableForm?: (show: boolean) => void;
}
const TextEditor = (props: ITextEditor) => {
  const { value, placeholder, onChange = () => {}, onBlur = () => {}, max, setShowVariableForm = () => {} } = props;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showVariablePicker, setShowVariablePicker] = useState(false);
  const [variables, setVariables] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      let res = await getVariables();
      res = [
        ...res,
        {
          _id: "initial_message",
          label: "Mensagem Inicial",
          scope: "room",
        },
        {
          _id: "last_message",
          label: "Ultima Mensagem",
          scope: "room",
        },
        {
          _id: "selected_option",
          label: "Opção Selecionada",
          scope: "room",
        },
        {
          _id: "http_request_status_code",
          label: "Código Status HTTP",
          scope: "room",
        },
        {
          _id: "current_date",
          label: "Data Atual",
          scope: "room",
        },
        {
          _id: "current_time",
          label: "Hora Atual",
          scope: "room",
        },
        {
          _id: "current_timestamp",
          label: "Timestamp Atual",
          scope: "room",
        },
        {
          _id: "roomId",
          label: "ID da Sala",
          scope: "room",
        },
      ].sort((a, b) => a.label.localeCompare(b.label));
      setVariables(res);
    };

    fetchData().catch((err) => {
      log("error", "Erro ao buscar dados", err);
    });
  }, []);

  const editor = useEditor(
    {
      extensions: [
        Document,
        Paragraph,
        Text,
        Bold.extend({
          addPasteRules() {
            return [
              markPasteRule({
                find: /(?:^|\s)((?:\*)((?:[^\*]+))(?:\*))/g,
                type: this.type,
              }),
            ];
          },
          addInputRules() {
            return [
              markInputRule({
                find: /(?:^|\s)((?:\*)((?:[^\*]+))(?:\*))/g,
                type: this.type,
              }),
            ];
          },
        }),
        Italic.extend({
          addPasteRules() {
            return [
              markPasteRule({
                find: /(?:^|\s)((?:_)((?:[^_]+))(?:_))/g,
                type: this.type,
              }),
            ];
          },
          addInputRules() {
            return [
              markInputRule({
                find: /(?:^|\s)((?:_)((?:[^_]+))(?:_))/g,
                type: this.type,
              }),
            ];
          },
        }),
        Link,
        History,
        Mention.configure({
          HTMLAttributes: {
            class: "variable",
          },
          renderLabel({ node }: { node: ProseMirrorNode }) {
            return `${node.attrs.id}`;
          },
          suggestion: {
            char: "$",
          },
        }),
        CharacterCount.configure({
          limit: max,
        }),
      ],
      content: value,
      onUpdate: ({ editor }) => {
        onChange(editor);
      },
      onBlur: ({ editor }) => {
        onBlur(editor);
      },
    },
    [max]
  );
  return (
    <>
      {showEmojiPicker && (
        <div style={{ marginLeft: 60 }}>
          <Picker
            locale="pt"
            data={data}
            theme="light"
            previewPosition="none"
            onEmojiSelect={(emoji: any) => {
              editor?.chain().focus().insertContent(emoji.native).run();
              setShowEmojiPicker(false);
            }}
          />
        </div>
      )}
      {showVariablePicker && (
        <>
          <div className="variable-list">
            {variables?.map((variable: any, index: number) => {
              return (
                <Tippy
                  className="at-tooltip"
                  key={index}
                  content={
                    <div>
                      <p className="at-u-flex at-u-items-center">
                        <label at-tx="white" className="at-u-mr1">
                          {variable.label}
                        </label>
                        <small className="at-badge" style={{ marginLeft: "auto" }} data-scope={variable.scope} at-bg="primary">
                          {variable.scope}
                        </small>
                      </p>
                      <small>Clique para Adicionar</small>
                    </div>
                  }
                >
                  <div
                    className="variable"
                    data-scope={variable.scope}
                    onClick={() => {
                      editor?.chain().focus().insertContent(`<span data-type='mention' data-id='$${variable._id}' data-label='${variable.scope}'>${variable._id}</span> `).run();
                    }}
                  >
                    {variable._id}
                  </div>
                </Tippy>
              );
            })}
          </div>
        </>
      )}
      <div className="editor-controls">
        <Tippy content={<span at-tx="white">Negrito</span>} duration={0} className={"at-tooltip"} hideOnClick={false}>
          <div
            className="control"
            data-active={editor?.isActive("bold") ? "true" : ""}
            onClick={() => {
              editor?.chain().focus().toggleBold().run();
            }}
          >
            <BiBold />
          </div>
        </Tippy>
        <Tippy content={<span at-tx="white">Itálico</span>} duration={0} className={"at-tooltip"} hideOnClick={false}>
          <div
            className="control"
            data-active={editor?.isActive("italic") ? "true" : ""}
            onClick={() => {
              editor?.chain().focus().toggleItalic().run();
            }}
          >
            <BiItalic />
          </div>
        </Tippy>
        <Tippy content={<span at-tx="white">Emojis</span>} duration={0} className={"at-tooltip"} hideOnClick={false}>
          <div
            className="control"
            onClick={() => {
              setShowEmojiPicker(!showEmojiPicker);
              setShowVariablePicker(false);
            }}
          >
            <BiHappy />
          </div>
        </Tippy>
        <Tippy content={<span at-tx="white">Utilizar Variável</span>} duration={0} className={"at-tooltip"} hideOnClick={false}>
          <div
            className="control"
            onClick={() => {
              setShowVariablePicker(!showVariablePicker);
              setShowEmojiPicker(false);
            }}
          >
            <BiLayer />
          </div>
        </Tippy>
        <Tippy content={<span at-tx="white">Cadastrar Variável</span>} duration={0} className={"at-tooltip"} hideOnClick={false}>
          <div
            className="control"
            onClick={() => {
              setShowVariableForm(true);
            }}
          >
            <BiLayerPlus />
          </div>
        </Tippy>
        {max && (
          <div className="at-u-flex-grow" at-right="true">
            {editor?.storage?.characterCount?.characters()}/{max}
          </div>
        )}
      </div>
      <EditorContent editor={editor} className="editor" placeholder={placeholder} />
    </>
  );
};

export default TextEditor;
