import Tippy from "@tippyjs/react";
import { KeyboardEvent, useEffect, useState } from "react";
import { BiErrorAlt } from "react-icons/bi";
import { FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";
import { Button, IconButton } from "../../components";
import { getChannels, showHelpModal } from "../../services/methods";
import { log } from "../../services/utils";
import { toSvg } from "html-to-image";
import { useReactFlow } from "reactflow";
import type { IChannel, IError, IFlowConfig, IFlowEdit } from "../../App";
import { TbCamera, TbHelpCircle, TbMoon, TbSearch, TbSun } from "react-icons/tb";
interface INavbar {
  saveFlow: (...args: any) => void;
  setDark: (dark: boolean) => void;
  openLogs: () => void;
  openSearch: () => void;
  page: string;
  errors: IError[];
  dark: boolean;
  flow: IFlowConfig;
}
const Navbar = (props: INavbar) => {
  const { saveFlow, openLogs, openSearch, setDark, errors, dark, flow } = props;
  const { fitView } = useReactFlow();

  const [activeChannels, setActiveChannels] = useState<IChannel[]>([]);
  const [publishing, setPublishing] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [saving, setSaving] = useState(false);
  const [printing, setPrinting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getChannels();
      const channels = data.filter((channel: IChannel) => channel?.fluxo?._id === globalThis.flowId);
      const uniqueChannels = channels.filter((channel: IChannel, index: number) => channels.findIndex((c: IChannel) => c.tipo === channel.tipo) === index);
      setActiveChannels(uniqueChannels);
    };

    fetchData().catch((err) => log("error", "Erro ao buscar fluxos", err));
  }, []);

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      setShowInput(false);
      saveFlow();
    }
  };

  const downloadImage = () => {
    fitView();
    setPrinting(true);
    if (!document.querySelector(".react-flow")) return;
    document.querySelector("body")?.setAttribute("data-printing", "true");
    setTimeout(() => {
      toSvg(document.querySelector(".react-flow") as HTMLElement, {
        filter: (node) => {
          if (node?.classList?.contains("react-flow__minimap") || node?.classList?.contains("react-flow__controls")) {
            return false;
          }
          return true;
        },
      }).then(async (dataUrl) => {
        const svgElement = await decodeURIComponent(dataUrl.replace("data:image/svg+xml;charset=utf-8,", "").trim());
        const newWindow = window.open();
        newWindow?.document.write(
          `<html>
            <head>
            <title>Flow.pdf</title>
            <style>
            @page {
              size: A4 landscape !important;
              margin:0 !important;
            }
            @media print {
            * {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
            color-adjust: exact !important;                 /*Firefox*/
            }
            }
            </style>
            </head>
            <body style="margin:60px 32px 32px 32px ">
                    ${svgElement}
            <script>
            window.print();
            window.close();
            </script>
            </body>
            </html>`
        );
        setPrinting(false);
        document.querySelector("body")?.removeAttribute("data-printing");
      });
    }, 1500);
  };

  const publishFlow = async () => {
    setPublishing(true);
    await saveFlow(true);
    setPublishing(false);
  };

  const storeFlow = async () => {
    setSaving(true);
    await saveFlow();
    setSaving(false);
  };

  return (
    <header className="app-header at-u-flex at-u-items-center animate__fadeInDown animate__animated animate__delay-1s">
      <div className="app-subheader">
        {errors.length ? (
          <div className="at-u-flex at-u-gap at-u-mr2">
            {errors.filter((err: IError) => err.level === 2).length ? (
              <div className="at-u-mr1">
                <Tippy
                  placement="bottom-start"
                  content={
                    <>
                      <span at-tx="white">
                        {errors.filter((err: IError) => err.level === 2).length} Erro{errors.filter((err: IError) => err.level === 2).length === 1 ? "" : "s"} encontrado{errors.filter((err: IError) => err.level === 2).length === 1 ? "" : "s"}
                      </span>
                      <br />
                      <small>Clique para detalhar</small>
                    </>
                  }
                  className="at-tooltip remove-arrow"
                  duration={0}
                >
                  <div className="error-sign navbar-button" at-bg="rose-500" onClick={openLogs}>
                    <BiErrorAlt color="white" />
                    <div className="badge" at-tx="white">
                      {errors.filter((err: IError) => err.level === 2).length}
                    </div>
                  </div>
                </Tippy>
              </div>
            ) : null}
            {errors.filter((err: IError) => err.level === 1).length ? (
              <div>
                <Tippy
                  placement="bottom-start"
                  content={
                    <>
                      <span at-tx="white">
                        {errors.filter((err: IError) => err.level === 1).length} Alerta{errors.filter((err: IError) => err.level === 1).length === 1 ? "" : "s"} encontrado{errors.filter((err: IError) => err.level === 1).length === 1 ? "" : "s"}
                      </span>
                      <br />
                      <small>Clique para detalhar</small>
                    </>
                  }
                  className="at-tooltip remove-arrow"
                  duration={0}
                >
                  <div className="error-sign navbar-button" onClick={openLogs}>
                    <TiWarningOutline />
                    <div className="badge" at-tx="white">
                      {errors.filter((err: IError) => err.level === 1).length}
                    </div>
                  </div>
                </Tippy>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="at-u-flex-grow">
          {showInput ? (
            <input onFocus={(e) => e.target.select()} defaultValue={flow.nome} onChange={(e) => (flow.nome = e.target.value)} onKeyDown={handleEnter} autoFocus={true} onBlur={() => setShowInput(false)} />
          ) : (
            <div className="at-u-flex at-u-gap at-u-items-center">
              <h3 onClick={() => setShowInput(true)}> {flow.nome} </h3>
              <Tippy
                className={activeChannels.length ? "at-tooltip remove-arrow" : ""}
                placement="bottom"
                duration={0}
                content={
                  <div className="at-u-flex at-u-gap at-u-flex-column">
                    {activeChannels.map((channel: IChannel, index: number) => {
                      return (
                        <div key={index}>
                          <small className="at-u-block" at-tx="white">
                            {channel.descricao}
                          </small>
                          <small>{channel.identificador}</small>
                        </div>
                      );
                    })}
                  </div>
                }
              >
                <div className="badge">
                  <div className="circle" at-bg={activeChannels.length ? "emerald-400" : null} />
                  {activeChannels.length ? "Ativo" : "Inativo"}
                </div>
              </Tippy>
            </div>
          )}
          {flow?._updatedAt ? (
            <small>
              Ultima edição feita por {globalThis.userId === flow?.ultimoEditor?._id ? "você" : flow?.ultimoEditor?.name} dia {new Date(flow?._updatedAt)?.toLocaleDateString("pt-BR")} as {new Date(flow?._updatedAt)?.toLocaleTimeString("pt-BR")}
            </small>
          ) : null}
        </div>
        <div className="at-u-flex at-u-items-center" style={{ gap: 10 }}>
          <div className="editors-container">
            {flow?.uniqueEditors?.map((edit: IFlowEdit, index: number) => {
              return (
                <Tippy
                  key={index}
                  className={"at-tooltip remove-arrow"}
                  placement="bottom"
                  duration={0}
                  content={
                    <>
                      <span at-tx="white">{edit?.editor?.name}</span> <br />
                      <small>
                        Ultima edição dia {new Date(edit?._updatedAt)?.toLocaleDateString("pt-BR")} as {new Date(edit?._updatedAt)?.toLocaleTimeString("pt-BR")}
                      </small>
                    </>
                  }
                >
                  <img src={`https://${globalThis.host}/avatar/${edit?.editor?.username}`} alt={edit?.editor?.username} />
                </Tippy>
              );
            })}
          </div>
          <Button onClick={storeFlow} id="button-navbar-save" label="Salvar" loading={saving} outlined={true} />
          <Button onClick={publishFlow} id="button-navbar-publish" label="Salvar & Publicar" loading={publishing} background="primary" />
          {activeChannels.map((channel: IChannel) => {
            if (channel.tipo === "TELEGRAM") {
              return <IconButton id="button-navbar-test-telegram" key={channel._id} icon={<FaTelegramPlane />} onClick={() => window.open(`https://t.me/${channel.identificador}`, "_blank")} color="sky" />;
            }
            if (channel.tipo === "WHATS") {
              return <IconButton id="button-navbar-test-whatsapp" key={channel._id} icon={<FaWhatsapp />} onClick={() => window.open(`https://wa.me/${channel.identificador}`, "_blank")} color="green" />;
            }
            return null;
          })}
          <IconButton id="button-navbar-search" icon={<TbSearch />} onClick={openSearch} />
          <IconButton id="button-navbar-darkmode" icon={dark ? <TbSun /> : <TbMoon />} onClick={() => setDark(!dark)} />
          <IconButton id="button-navbar-download" icon={<TbCamera />} onClick={downloadImage} disabled={printing} />
          <IconButton id="button-navbar-help" icon={<TbHelpCircle />} onClick={showHelpModal} />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
