"use client";
import { EditorContent, useEditor, BubbleMenu } from "@tiptap/react";
/** Tiptap Extensions */
import Link from "@tiptap/extension-link";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import suggestion from "./Suggestions/AdvancedTextEditorSuggestions";
import { AiOutlineBold, AiOutlineItalic, AiOutlineStrikethrough, AiOutlineAlignLeft, AiOutlineAlignCenter, AiOutlineAlignRight, AiOutlineUnderline } from "react-icons/ai";

interface IAdvancedTextEditor {
  value: string | undefined;
  onChange?: (editor: any) => void;
}

const AdvancedTextEditor = (props: IAdvancedTextEditor) => {
  const { value, onChange = () => {} } = props;
  const editor = useEditor({
    extensions: [
      StarterKit,
      TextStyle,
      Placeholder.configure({
        placeholder: 'Digite "/" para ver os comandos',
      }),
      Link,
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Underline,
    ],
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor);
    },
    editorProps: {
      attributes: {
        class: "prose w-full h-full focus:outline-none antialiased p-2",
      },
    },
  });

  return (
    <>
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }} className="advanced-text-editor-bubble">
          <button onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive("bold") ? "!bg-gray-800" : ""}>
            <AiOutlineBold />
          </button>
          <button onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive("italic") ? "!bg-gray-800" : ""}>
            <AiOutlineItalic />
          </button>
          <button onClick={() => editor.chain().focus().toggleStrike().run()} className={editor.isActive("strike") ? "!bg-gray-800" : ""}>
            <AiOutlineStrikethrough />
          </button>
          <button onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive("strike") ? "!bg-gray-800" : ""}>
            <AiOutlineUnderline />
          </button>
          <button onClick={() => editor.chain().focus().setTextAlign("left").run()} className={editor.isActive({ textAlign: "left" }) ? "!bg-gray-800" : ""}>
            <AiOutlineAlignLeft />
          </button>
          <button onClick={() => editor.chain().focus().setTextAlign("center").run()} className={editor.isActive({ textAlign: "center" }) ? "!bg-gray-800" : ""}>
            <AiOutlineAlignCenter />
          </button>
          <button onClick={() => editor.chain().focus().setTextAlign("right").run()} className={editor.isActive({ textAlign: "right" }) ? "!bg-gray-800" : ""}>
            <AiOutlineAlignRight />
          </button>
        </BubbleMenu>
      )}
      <EditorContent editor={editor} className="advanced-text-editor" />
    </>
  );
};

export default AdvancedTextEditor;
