import { BiAnchor, BiGitMerge, BiLayout, BiLike, BiLinkAlt, BiTransferAlt } from "react-icons/bi";
import { RiQuestionLine } from "react-icons/ri";
import { TbBolt, TbBook, TbCalendarStats, TbCircleX, TbClick, TbDotsCircleHorizontal, TbFilter, TbGitFork, TbHourglass, TbList, TbListNumbers, TbLogin, TbMail, TbMapPin, TbMenu2, TbMessage, TbMessageDots, TbTag, TbUser, TbUsers, TbVariable } from "react-icons/tb";
import type { INodeData } from "../../components/Nodes";
import type { IconType } from "react-icons/lib";
import type { ToastOptions } from "react-hot-toast";

import type { ReactNode } from "react";
import { FaPaperclip, FaRegImage } from "react-icons/fa";

const cards: Array<INodeData> = [
  {
    id: "send-new-message",
    label: "Enviar mensagem",
    description: "Envia uma mensagem na conversa atual",
    category: "action",
    icon: TbMessageDots,
    props: {
      nodeTitle: "Enviar mensagem",
      text: "",
    },
  },
  {
    id: "user-input",
    label: "Input de dados",
    description: "Aguarda uma resposta do usuário",
    category: "action",
    icon: TbLogin,
    props: {
      nodeTitle: "Input de dados",
      text: "",
      timeout: 900,
      variable: "",
      stringAction: "none",
      stringActionValue: "",
    },
  },
  {
    id: "new-http-request",
    label: "Requisição HTTP",
    description: "Executa uma request POST ou GET",
    category: "action",
    icon: BiLinkAlt,
    props: {
      nodeTitle: "Executar Request HTTP",
      type: "http",
      url: "",
      headers: {},
      contentType: "form-data",
      method: "GET",
      query: {},
      data: {},
      rawData: "",
      retorno: {},
      timeout: 5,
    },
  },
  {
    id: "close-chat",
    label: "Encerrar atendimento",
    description: "Encerra a conversa atual",
    category: "action",
    icon: TbCircleX,
    props: {
      nodeTitle: "Encerrar conversa",
      text: "",
    },
  },
  {
    id: "set-variable",
    label: "Definir variável",
    description: "Atribui um valor à uma variável",
    category: "action",
    icon: TbVariable,
    props: {
      nodeTitle: "Definir variável",
      key: "",
      value: "",
      variable: {},
      stringAction: "none",
      stringActionValue: "",
    },
  },
  {
    id: "add-tags",
    label: "Adicionar Tags",
    description: "Adiciona uma tag ao atendimento",
    category: "action",
    icon: TbTag,
    props: {
      nodeTitle: "Adicionar Tags",
      tags: [],
    },
  },
  {
    id: "set-tabulacao",
    label: "Definir Tabulação",
    description: "Seleciona uma tabulação para o atendimento",
    category: "action",
    icon: TbFilter,
    props: {
      nodeTitle: "Definir tabulação",
      tabulacao: {},
      tabulacaoId: "",
    },
  },
  {
    id: "wait-delay",
    label: "Aguardar (Delay)",
    description: "Segura a execução do fluxo ",
    category: "action",
    icon: TbHourglass,
    props: {
      nodeTitle: "Aguardar",
      timeout: 30,
    },
  },
  {
    id: "send-location",
    label: "Enviar Localização",
    description: "Envia um local no mapa",
    category: "action",
    icon: TbMapPin,
    props: {
      nodeTitle: "Enviar Localização",
      latitude: -28.245305817791557,
      longitude: -52.41649245100152,
      name: "Convert Company",
      address: "Av. Dr. Álvaro Severo de Miranda, 1106 - Sala 801 - Cidade Nova, Passo Fundo - RS, 99022-032",
    },
  },
  {
    id: "record-log",
    label: "Gravar no Log",
    description: "Grava um registro no Log",
    category: "action",
    icon: TbBook,
    props: {
      nodeTitle: "Registro no Log",
      status: "INFO",
      text: "",
    },
  },
  {
    id: "send-attachment-image",
    label: "Enviar imagem",
    description: "Envia um arquivo de imagem para o usuário",
    category: "action",
    icon: FaRegImage,
    props: {
      nodeTitle: "Enviar imagem",
      file: "https://cdn.letteldata.com.br/images/preview.png",
    },
  },
  {
    id: "send-attachment-file",
    label: "Enviar documento",
    description: "Envia um arquivo para o usuário",
    category: "action",
    icon: FaPaperclip,
    props: {
      nodeTitle: "Enviar documento",
      fileName: "",
      file: "https://cdn.letteldata.com.br/documents/preview.pdf",
    },
  },
  {
    id: "send-email",
    label: "Enviar Email",
    description: "Envia um email para o usuário",
    category: "action",
    icon: TbMail,
    props: {
      nodeTitle: "Enviar email",
      to: [],
      subject: "",
      body: "",
      cc: [],
      cco: [],
    },
  },
  {
    id: "transfer-to-user",
    label: "Agente",
    description: "Envia a conversa para um usuário",
    category: "transfer",
    icon: TbUser,
    props: {
      nodeTitle: "Transferir para Agente",
      userId: "",
      text: "",
    },
  },
  {
    id: "transfer-to-department",
    label: "Departamento",
    description: "Envia a conversa para um departamento",
    category: "transfer",
    icon: TbUsers,
    props: {
      nodeTitle: "Transferir para departamento",
      departmentId: "",
      userId: "none",
      department: {},
      user: {},
      text: "",
    },
  },
  // {
  //   id: "transfer-to-flow",
  //   label: "Fluxo",
  //   description: "Envia a conversa para um Fluxo",
  //   category: "transfer",
  //   icon: RiFlowChart,
  //   props : {
  //     nodeTitle : "Transferir para fluxo",
  //     flowId : "",
  //     nodeId : "",
  //     flow : {},
  //     node : {},
  //     text : ""
  //   }
  // },
  {
    id: "transfer-to-anchor",
    label: "Âncora",
    description: "Envia a conversa para uma âncora",
    category: "transfer",
    icon: BiAnchor,
    props: {
      nodeTitle: "Transferir para âncora",
      anchorId: "",
      anchor: {},
      text: "",
    },
  },
  {
    id: "transfer-to-node",
    label: "Bloco",
    description: "Envia a conversa para um bloco",
    category: "transfer",
    icon: BiLayout,
    props: {
      nodeTitle: "Transferir para bloco",
      nodeId: "",
      node: {},
      text: "",
    },
  },
  {
    id: "button-menu",
    label: "Botões",
    description: "Menu customizado com opções de menu",
    category: "menu",
    icon: TbClick,
    props: {
      nodeTitle: "Menu",
      text: "Por favor, selecione uma opção",
      menuTitle: "Selecionar Opção",
      mode: "quickreply",
      timeout: 900,
      maxRetries: 3,
      options: [],
      invalidOptionText: "Opção inválida, por favor tente novamente",
    },
  },
  {
    id: "list-menu",
    label: "Lista",
    description: "Menu customizado com opções de lista",
    category: "menu",
    icon: TbList,
    props: {
      nodeTitle: "Menu",
      text: "Por favor, selecione uma opção",
      menuTitle: "Selecionar Opção",
      mode: "list",
      timeout: 900,
      maxRetries: 3,
      options: [],
      invalidOptionText: "Opção inválida, por favor tente novamente",
    },
  },
  {
    id: "text-menu",
    label: "Texto",
    description: "Menu customizado com opções de texto",
    category: "menu",
    icon: TbListNumbers,
    props: {
      nodeTitle: "Menu",
      text: "Por favor, selecione uma opção",
      menuTitle: "Selecionar Opção",
      mode: "text",
      timeout: 900,
      maxRetries: 3,
      options: [],
      invalidOptionText: "Opção inválida, por favor tente novamente",
    },
  },
  {
    id: "yes-or-no-menu",
    label: "Sim ou Não",
    description: "Menu simples com resposta de sim e não",
    category: "menu",
    icon: BiLike,
    props: {
      nodeTitle: "Sim / Não",
      text: "Por favor, selecione uma opção",
      menuTitle: "Selecionar Opção",
      mode: "quickreply",
      timeout: 900,
      maxRetries: 3,
      invalidOptionText: "Opção inválida, por favor tente novamente",
      options: [
        {
          id: 1,
          text: "Sim",
          keyword: "",
          keywords: ["sim", "Sim", "ok", "s", "S", "positivo", "aham"],
          mode: "create",
        },
        {
          id: 2,
          text: "Não",
          keyword: "",
          keywords: ["não", "nao", "Não", "Nao", "n", "naum", "negativo"],
          mode: "create",
        },
      ],
    },
  },
  {
    id: "inactive-test-menu",
    label: "Você está ai?",
    description: "Pergunta ao usuário se ele ainda está ai",
    category: "menu",
    icon: RiQuestionLine,
    props: {
      nodeTitle: "👋 Você ainda está ai?",
      text: "👋 Você ainda está ai?",
      menuTitle: "Selecionar Opção",
      mode: "quickreply",
      timeout: 300,
      maxRetries: 0,
      invalidOptionText: "Opção inválida, por favor tente novamente",
      options: [
        {
          id: 1,
          text: "Sim",
          keyword: "",
          keywords: ["sim", "Sim", "ok", "s", "S", "positivo", "aham"],
          mode: "create",
        },
      ],
    },
  },
  {
    id: "custom-menu",
    label: "Customizado",
    description: "Menu customizado",
    category: "menu",
    icon: TbMenu2,
    props: {
      nodeTitle: "Menu",
      text: "Por favor, selecione uma opção",
      menuTitle: "Selecionar Opção",
      mode: "quickreply",
      invalidOptionText: "Opção inválida, por favor tente novamente",
      timeout: 900,
      maxRetries: 3,
      options: [],
    },
  },
  {
    id: "multiple-conditions",
    label: "Bloco Condicional",
    description: "Teste uma variável para gerar ramificações no fluxo",
    category: "condition",
    icon: TbVariable,
    props: {
      nodeTitle: "Validar Condições",
      eval: "",
      options: [],
    },
  },
  {
    id: "condition-initial-message",
    label: "Respondeu ao Encerramento?",
    description: "Valida se a mensagem inicial do usuário foi uma resposta ao fim do ultimo atendimento",
    category: "condition",
    icon: TbMessageDots,
    props: {
      nodeTitle: "É uma resposta ao encerramento?",
      eval: "$initial_message",
      options: [
        {
          id: 1,
          text: "",
          keyword: "",
          keywords: ["beleza", "beleza!", "blz", "blz!", "certo", "certo!", "entendi", "entendi!", "entendido", "entendido!", "igualmente", "igualmente!", "maravilha", "maravilha!", "obrigada", "obrigada!", "obrigada.", "obrigado", "obrigado!", "obrigado.", "ok", "ok!", "ok.", "okay", "okei", "otimo", "otimo!", "otimo.", "ótimo", "ótimo!", "ótimo.", "perfeito", "perfeito!", "show", "show!", "show.", "tá certo", "tá certo!", "ta otimo", "tá ótimo", "tchau", "tchau!", "tchau.", "adeus", "adeus!", "adeus.", "tranquilo", "top", "top.", "top!", "Até", "Até!", "Até mais!", "Ate mais!", "Até mais", "Ate mais", "Ate", "Ate.", "Até.", "Sim ajudou", "Sim", "sim."],
          mode: "create",
          condition: "equals",
          regex: "",
        },
      ],
    },
  },
  {
    id: "date-hour-block",
    label: "Regras de Horário",
    description: "Validar a data e horário antes de prosseguir",
    category: "condition",
    icon: TbCalendarStats,
    props: {
      nodeTitle: "Validar Regra de Horário",
      timegroupId: "",
      timegroup: {},
    },
  },
  {
    id: "anchor-node",
    label: "Âncora",
    description: "Ponto para transferência no atendimento",
    category: "other",
    icon: BiAnchor,
    props: {
      nodeTitle: "Âncora",
      description: "",
      accent: "indigo",
    },
  },
  {
    id: "comment-node",
    label: "Comentário",
    description: "Bloco para algumas anotações",
    category: "other",
    icon: TbMessage,
    props: {
      comment: "",
      accent: "slate",
      solid: false,
      size: 14,
    },
    options: {
      dragHandle: ".header",
    },
  },
];

export interface ICategory {
  id: string;
  label?: string;
  description?: string;
  color?: string;
  icon?: IconType | ReactNode | any;
}
const categories: Array<ICategory> = [
  {
    id: "action",
    label: "Ações",
    description: "Métodos executáveis durante o fluxo",
    color: "amber",
    icon: TbBolt,
  },
  {
    id: "condition",
    label: "Condições",
    description: "Validação condicional de variáveis",
    color: "emerald",
    icon: BiGitMerge,
  },
  {
    id: "transfer",
    label: "Transferência",
    description: "Envia o atendimento para algum destino",
    color: "blue",
    icon: BiTransferAlt,
  },
  {
    id: "menu",
    label: "Menus",
    description: "Listas de opcões para ramificar atendimento",
    color: "rose",
    icon: TbGitFork,
  },
  {
    id: "other",
    label: "Outros",
    description: "Nodes diversos",
    color: "purple",
    icon: TbDotsCircleHorizontal,
  },
];

export interface IIntegration {
  name: string;
  id: string;
  cards: Array<INodeData>;
}
const integrations: Array<IIntegration> = [
  {
    name: "Movidesk",
    id: "movidesk",
    cards: [
      {
        id: "new-http-request",
        label: "Consultar Ticket",
        description: "Busca um ticket no movidesk",
        category: "action",
        icon: BiLinkAlt,
        integration: {
          id: "movidesk-get-ticket",
          origin: "movidesk",
          configure: true,
        },
        props: {
          nodeTitle: "Consultar Ticket (Movidesk)",
          type: "http",
          url: "https://api.movidesk.com/public/v1/tickets",
          headers: {},
          contentType: "form-data",
          method: "GET",
          query: {},
          data: {},
          rawData: "",
          retorno: {},
          timeout: 5,
        },
      },
    ],
  },
];

const colors: string[] = ["red", "purple", "rose", "violet", "indigo", "fuchsia", "yellow", "green", "amber", "lime", "pink", "teal", "emerald", "sky", "orange", "blue", "cyan"];

const timeSuggestions = [
  {
    label: "30 segundos",
    value: "30",
  },
  {
    label: "1 minuto",
    value: "60",
  },
  {
    label: "2 minutos",
    value: "120",
  },
  {
    label: "3 minutos",
    value: "180",
  },
  {
    label: "4 minutos",
    value: "240",
  },
  {
    label: "5 minutos",
    value: "300",
  },
  {
    label: "10 minutos",
    value: "600",
  },
  {
    label: "15 minutos",
    value: "900",
  },
  {
    label: "20 minutos",
    value: "1200",
  },
  {
    label: "30 minutos",
    value: "1800",
  },
  {
    label: "40 minutos",
    value: "2400",
  },
  {
    label: "50 minutos",
    value: "3000",
  },
  {
    label: "1 hora",
    value: "3600",
  },
];

const regexSuggestions = [
  {
    label: "Remover espaços em branco",
    value: "\\s",
  },
  {
    label: "Remover espaços em branco duplicados",
    value: "\\s+",
  },
  {
    label: "Remover digitos numéricos",
    value: "\\d",
  },
  {
    label: "Deixar apenas digitos numéricos",
    value: "\\D",
  },
  {
    label: "Remover caracteres especiais",
    value: "\\W",
  },
  {
    label: "Deixar apenas caracteres especiais",
    value: "\\w",
  },
];

const regexTestSuggestions = [
  {
    label: "Validar número de telefone (Brasil)",
    value: "^(?:(?:+|00)?(55)s?)?(?:(?([1-9][0-9]))?s?)?(?:((?:9d|[2-9])d{3})-?(d{4}))$",
  },
  {
    label: "É somente números",
    value: "^[0-9]*$",
  },
  {
    label: "Validar e-mail",
    value: "^[a-z0-9.]+@[a-z0-9]+.[a-z]+.([a-z]+)?$",
  },
  {
    label: "Validar CPF",
    value: "^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}$",
  },
  {
    label: "Validar CNPJ",
    value: "^[0-9]{2}.?[0-9]{3}.?[0-9]{3}/?[0-9]{4}-?[0-9]{2}$",
  },
  {
    label: "Validar CEP",
    value: "^[0-9]{5}-?[0-9]{3}$",
  },
  {
    label: "Validar data",
    value: "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[012])/([0-9]{4})$",
  },
  {
    label: "Validar hora",
    value: "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$",
  },
  {
    label: "É somente letras",
    value: "^[a-zA-Z]+$",
  },
  {
    label: "Tem caracteres especiais",
    value: "[^a-zA-Z0-9]",
  },
];

const regexMatchSuggestions = [
  {
    label: "Extrair CPF",
    value: "([0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2})",
  },
  {
    label: "Extrair CNPJ",
    value: "([0-9]{2}.?[0-9]{3}.?[0-9]{3}/?[0-9]{4}-?[0-9]{2})",
  },
  {
    label: "Extrair CEP",
    value: "([0-9]{5}-?[0-9]{3})",
  },
  {
    label: "Extrair data",
    value: "([0-9]{2}/[0-9]{2}/[0-9]{4})",
  },
  {
    label: "Extrair hora",
    value: "([0-9]{2}:[0-9]{2})",
  },
  {
    label: "Extrair e-mail",
    value: "([a-z0-9.]+@[a-z0-9]+.[a-z]+.([a-z]+)?)",
  },
];

const toastOptions: ToastOptions = {
  position: "bottom-center",
  className: "toast-app",
  duration: 2000,
  style: {
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    background: "#001122",
    color: "#FFFFFF",
  },
};

const toastContainerStyle: React.CSSProperties = {
  top: 40,
  bottom: 40,
  right: 40,
  left: 40,
  zIndex: 999999999999999999,
};

export { cards, categories, integrations, colors, timeSuggestions, regexMatchSuggestions, regexSuggestions, regexTestSuggestions, toastOptions, toastContainerStyle };
