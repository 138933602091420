import Tippy from "@tippyjs/react";
import type { FC } from "react";
import { BiAnchor, BiErrorAlt } from "react-icons/bi";
import { TiWarningOutline } from "react-icons/ti";
import { NodeProps, Position } from "reactflow";
import { useReactFlow, Handle } from "reactflow";
import { addHighlight, dropBlock, removeHighlights } from ".";

const AnchorNode: FC<NodeProps> = ({ data, id }) => {
  const flow = useReactFlow();
  return (
    <>
      <Tippy content={<span at-tx="white">{data?.props?.description}</span>} duration={0} className={data?.props?.description ? "at-tooltip" : ""} hideOnClick={false}>
        <div className="custom-node" at-accent={data?.props?.accent} onDragEnter={(e) => addHighlight(e, id, data?.id)} onDragLeave={(e) => removeHighlights(e, id, data?.id)} onDrop={(e) => dropBlock(e, id, data?.id, flow)}>
          <div className="node-title">
            <div className="status">
              <div className="at-badge node-status data-error-count" at-bg="danger" tabIndex={1}>
                <BiErrorAlt />
              </div>
              <div className="at-badge node-status data-warning-count" at-bg="amber-300" at-tx="slate-800">
                <TiWarningOutline />
              </div>
            </div>
          </div>
          <div className="icon">
            <BiAnchor color="#FFF" />
          </div>
          <div>
            <label className="at-u-block">{data?.props?.nodeTitle}</label>
            <small at-tx="transparent-white">{data?.props?.description}</small>
          </div>
          <Handle id={`handle-${id}-top`} type="source" position={Position.Top} />
          <Handle id={`handle-${id}-bottom`} type="source" position={Position.Bottom} />
        </div>
      </Tippy>
    </>
  );
};

export default AnchorNode;
