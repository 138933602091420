import CharacterCount from "@tiptap/extension-character-count";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { EditorContent, useEditor } from "@tiptap/react";

interface IPureTextEditor {
  value: string;
  placeholder?: string;
  onChange?: (editor: any) => void;
  max?: number;
}
const PureTextEditor = (props: IPureTextEditor) => {
  const { value, placeholder, onChange = () => {}, max } = props;
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      CharacterCount.configure({
        limit: max,
      }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      onChange(editor);
    },
  });
  return (
    <>
      <div className="editor-controls">
        {max && (
          <div className="at-u-flex-grow" at-right="true">
            {editor?.storage?.characterCount?.characters()}/{max}
          </div>
        )}
      </div>
      <EditorContent editor={editor} className="editor" placeholder={placeholder} />
    </>
  );
};

export default PureTextEditor;
