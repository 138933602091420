import type { FC } from "react";
import type { NodeProps } from "reactflow";
import { Title } from "./";
import { WhatsAppMessage } from "../";
import { Handle, Position, useReactFlow } from "reactflow";
import { TbArrowForwardUp, TbUser, TbUsers } from "react-icons/tb";
import { BiAnchor, BiLayout } from "react-icons/bi";

const TransferNode: FC<NodeProps> = ({ data, id }) => {
  const { getNode, setCenter } = useReactFlow();
  const addHighlight = (nodeId: string) => {
    document.querySelectorAll(`[data-id='${nodeId}']`).forEach((el) => {
      el.setAttribute("data-highlighted", "true");
    });
  };
  const removeHighlight = (nodeId: string) => {
    document.querySelectorAll(`[data-id='${nodeId}']`).forEach((el) => {
      el.removeAttribute("data-highlighted");
    });
  };
  const zoomNode = (nodeId: string) => {
    const node = getNode(nodeId);
    if (node?.position) {
      const { x, y } = node.position;
      const { width, height } = node;
      setCenter(x + (width || 0) / 2, y + (height || 0) / 2, { duration: 800 });
    }
  };
  return (
    <div className="custom-node" at-accent="blue">
      <div className="node-body" style={{ minWidth: 200 }}>
        <Title data={data} />
        <div className="at-u-mt1">
          {data?.props?.text.replace(/(<([^>]+)>)/gi, "") !== "" && (
            <div className="whatsapp-mockup at-u-lg-rounded at-u-p1 at-u-px2 at-u-mt1">
              <WhatsAppMessage richText={true} message={data?.props?.text} type={"received"} node={data?.props} />
            </div>
          )}
          {data?.props?.transferType ? (
            <div className="transfer-card">
              <div style={{ backgroundColor: data?.props?.department?.data?.color, borderRadius: 4, padding: 6, marginRight: 15 }}></div>
              <label>{data?.props?.departmentId}</label> <TbArrowForwardUp /> <TbUsers />
            </div>
          ) : (
            <>
              {data?.props?.departmentId && data?.props?.departmentId !== "variable" && (
                <div className="transfer-card">
                  <div style={{ backgroundColor: data?.props?.department?.data?.color, borderRadius: 4, padding: 6, marginRight: 15 }}></div>
                  <label>{data?.props?.department?.label}</label> <TbArrowForwardUp /> <TbUsers />
                </div>
              )}
              {data?.props?.userId !== "none" && data?.props?.userId && data?.props?.userId !== "variable" && (
                <div className="transfer-card">
                  <img alt={data?.props?.user?.data?.username} src={`https://${globalThis.host}/avatar/${data?.props?.user?.data?.username}`} className="select-avatar" /> <label>{data?.props?.user?.label}</label> <TbArrowForwardUp /> <TbUser />
                </div>
              )}
              {data?.props?.departmentId === "variable" && (
                <div className="transfer-card">
                  <label>{data?.props?.variableDepartmentId}</label> <TbArrowForwardUp /> <TbUsers />
                </div>
              )}
              {data?.props?.userId === "variable" && (
                <div className="transfer-card">
                  <label>{data?.props?.variableUserId}</label> <TbArrowForwardUp /> <TbUser />
                </div>
              )}
              {data?.props?.anchorId && (
                <div className="transfer-card clickable" onClick={() => zoomNode(data?.props?.anchorId)} onMouseEnter={() => addHighlight(data?.props?.anchorId)} onMouseLeave={() => removeHighlight(data?.props?.anchorId)} at-accent={data?.props?.anchor?.data?.accent}>
                  <div className="anchor" at-tx="white">
                    <BiAnchor color="#FFF" width={12} height={12} /> <label>{data?.props?.anchor?.data?.nodeTitle}</label>
                  </div>
                  <TbArrowForwardUp /> <BiAnchor />
                </div>
              )}
              {data?.props?.nodeId && (
                <div className="transfer-card clickable" onClick={() => zoomNode(data?.props?.nodeId)} onMouseEnter={() => addHighlight(data?.props?.nodeId)} onMouseLeave={() => removeHighlight(data?.props?.nodeId)}>
                  <label className="node">{data?.props?.node?.data?.nodeTitle}</label> <TbArrowForwardUp /> <BiLayout />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Handle id={`handle-${id}-top`} type="target" position={Position.Top} />
    </div>
  );
};

export default TransferNode;
