import "./index.css";
import "./assets/styles/atenas-ui.min.css";
import "./assets/styles/animate.css";
import "./assets/styles/styles.scss";

import FlowBuilder from "./components/FlowBuilder";
import { useState, useEffect } from "react";
import { getFlowById } from "./services/methods";
import { log } from "./services/utils";
import { TiWarningOutline } from "react-icons/ti";
import { Button } from "./components";
import { INode } from "./components/Nodes";
import { ReactFlowJsonObject } from "reactflow";

export interface IFlow {
  data: Object;
  layout: ReactFlowJsonObject;
  config: IFlowConfig;
}

export interface IFlowConfig {
  _id: string;
  nome?: string;
  descricao?: string;
  objeto?: string;
  _updatedAt?: string;
  ultimoEditor?: IFlowEditor;
  edits?: IFlowEdit[];
  uniqueEditors?: IFlowEdit[];
}

export interface IFlowEdit {
  _updatedAt: string;
  editor: IFlowEditor;
  type?: "simple" | "complex";
}

export interface IFlowEditor {
  _id: string;
  name: string;
  username: string;
}

export interface IChannel {
  _id: string;
  descricao?: string;
  identificador?: string;
  status?: string;
  tipo?: string;
  _updatedAt?: string;
  fluxo: IFlowConfig;
}

export interface IError {
  level: number;
  message: string;
  nodeId: string;
  node: INode;
  handleId: string | null;
}

function App() {
  const fetchTheme = () => {
    const value = localStorage.getItem("convert-flowbuilder-darkmode") ?? "";
    if (value === "true") {
      document.body.setAttribute("at-dark", "true");
    } else {
      document.body.removeAttribute("at-dark");
    }
    if (value) {
      return value === "true";
    } else {
      return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
  };

  const [dark, setDark] = useState<boolean>(fetchTheme());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [flow, setFlow] = useState<IFlow>({ data: {}, layout: { nodes: [], edges: [], viewport: { x: 0, y: 0, zoom: 0.5 } }, config: {} as any });

  useEffect(() => {
    localStorage.setItem("convert-flowbuilder-darkmode", String(dark));
    if (dark) {
      document.body.setAttribute("at-dark", "true");
    } else {
      document.body.removeAttribute("at-dark");
    }
  }, [dark]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await getFlowById(globalThis.flowId);
      if (!data) throw new Error("Erro");
      if (data.edits) {
        data.uniqueEditors = Object.values(
          data?.edits?.reduce((acc: any, obj: IFlowEdit) => {
            if (!acc[obj.editor._id] || acc[obj.editor._id]._updatedAt < obj._updatedAt) {
              acc[obj.editor._id] = obj;
            }
            return acc;
          }, {})
        );
        data.uniqueEditors = data.uniqueEditors.sort((a: IFlowEdit, b: IFlowEdit) => new Date(b._updatedAt).getTime() - new Date(a._updatedAt).getTime());
      }
      if (data.objeto) {
        const obj = JSON.parse(data.objeto);
        setFlow({ ...obj, config: data });
      } else {
        setFlow({ ...{ data: {}, layout: { nodes: [], edges: [], viewport: { x: 0, y: 0, zoom: 0.5 } } }, config: data });
      }
      setLoading(false);
    };

    fetchData().catch((err) => {
      log("error", "Erro ao buscar dados", err);
      setError(true);
      setLoading(false);
    });
  }, []);

  return (
    <div className="app" id="app" at-dark={dark ? "true" : null}>
      {loading && !flow?.config?._id ? (
        <>
          <div className="at-loading" at-active="true">
            <div className="at-loading-container">
              <div className="at-loading-animation"></div>
              <h1 at-tx="white">Carregando!</h1>
              <p at-tx="transparent-white">Por favor, aguarde...</p>
            </div>
          </div>
          <div className="app-page">
            <div className="app-page-content">
              <FlowBuilder setDark={setDark} dark={dark} flowData={flow} />
            </div>
          </div>
        </>
      ) : error ? (
        <div className="app-page">
          <svg className="react-flow__background background">
            <pattern id="pattern-1" x="0" y="0" width="24" height="24" patternUnits="userSpaceOnUse" patternTransform="translate(-1,-1)">
              <circle cx="1" cy="1" r="1" fill="#95a7b888"></circle>
            </pattern>
            <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-1)"></rect>
          </svg>
          <div className="at-u-absolute-center at-u-p3" at-center="true">
            <div className="error-sign larger" at-shadow="amber-400">
              <TiWarningOutline />
            </div>
            <h1>Erro ao buscar fluxo!</h1>
            <p>Ocorreu um erro ao buscar o fluxo</p>
            <br />
            {globalThis?.host ? (
              <a href={`https://${globalThis?.host}/omnichannel/omnichannel-flows/`} target="_blank" rel="noreferrer">
                <Button label="Voltar para o Omnichannel" outlined={true} />
              </a>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="app-page">
          <div className="app-page-content">
            <FlowBuilder setDark={setDark} dark={dark} flowData={flow} />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
