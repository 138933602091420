import { useEffect, useState } from "react";
import { uuid } from "../services/methods";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";

export interface ISelectItem {
  id?: string;
  value: string;
  label: string;
  description?: string;
  icon?: React.ReactNode;
  badge?: React.ReactNode;
}

export interface ISelect {
  options: Array<ISelectItem>;
  value?: string;
  placeholder?: string;
  onChange?: (value: string, option: ISelectItem) => void;
}

const Select = (props: ISelect) => {
  const { options, value, placeholder, onChange = () => {} } = props;
  const [selectedOption, setSelectedOption] = useState<ISelectItem>({ id: "", value: "", label: "" });

  useEffect(() => {
    if (value) {
      const o = options.find((option: ISelectItem) => option.value === value);
      if (o) setSelectedOption(o);
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      const o = options.find((option: ISelectItem) => option.value === value);
      if (o) setSelectedOption(o);
    }
  }, [options.length]);

  const handleChange = (option: ISelectItem) => {
    setSelectedOption(option);
    onChange(option.value, option);
  };

  return (
    <div className="at-select">
      <Listbox value={selectedOption} onChange={handleChange} key={uuid()}>
        <Listbox.Button className="at-select-menu">
          {selectedOption.id ? (
            <>
              {selectedOption.icon && selectedOption.icon}
              <span>{selectedOption.label}</span>
            </>
          ) : (
            <span className="placeholder">{placeholder || "Selecione uma opção"}</span>
          )}
          <ChevronUpDownIcon className="chevron" />
        </Listbox.Button>
        <Listbox.Options className="at-select-options">
          {options
            .filter((option: ISelectItem) => option.id !== undefined)
            .map((option: ISelectItem) => (
              <Listbox.Option key={option.id} value={option} data-selected={option.id === selectedOption?.id}>
                {option.icon && option.icon}
                <div className="at-u-flex-grow">
                  <span>{option.label}</span>
                  <small>{option.description}</small>
                </div>
                {option.id === selectedOption?.id && <CheckIcon className="selected-icon" />}
                {option.badge && <div>{option.badge}</div>}
              </Listbox.Option>
            ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default Select;
