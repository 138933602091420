import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

interface ISearchableSelectItem {
  id: string;
  label?: string;
  description?: string;
  value: string;
  leftItem?: ReactNode;
  rightItem?: ReactNode;
  data?: any;
}

interface ISearchableSelect {
  getData: (query: string) => Promise<any> | void;
  transformData: (data: any) => any;
  value?: string;
  onChange: (value: string, option: ISearchableSelectItem) => void;
  extraOptions?: Array<ISearchableSelectItem>;
}

const SearchableSelect = (props: ISearchableSelect) => {
  const { getData, transformData, value, onChange, extraOptions = [] } = props;
  const [selectedOption, setSelectedOption] = useState<ISearchableSelectItem>({ id: "", value: "" });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");
  const searchInput = useRef<HTMLInputElement>(null);

  const fetchData = async () => {
    setLoading(true);
    const data = await getData(query);
    let opts = transformData(data);
    opts = [...extraOptions, ...opts];
    setOptions(opts);
    if (value) {
      setSelectedOption(opts.find((option: ISearchableSelectItem) => option.value === value));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (query === "") {
      fetchData();
    } else {
      const timeout = setTimeout(() => fetchData(), 300);
      return () => clearTimeout(timeout);
    }
  }, [query]);

  return (
    <div className="at-select at-select-searchable">
      <Combobox
        value={selectedOption}
        onChange={(option: ISearchableSelectItem) => {
          setSelectedOption(option);
          searchInput?.current?.blur();
          onChange(option.value, option);
        }}
      >
        {({ open }) => (
          <>
            <Combobox.Button
              as="div"
              onClick={(e: MouseEvent) => {
                if (open) {
                  e.preventDefault();
                }
              }}
              className="at-select-input-container"
            >
              <Combobox.Input
                ref={searchInput}
                onChange={(event) => setQuery(event.target.value)}
                onFocus={() => setQuery("")}
                displayValue={(option: ISearchableSelectItem) => {
                  if (open || !option?.label) {
                    return "";
                  }
                  return option?.label;
                }}
                key={open as any}
                className="at-select-input"
                placeholder={loading ? "Carregando..." : "Buscar"}
              />
              <ChevronUpDownIcon className="chevron" />
            </Combobox.Button>
            {loading && open ? (
              <motion.div className="at-select-options" initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <li data-disabled="true">Buscando resultados...</li>
              </motion.div>
            ) : !loading && open && !options.length ? (
              <motion.div className="at-select-options" initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <li data-disabled="true">Nenhum resultado encontrado!</li>
              </motion.div>
            ) : (
              <Combobox.Options className="at-select-options">
                {options.map((option: ISearchableSelectItem) => (
                  <Combobox.Option key={option.id} value={option} as="div">
                    {({ active, selected }) => (
                      <>
                        <li data-selected={selected} data-active={active}>
                          {option.leftItem && option.leftItem}
                          <div className="at-u-flex-grow">
                            <span>{option.label}</span>
                            <small>{option.description}</small>
                          </div>
                          {selected && <CheckIcon className="selected-icon" />}
                          {option.rightItem && <div>{option.rightItem}</div>}
                        </li>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </>
        )}
      </Combobox>
    </div>
  );
};

export default SearchableSelect;
