import { useState, createElement, DragEvent, FormEvent } from "react";
import { ICategory, IIntegration, cards, categories, integrations } from "../../services/constants";
import { MdOutlineDragIndicator } from "react-icons/md";
import { TbChevronsLeft, TbLayout } from "react-icons/tb";
import { BiGitBranch, BiSearch, BiStore } from "react-icons/bi";
import type { INodeData } from "../../components/Nodes";
import Tippy from "@tippyjs/react";

const { version } = require("../../../package.json");

const Sidebar = () => {
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("blocks");
  const versionType = window.location.pathname.includes("beta") ? "beta" : window.location.pathname.includes("alpha") ? "alpha" : window.location.pathname.includes("dev") ? "dev" : "";
  const versionCode = version;
  const [compacted, setCompacted] = useState(false);
  return (
    <aside className="app-sidebar animate__fadeInLeft animate__animated" data-compact={compacted ? "true" : null}>
      {versionType ? (
        <div className="header-gradient-alternate">
          <div className={`py-4 px-3 flex items-center gap-3 select-none ${compacted ? "flex-col !px-2 !py-3" : ""}`}>
            <div className="command-icon border border-sky-300" at-accent="sky">
              <BiGitBranch size={20} />
            </div>
            <h3 className="font-semibold grow whitespace-nowrap">
              Flowbuilder <small className="px-1.5 text-xs font-semibold rounded-full bg-sky-400 border border-sky-300">{versionType}</small>
            </h3>
            <small className="text-sky-50 font-semibold">v{versionCode}</small>
          </div>
          <div className="at-u-border-bottom bg-sky-700/50 p-1.5 flex items-center gap-1 select-none ">
            <div className={`tab flex items-center gap-2 p-1 pr-2  hover:bg-sky-500 text-sky-100  rounded-md text-xs ${activeTab === "blocks" ? "border bg-sky-500 border-sky-400/30" : ""}`} onClick={() => setActiveTab("blocks")}>
              <TbLayout size="16" />
              <span>Blocos</span>
            </div>
            <div className={`tab flex items-center gap-2 p-1 pr-2  hover:bg-sky-500 text-sky-100  rounded-md text-xs ${activeTab === "integrations" ? "border bg-sky-500 border-sky-400/30" : ""}`} onClick={() => setActiveTab("integrations")}>
              <BiStore size="16" />
              <span>Integrações</span>
            </div>
            {!compacted && <div className="grow"></div>}
            <div className="collapse-icon bg-sky-500" onClick={() => setCompacted((compacted) => !compacted)}>
              <TbChevronsLeft size={18} />
            </div>
          </div>
        </div>
      ) : (
        <div className="header-gradient-official">
          <div className={`py-4 px-3 flex items-center gap-3 select-none ${compacted ? "flex-col !px-2 !py-3" : ""}`}>
            <div className="command-icon border border-teal-400" at-accent="teal">
              <BiGitBranch size={20} />
            </div>
            <h3 className="font-semibold grow">Flowbuilder</h3>
            <small className="text-teal-100/80 font-semibold">v{versionCode}</small>
          </div>
          <div className="at-u-border-bottom bg-teal-700/50 p-1.5 flex items-center gap-1 select-none ">
            <div className={`flex tab items-center gap-2 p-1 pr-2  hover:bg-teal-500 text-teal-100  rounded-md text-xs ${activeTab === "blocks" ? "border bg-teal-500 border-teal-400/30" : ""}`} onClick={() => setActiveTab("blocks")}>
              <TbLayout size="16" />
              <span>Blocos</span>
            </div>
            <div className={`flex tab items-center gap-2 p-1 pr-2  hover:bg-teal-500 text-teal-100  rounded-md text-xs ${activeTab === "integrations" ? "border bg-teal-500 border-teal-400/30" : ""}`} onClick={() => setActiveTab("integrations")}>
              <BiStore size="16" />
              <span>Integrações</span>
            </div>
            {!compacted && <div className="grow"></div>}
            <div className="collapse-icon bg-teal-500" onClick={() => setCompacted((compacted) => !compacted)}>
              <TbChevronsLeft size={18} />
            </div>
          </div>
        </div>
      )}
      <div className="p-3 at-u-border-bottom search-field">
        <div className="relative">
          <input type="text" placeholder="Buscar blocos..." value={search} onChange={(e: FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value)} className="bg-[#334455] h-[34px] p-2 w-full rounded-lg border border-[#445566] placeholder:text-[#778899] placeholder:font-medium placeholder:text-xs text-white pr-8 shadow-sm focus:border-[#556677]" />
          <BiSearch className="absolute right-2 top-2 text-slate-500" size="20" />
        </div>
      </div>
      <div className="app-sidebar-items ">
        {activeTab === "blocks"
          ? categories.map((category: ICategory, index: number) => SidebarCategory(category, search, index, compacted))
          : activeTab === "integrations"
          ? integrations.map((integration: IIntegration, index: number) => {
              return (
                <div className="category" at-accent="pink" data-expanded={true} key={index}>
                  <div className="title">
                    <div className="at-u-flex-grow at-u-flex at-u-items-center">
                      <img src={require(`../../assets/icons/${integration.id}.png`)} alt={integration?.id} />
                      <label>{integration.name}</label>
                    </div>
                  </div>
                  <div className="children">{integration.cards.filter((item: INodeData) => item.label.toLowerCase().includes(search) || item?.description?.toLowerCase().includes(search) || search === "").map((item: INodeData, key: number) => SidebarItem(item, key, compacted))}</div>
                </div>
              );
            })
          : null}
      </div>
    </aside>
  );
};

const SidebarCategory = (category: ICategory, search: string, index: number, compacted: boolean) => {
  return (
    <div className="category" at-accent={category.color} data-expanded={true} key={index}>
      <div className="title">
        <div className="at-u-flex-grow at-u-flex at-u-items-center">
          <div className="select-color" at-accent={category.color} at-bg="primary"></div>
          <label>{category.label}</label>
        </div>
      </div>
      <div className="children">{cards.filter((item: INodeData) => item.category === category.id && (item.label.toLowerCase().includes(search) || item?.description?.toLowerCase().includes(search) || search === "")).map((item: INodeData, key: number) => SidebarItem(item, key, compacted))}</div>
    </div>
  );
};

const SidebarItem = (item: INodeData, key: number, compacted: boolean) => {
  const onDragStart = (event: DragEvent) => {
    if (!event.dataTransfer) return;
    event.dataTransfer.setData("application/reactflow", `${item.category}-node`);
    event.dataTransfer.setData("application/reactflow/item", JSON.stringify(item));
    event.dataTransfer.effectAllowed = "move";
  };
  return (
    <Tippy
      className="at-tooltip remove-arrow"
      key={key}
      placement={compacted ? "right" : "top"}
      hideOnClick={true}
      content={
        <small>
          <b at-tx="white">{item.label}</b>
          <br />
          {item.description}
        </small>
      }
    >
      <div className="item" onDragStart={onDragStart} draggable>
        <MdOutlineDragIndicator />
        {item.icon && <div className="icon">{createElement(item.icon, { color: "currentColor" })}</div>}
        <label className="at-u-flex-grow">{item.label}</label>
      </div>
    </Tippy>
  );
};

export default Sidebar;
