import Bold from "@tiptap/extension-bold";
import Document from "@tiptap/extension-document";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { EditorContent, useEditor } from "@tiptap/react";
import { useEffect } from "react";
import type { Content } from "@tiptap/react";

interface IRichPreview {
  content: Content;
}
const RichPreview = (props: IRichPreview) => {
  const { content } = props;
  const editor = useEditor({
    editable: false,
    extensions: [
      Document,
      Paragraph,
      Text,
      Bold,
      Italic,
      Link,
      Mention.configure({
        HTMLAttributes: {
          class: "variable",
        },
        renderLabel({ node }: any) {
          return `${node.attrs.id}`;
        },
        suggestion: {
          char: "$",
        },
      }),
    ],
    content: content,
  });

  useEffect(() => {
    if (content) editor?.chain()?.setContent(content).run();
  }, [content]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <EditorContent editor={editor} />
    </>
  );
};

export default RichPreview;
