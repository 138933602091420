import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { ReactNode, useEffect, useState } from "react";
import { getVariables, uuid } from "../services/methods";
import { TbTags } from "react-icons/tb";
import { log } from "../services/utils";

interface IVariableSelectItem {
  id: string;
  label: string;
  description?: string;
  value: string;
  badge?: ReactNode;
  icon?: ReactNode;
}

interface IVariableSelect {
  value?: string;
  onChange: (value: string, option: IVariableSelectItem) => void;
  typeId?: string;
  showVariableForm?: boolean;
  setShowVariableForm?: (value: boolean) => void;
}
const VariableSelect = (props: IVariableSelect) => {
  const { value, onChange, typeId = "", showVariableForm, setShowVariableForm = () => {} } = props;
  const [selectedOption, setSelectedOption] = useState<IVariableSelectItem>({ id: "", label: "", value: "" });
  const [variables, setVariables] = useState<Array<IVariableSelectItem>>([]);
  const [loading, setLoading] = useState(false);

  const isReserved = ["origem", "destino", "id_whats", "canal_destino", "tipo_mensagem", "initial_message", "last_message", "selected_option", "http_request_status_code", "roomId", "current_date", "current_time", "current_timestamp"];
  const checkReserved = ["request-result-key", "variable-to-set", "input-variable"];

  const fetchData = async () => {
    setLoading(true);
    let res = await getVariables();
    res = [
      ...res,
      {
        _id: "initial_message",
        label: "Mensagem Inicial",
        scope: "room",
      },
      {
        _id: "last_message",
        label: "Ultima Mensagem",
        scope: "room",
      },
      {
        _id: "selected_option",
        label: "Opção Selecionada",
        scope: "room",
      },
      {
        _id: "http_request_status_code",
        label: "Código Status HTTP",
        scope: "room",
      },
      {
        _id: "current_date",
        label: "Data Atual",
        scope: "room",
      },
      {
        _id: "current_time",
        label: "Hora Atual",
        scope: "room",
      },
      {
        _id: "current_timestamp",
        label: "Timestamp Atual",
        scope: "room",
      },
      {
        _id: "roomId",
        label: "ID da Sala",
        scope: "room",
      },
    ];
    let data = res
      .map((v: any, i: number) => {
        if (!checkReserved.includes(typeId) || !isReserved.includes(v._id)) {
          return {
            id: i + 10,
            label: v.label,
            value: "$" + v._id,
            description: "$" + v._id,
            type: v.type || "",
            badge: (
              <div className="at-badge" data-scope={v.scope} at-bg="primary">
                {v.scope}
              </div>
            ),
          };
        } else {
          return false;
        }
      })
      .sort((a: IVariableSelectItem, b: IVariableSelectItem) => a.label?.localeCompare(b?.label));
    setVariables(data);
    setLoading(false);
    if (value) {
      const o = data.find((option: IVariableSelectItem) => option.value === value);
      if (o) setSelectedOption(o);
    }
  };

  useEffect(() => {
    fetchData().catch((err) => {
      log("error", "Erro ao buscar dados", err);
    });
    if (value) {
      const o = variables.find((option: IVariableSelectItem) => option.value === value);
      if (o) setSelectedOption(o);
    }
  }, []);

  useEffect(() => {
    if (!showVariableForm) {
      fetchData().catch((err) => {
        log("error", "Erro ao buscar dados", err);
      });
    }
  }, [showVariableForm]);

  useEffect(() => {
    onChange(selectedOption.value, selectedOption);
  }, [selectedOption]);

  return (
    <div className="at-select">
      <Listbox value={selectedOption} onChange={setSelectedOption} key={uuid()}>
        <Listbox.Button className="at-select-menu">
          {selectedOption.id ? (
            <>
              {selectedOption.icon && selectedOption.icon}
              <span>{selectedOption.label}</span>
            </>
          ) : (
            <span className="placeholder">{loading ? "Carregando..." : "Selecione uma variável"}</span>
          )}
          <ChevronUpDownIcon className="chevron" />
        </Listbox.Button>
        <Listbox.Options className="at-select-options">
          <Listbox.Option value={"create-new-variable"} onClick={() => setShowVariableForm(true)}>
            <TbTags />
            <div className="at-u-flex-grow">
              <span>Nova Variável</span>
              <small>Cadastrar uma nova variável</small>
            </div>
          </Listbox.Option>
          {variables
            .filter((option: IVariableSelectItem) => option.id !== undefined)
            .map((option: IVariableSelectItem) => (
              <Listbox.Option key={option.id} value={option} data-selected={option.id === selectedOption.id}>
                {option.icon && option.icon}
                <div className="at-u-flex-grow">
                  <span>{option.label}</span>
                  <small>{option.description}</small>
                </div>
                {option.id === selectedOption.id && <CheckIcon className="selected-icon" />}
                {option.badge && <div>{option.badge}</div>}
              </Listbox.Option>
            ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default VariableSelect;
