import { Action, INode, Transfer, Condition, Menu, Anchor, Start } from "./Nodes";
import { useReactFlow } from "reactflow";

interface INodePreviewProps {
  nodeId: string;
}

const NodePreview = (props: INodePreviewProps) => {
  const { nodeId } = props;
  const { getNode } = useReactFlow();
  const node = getNode(nodeId) as INode;
  if (!node) return <></>;
  if (node?.data?.id === "anchor-node") {
    return (
      <div className="react-flow__node react-flow__node-anchor-node">
        {/* @ts-ignore */}
        <Anchor data={node?.data} />
      </div>
    );
  }
  if (node?.data?.id === "anchor-node") {
    return (
      <div className="react-flow__node react-flow__node-anchor-node">
        {/* @ts-ignore */}
        <Anchor data={node?.data} />
      </div>
    );
  }
  if (node?.id === "start") {
    return (
      <div className="react-flow__node react-flow__node-start-node">
        {/* @ts-ignore */}
        <Start data={node?.data} />
      </div>
    );
  }
  return (
    <div className="react-flow__node react-flow__node-menu-node react-flow__node-action-node react-flow__node-transfer-node react-flow__node-condition-node ">
      {/* @ts-ignore */}
      {node?.data?.category === "transfer" && <Transfer data={node?.data} />}
      {/* @ts-ignore */}
      {node?.data?.category === "action" && <Action data={node?.data} />}
      {/* @ts-ignore */}
      {node?.data?.category === "condition" && <Condition data={node?.data} />}
      {/* @ts-ignore */}
      {node?.data?.category === "menu" && <Menu data={node?.data} />}
      {/* @ts-ignore */}
    </div>
  );
};

export default NodePreview;
