import type { DragEvent, MouseEvent } from "react";
import type { Edge, Node, NodeDragHandler, OnEdgesDelete, SelectionDragHandler, XYPosition } from "reactflow";
import { useState, useCallback, useRef, useEffect } from "react";
import ReactFlow, { Controls, Background, applyNodeChanges, applyEdgeChanges, addEdge, Connection, ReactFlowProvider, ConnectionMode, NodeChange, EdgeChange, MarkerType, ReactFlowInstance, MiniMap, SelectionMode } from "reactflow";
import { Start, Menu, Action, Transfer, Anchor, Condition, Comment, INode, INodeProps } from "../Nodes";
import { contextMenu, dialog, exportFlow, saveFlow, uuid } from "../../services/methods";
import { SmartStepEdge } from "@tisoap/react-flow-smart-edge";
import toast, { Toaster } from "react-hot-toast";
import useUndoRedo from "../../services/hooks/useUndoRedo";

import NodeSidebar from "../NodeSidebar";

import "reactflow/dist/style.css";
import "../../assets/styles/nodes.scss";
import Navbar from "../../layout/Navbar";
import ErrorModal from "../ErrorModal";

import { getHelperLines, log } from "../../services/utils";
import Sidebar from "../../layout/Sidebar";
import type { IFlow } from "../../App";
import { toastContainerStyle, toastOptions } from "../../services/constants";
import { CommandMenu } from "../Command";
import { AnimatePresence } from "framer-motion";
import { HelperLines } from "..";

const nodeTypes = {
  "start-node": Start,
  "menu-node": Menu,
  "action-node": Action,
  "transfer-node": Transfer,
  "anchor-node": Anchor,
  "condition-node": Condition,
  "comment-node": Comment,
};

const edgeTypes = {
  "smart-edge": SmartStepEdge,
};

const initialNodes: Node[] = [
  {
    id: "start",
    type: "start-node",
    position: { x: 25, y: 25 },
    deletable: false,
    data: {
      props: {
        nodeTitle: "Inicio do Fluxo",
      },
    },
  },
];

const initialEdges: Edge[] = [];

interface IFlowBuilder {
  setDark: (dark: boolean) => void;
  flowData: IFlow; //TODO - Documentar o tipo
  dark: boolean;
}
const Flow = (props: IFlowBuilder) => {
  const { setDark, flowData, dark } = props;
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [helperLineHorizontal, setHelperLineHorizontal] = useState<number | undefined>(undefined);
  const [helperLineVertical, setHelperLineVertical] = useState<number | undefined>(undefined);
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>(initialEdges);
  const [activeNode, setActiveNode] = useState<Node | {}>({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState([]);
  const [page, setPage] = useState("map");
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const mousePosition = useRef<XYPosition>({ x: 0, y: 0 });
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();

  /** Evento de mudança nos nodes */
  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      setNodes((nds) => customApplyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  /** Evento de mudança nos edges */
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  /** Aplicar node changes e gerenciar helper lines */
  const customApplyNodeChanges = useCallback((changes: NodeChange[], nodes: Node[]): Node[] => {
    // reset the helper lines (clear existing lines, if any)
    setHelperLineHorizontal(undefined);
    setHelperLineVertical(undefined);

    // this will be true if it's a single node being dragged
    // inside we calculate the helper lines and snap position for the position where the node is being moved to
    if (changes.length === 1 && changes[0].type === "position" && changes[0].dragging && changes[0].position) {
      const helperLines = getHelperLines(changes[0], nodes);

      // if we have a helper line, we snap the node to the helper line position
      // this is being done by manipulating the node position inside the change object
      changes[0].position.x = helperLines.snapPosition.x ?? changes[0].position.x;
      changes[0].position.y = helperLines.snapPosition.y ?? changes[0].position.y;

      // if helper lines are returned, we set them so that they can be displayed
      setHelperLineHorizontal(helperLines.horizontal);
      setHelperLineVertical(helperLines.vertical);
    }

    return applyNodeChanges(changes, nodes);
  }, []);

  /** Evento de conexao de um node com outro */
  const onConnect = useCallback(
    (connection: Connection) => {
      if (connection.source !== connection.target && connection.target !== "start") {
        const isTargetItem = document.querySelector(`[data-item-handle-id='${connection.targetHandle}']`);
        if (!isTargetItem) {
          if (!checkConnection(connection, edges)) {
            takeSnapshot();
            setEdges((eds) => addEdge({ ...connection, animated: true, type: "smoothstep", markerEnd: { type: MarkerType.Arrow } }, eds));
            log("success", "Dois blocos foram conectados\n", connection.source + " => ", connection.target);
          } else {
            log("warning", "Usuário tentou conectar um bloco já conectado", connection.source + " => ", connection.target);
          }
        }
      }
      checkErrors();
    },
    [setEdges, edges, nodes, takeSnapshot]
  );

  /** Testando se o node ja foi conectado com outro */
  const checkConnection = useCallback(
    (connection: Connection, edges: Edge[]) => {
      const node = nodes.find((node: Node) => node.id === connection?.source);
      const connections = edges.filter((edge: Edge) => {
        return edge?.source === connection?.source;
      });
      const hasHandles = ["user-input", "new-http-request", "multiple-conditions", "condition-initial-message", "date-hour-block", "yes-or-no-menu", "inactive-test-menu", "custom-menu", "button-menu", "list-menu", "text-menu", "send-email"];
      if (hasHandles.includes(node?.data?.id)) {
        const handleConnections = edges.filter((edge: Edge) => {
          return edge?.sourceHandle === connection?.sourceHandle;
        });
        return handleConnections.length > 0;
      } else {
        return connections.length > 0;
      }
    },
    [nodes]
  );

  /** Recebi um evento sobre um drag sobre o flowbuilder (deve vir do sidebar) */
  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "move";
    }
  }, []);

  /** Recebi um evento sobre um drop sobre o flowbuilder (deve vir do sidebar) vou adicionar o node */
  const onDrop = useCallback(
    async (event: DragEvent) => {
      event.preventDefault();
      if (!event.dataTransfer || !reactFlowWrapper.current) return;

      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
      let type = event.dataTransfer.getData("application/reactflow");
      const item = JSON.parse(event.dataTransfer.getData("application/reactflow/item"));
      if (type === "other-node") {
        type = item.id;
      }

      if (typeof type === "undefined" || !type) return;

      const position = reactFlowInstance?.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const node = {
        id: uuid(),
        type,
        position,
        data: item,
        ...item.options,
      };

      if (item.integration) {
        if (item.integration.id === "movidesk-get-ticket") {
          const token = await dialog({ title: "Token de API", description: "Por favor, para configurar essa integração informe o token de API do Movidesk, ou a variável aonde ele está armazenado", placeholder: "Ex : bbb0d13a82627a340e4d979e1adc66511111ee8e", buttonText: "Próximo" });
          const id = await dialog({ title: "ID do Ticket", description: "Agora informe a variável que irá mapear o código do ticket", placeholder: "Ex : $codigo_ticket", buttonText: "Finalizar!" });
          node.data.props.query = { token, id };
        }
      }
      takeSnapshot();
      setNodes((nds) => nds.concat(node));
      log("flowbuilder", "Novo node adicionado ao Flow", node);
      if (event.shiftKey) {
        onEditNode(event, node);
      }
    },
    [reactFlowInstance]
  );

  /** Evento de inicialização do React Flow, aqui chamo a função que busca o fluxo no banco */
  const initializeFlow = async (instance: ReactFlowInstance) => {
    log("flowbuilder", "Inicializando Flow Builder");
    setReactFlowInstance(instance);
    const nds = onRestore();
    if (nds) {
      const start = nds.find((node: INode) => node.id === "start");
      if (start) {
        const { x, y } = start.position;
        const { width, height } = start;
        instance.setCenter(x + (width || 0) / 2, y + (height || 0) / 2 + 200, { duration: 800, zoom: 1 });
      }
      setEdges((eds) =>
        eds.filter((ed) => {
          if (nds.find((node: INode) => node.id === ed.source) && nds.find((node: INode) => node.id === ed.target)) {
            return true;
          }
          return false;
        })
      );
    }
  };

  /** Função para salvar o fluxo */
  const onSave = useCallback(
    async (publish = false) => {
      const t = toast.loading(`${publish ? "Publicando" : "Salvando"} Fluxo...`);
      try {
        if (reactFlowInstance) {
          const layout = reactFlowInstance.toObject();
          const { data, errors } = exportFlow(layout);
          if (errors.length) {
            setErrors(errors);
            // attachErrors(errors);
          }
          if (data?.error) {
            toast.dismiss(t);
            log("error", `Erro ao ${publish ? "publicar" : "salvar"} fluxo de atendimento!`, data?.error);
            toast.error(`Erro ao ${publish ? "publicar" : "salvar"} o fluxo!`);
            return;
          }
          const store = { data, layout };
          const res = await saveFlow(store, flowData?.config, publish);
          flowData.config.ultimoEditor = res.ultimoEditor;
          flowData.config._updatedAt = res._updatedAt.$date;
          toast.dismiss(t);
          if (res) {
            log("success", `Fluxo de atendimento ${publish ? "publicado" : "salvo"} `, res, data);
            toast.success(`Fluxo ${publish ? "publicado" : "salvo"}!`);
          } else {
            log("success", "Erro ao salvar fluxo!");
            toast.error(`Erro ao ${publish ? "publicar" : "salvar"} o fluxo!`);
          }
        }
      } catch (err) {
        toast.dismiss();
        log("error", "Falha ao salvar fluxo de atendimento!", err);
        toast.error("Erro ao salvar o fluxo!");
      }
    },
    [reactFlowInstance, nodes]
  );

  /** Ativa o contextmenu no menu de seleção*/
  const onContextSelection = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const items = [
        {
          label: "Duplicar Seleção",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-copy" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path> <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path> </svg>`,
          onclick: () => {
            const selection = nodes?.filter((node: Node) => {
              if (node.selected && node.id !== "start") {
                node.selected = false;
                return true;
              }
              node.selected = false;
              return false;
            });
            setEdges((eds) =>
              eds.map((ed) => {
                return { ...ed, selected: false };
              })
            );
            const selectedNodes = JSON.parse(JSON.stringify(selection));
            if (selectedNodes) {
              const newEdges: Edge[] = [];
              const newNodes = selectedNodes.map((node: Node) => {
                const newNode = { ...node, id: uuid(), position: { x: node.position.x + 100, y: node.position.y + 100 }, selected: true, oldId: node.id };
                if (node.type === "other-node") {
                  newNode.type = node.data.id;
                }
                return newNode;
              });
              newNodes.forEach((_newNode: Node) => {
                const newNode = JSON.parse(JSON.stringify(_newNode));
                edges.forEach((_edge) => {
                  const edge = JSON.parse(JSON.stringify(_edge));
                  if (edge.source === newNode.oldId) {
                    const targetId = newNodes.find((nd: Node | any) => nd.oldId === edge.target)?.id;
                    const ed = {
                      ...edge,
                      id: uuid(),
                      target: edge.target === newNode.oldId ? newNode.id : newNodes.find((nd: Node | any) => nd.oldId === edge.target)?.id || "",
                      source: edge.source === newNode.oldId ? newNode.id : newNodes.find((nd: Node | any) => nd.oldId === edge.source)?.id || "",
                      sourceHandle: edge.sourceHandle ? edge.sourceHandle.replace(newNode.oldId, newNode.id) : edge.sourceHandle,
                      targetHandle: targetId ? edge.targetHandle?.replace(edge.target, targetId) : edge.targetHandle,
                      selected: true,
                    };
                    if (ed.target && ed.source && ed.sourceHandle && ed.targetHandle) {
                      newEdges.push(ed);
                    }
                  }
                });
              });
              setNodes((nds) => nds.concat(newNodes));
              setEdges((eds) => eds.concat(newEdges));
              log("flowbuilder", "Nova seleção duplicada", newNodes);
            }
          },
        },
        {
          label: "Copiar Seleção",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-copy" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h3m9 -9v-5a2 2 0 0 0 -2 -2h-2"></path> <path d="M13 17v-1a1 1 0 0 1 1 -1h1m3 0h1a1 1 0 0 1 1 1v1m0 3v1a1 1 0 0 1 -1 1h-1m-3 0h-1a1 1 0 0 1 -1 -1v-1"></path> <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path> </svg>`,
          onclick: () => {
            copySelection();
          },
        },
        {
          type: "separator",
        },
        {
          label: "Deletar Seleção",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>`,
          onclick: () => {
            const selectedNodes = nodes?.filter((node) => node.selected);
            selectedNodes?.forEach((node) => {
              if (node.id === "start") return;
              setNodes((nds) => nds.filter((n) => n.id !== node.id));
              setEdges((eds) => eds.filter((ed) => ed.source !== node.id && ed.target !== node.id));
            });
          },
        },
      ];
      contextMenu(event, { title: "Gerenciar seleção", items });
    },
    [nodes]
  );

  /** Ativa o contextmenu no menu*/
  const onContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const target = event?.target as HTMLElement;
      let selectedNode = nodes?.find((node) => node.selected);
      if (typeof target?.className === "string" && target?.className?.includes("custom-node")) {
        const id = target?.parentElement?.getAttribute("data-id");
        setNodes((nds) =>
          nds.map((nd) => {
            if (nd.id === id) {
              selectedNode = nd;
              return { ...nd, selected: nd.id === id };
            } else {
              return { ...nd, selected: false };
            }
          })
        );
      } else {
        setNodes((nds) =>
          nds.map((nd) => {
            return { ...nd, selected: false };
          })
        );
        selectedNode = undefined;
      }
      const items = [];
      if (selectedNode) {
        if (selectedNode.id === "start") return;
        items.push(
          {
            label: "Editar Bloco",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path> <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path> <path d="M16 5l3 3"></path> </svg>`,
            onclick: () => {
              onEditNode(event, selectedNode as INode);
            },
          },
          {
            label: "Duplicar Bloco",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-copy" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path> <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path> </svg>`,
            onclick: () => {
              duplicateNode(selectedNode as INode);
            },
          },
          {
            label: "Copiar Bloco",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-copy" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h3m9 -9v-5a2 2 0 0 0 -2 -2h-2"></path> <path d="M13 17v-1a1 1 0 0 1 1 -1h1m3 0h1a1 1 0 0 1 1 1v1m0 3v1a1 1 0 0 1 -1 1h-1m-3 0h-1a1 1 0 0 1 -1 -1v-1"></path> <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path> </svg>`,
            onclick: () => {
              copyNode(selectedNode as INode);
            },
          },
          {
            type: "separator",
          },
          {
            label: "Deletar Bloco",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>`,
            onclick: () => {
              setNodes((nds) => nds.filter((n) => n.id !== selectedNode?.id));
              setEdges((eds) => eds.filter((ed) => ed.source !== selectedNode?.id && ed.target !== selectedNode?.id));
              log("flowbuilder", "Node deletado", selectedNode);
            },
          }
        );
      } else {
        items.push(
          {
            label: "Colar",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-text" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path> <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path> <path d="M9 12h6"></path> <path d="M9 16h6"></path> </svg>`,
            onclick: () => {
              onPaste();
            },
          },
          {
            type: "separator",
          },
          {
            label: "Buscar",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path> <path d="M21 21l-6 -6"></path> </svg>`,
            onclick: () => {
              setOpenDialog(true);
            },
          },
          {
            type: "separator",
          },
          {
            label: "Salvar Fluxo",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-floppy" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path> <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path> <path d="M14 4l0 4l-6 0l0 -4"></path> </svg>`,
            onclick: () => {
              document.querySelector<HTMLElement>("#button-navbar-save")?.click();
            },
          },
          {
            label: "Publicar Fluxo",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-world-upload" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M21 12a9 9 0 1 0 -9 9"></path> <path d="M3.6 9h16.8"></path> <path d="M3.6 15h8.4"></path> <path d="M11.578 3a17 17 0 0 0 0 18"></path> <path d="M12.5 3c1.719 2.755 2.5 5.876 2.5 9"></path> <path d="M18 21v-7m3 3l-3 -3l-3 3"></path> </svg>`,
            onclick: () => {
              document.querySelector<HTMLElement>("#button-navbar-publish")?.click();
            },
          },
          {
            type: "separator",
          },
          {
            label: "Desfazer",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-back-up" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M9 14l-4 -4l4 -4"></path> <path d="M5 10h11a4 4 0 1 1 0 8h-1"></path> </svg>`,
            onclick: () => {
              undo();
            },
            disabled: canUndo,
          },
          {
            label: "Refazer",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-forward-up" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M15 14l4 -4l-4 -4"></path> <path d="M19 10h-11a4 4 0 1 0 0 8h1"></path> </svg>`,
            onclick: () => {
              redo();
            },
            disabled: canRedo,
          },
          {
            type: "separator",
          },
          {
            label: "Ajuda",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-help-circle" width="36" height="36" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path> <path d="M12 16v.01"></path> <path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path> </svg>`,
            onclick: () => {
              document.querySelector<HTMLElement>("#button-navbar-help")?.click();
            },
          }
        );
      }
      contextMenu(event, { displayHeader: false, items });
    },
    [nodes, canUndo, canRedo, redo, undo]
  );

  /** Função para restaurar o fluxo */
  const onRestore = useCallback(() => {
    try {
      log("flowbuilder", "Buscando dados do fluxo!");
      const flow = flowData?.layout;
      if (!flow) {
        log("warning", "Nenhum fluxo encontrado");
        return;
      }

      if (flow) {
        if (!flow.nodes.length) {
          flow.nodes = initialNodes;
        }
        setNodes(flow.nodes);
        setEdges(flow.edges);
        log("success", "Fluxo restaurado com sucesso!", flow);
      }
      return flow.nodes;
    } catch (err) {
      log("error", "Falha ao buscar fluxo de atendimento!", err);
    }
  }, [nodes, edges]);

  /** Evento disparado ao usuário deletar um node */
  const onNodesDelete = useCallback(
    async (deletedNodes: Node[]) => {
      if (showSidebar) return;
      takeSnapshot();
      await deletedNodes.map(async (node: Node) => {
        await setNodes((nds) => nds.filter((n) => n.id !== node.id));
        await setEdges((eds) => eds.filter((ed) => ed.source !== node.id && ed.target !== node.id));
      });
      checkErrors();
    },
    [nodes, showSidebar, takeSnapshot]
  );

  /** Evento quando arrastam nodes */
  const onNodeDragStart: NodeDragHandler = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  /** Evento quando arrastam selecao */
  const onSelectionDragStart: SelectionDragHandler = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  /** Evento disparado ao usuário deletar um edge */
  const onEdgesDelete: OnEdgesDelete = useCallback(() => {
    takeSnapshot();
  }, [takeSnapshot]);

  const checkErrors = () => {
    setTimeout(() => {
      const layout = reactFlowInstance?.toObject();
      if (!layout) return;
      const { errors } = exportFlow(layout);
      setErrors(errors);
      // attachErrors(errors);
    }, 500);
  };

  const attachErrors = (errors: any) => {
    document.querySelectorAll(".node-status")?.forEach((node) => {
      node?.removeAttribute("data-error-count");
      node?.removeAttribute("data-warning-count");
    });
    document.querySelectorAll(".node-errors-list > li")?.forEach((el) => el.remove());
    errors.map((error: any) => {
      const nodeElement = document.querySelector(`[data-id='${error?.nodeId}']`);
      const errors = parseInt(nodeElement?.querySelector(".data-error-count")?.getAttribute("data-error-count") ?? "0");
      const warnings = parseInt(nodeElement?.querySelector(".data-warning-count")?.getAttribute("data-warning-count") ?? "0");
      if (error.level === 1) {
        nodeElement?.querySelector(".data-warning-count")?.setAttribute("data-warning-count", String(warnings + 1));
        const warningsList = nodeElement?.querySelector(".node-status-list.node-warnings");
        const item = document.createElement("li");
        item.innerText = error.message;
        warningsList?.appendChild(item);
      } else if (error.level === 2) {
        nodeElement?.querySelector(".data-error-count")?.setAttribute("data-error-count", String(errors + 1));
      }
      const errorsList = nodeElement?.querySelector(".node-errors-list");
      const item = document.createElement("li");
      item.innerText = error.message;
      if (error.handleId) {
        item.addEventListener("mouseenter", (e) => {
          document.querySelector(`[data-item-handle-id='${error.handleId}']`)?.setAttribute("data-highlighted", "true");
          document.querySelector(`[data-item-handle-id='${error.handleId}']`)?.setAttribute("data-highlighted-error", "true");
        });
        item.addEventListener("mouseleave", (e) => {
          document.querySelector(`[data-item-handle-id='${error.handleId}']`)?.removeAttribute("data-highlighted");
          document.querySelector(`[data-item-handle-id='${error.handleId}']`)?.removeAttribute("data-highlighted-error");
        });
      }
      errorsList?.appendChild(item);
    });
  };

  const onEditNode = (e: MouseEvent | DragEvent, node: INode) => {
    const disallow = ["comment-node", "start-node"];
    if (node.type && !disallow.includes(node?.type)) {
      log("flowbuilder", "Editando um node", node);
      setActiveNode(node);
      setShowSidebar(true);
    }
  };

  const onClick = (e: MouseEvent, node: INode) => {
    const target = e?.target as HTMLElement;
    if (!target?.getAttribute("data-label")) return;
    const method = target?.getAttribute("data-label")?.replace(/\s/g, "-")?.toLowerCase();
    if (method === "editar-bloco") {
      onEditNode(e, node);
    } else if (method === "duplicar-bloco") {
      duplicateNode(node);
    } else if (method === "deletar-bloco") {
      setNodes((nds) => nds.filter((n) => n.id !== node.id));
      setEdges((eds) => eds.filter((ed) => ed.source !== node.id && ed.target !== node.id));
      log("flowbuilder", "Node deletado", node);
    } else if (method === "copiar-bloco") {
      copyNode(node);
    }
  };

  const copyNode = (node: INode) => {
    if (!node?.height || !node?.position || !node.positionAbsolute) return;
    const n = {
      id: uuid(),
      data: node.data,
      type: node.type,
    };
    navigator.clipboard.writeText(JSON.stringify(n));
    log("flowbuilder", "Node copiado para o clipboard", n);
    toast.success("Bloco copiado para o clipboard");
  };

  const copySelection = () => {
    const zoom = reactFlowInstance?.getZoom();
    const selectedNodes = nodes?.filter((node) => {
      if (node.selected && node.id !== "start") {
        node.selected = false;
        return true;
      }
      node.selected = false;
      return false;
    });
    if (selectedNodes) {
      const newEdges: Edge[] = [];
      const newNodes = selectedNodes.map((node) => {
        const newNode = { ...node, id: uuid(), position: { x: node.position.x + 100, y: node.position.y + 100 }, selected: true, oldId: node.id };
        if (node.type === "other-node") {
          newNode.type = node.data.id;
        }
        return newNode;
      });
      newNodes.forEach((newNode) => {
        edges.forEach((edge) => {
          if (edge.source === newNode.oldId) {
            const targetId = newNodes.find((nd) => nd.oldId === edge.target)?.id;
            const ed = {
              ...edge,
              id: uuid(),
              target: edge.target === newNode.oldId ? newNode.id : newNodes.find((nd) => nd.oldId === edge.target)?.id || "",
              source: edge.source === newNode.oldId ? newNode.id : newNodes.find((nd) => nd.oldId === edge.source)?.id || "",
              sourceHandle: edge.sourceHandle ? edge.sourceHandle.replace(newNode.oldId, newNode.id) : edge.sourceHandle,
              targetHandle: targetId ? edge.targetHandle?.replace(edge.target, targetId) : edge.targetHandle,
            };
            if (ed.target && ed.source && ed.sourceHandle && ed.targetHandle) {
              newEdges.push(ed);
            }
          }
        });
      });
      const clipboardData = JSON.stringify({ nodes: newNodes, edges: newEdges, zoom });
      navigator.clipboard.writeText(clipboardData);
      log("flowbuilder", "Seleção copiada para o clipboard", clipboardData);
      toast.success("Seleção copiada para o clipboard");
    }
  };

  const duplicateNode = (node: INode) => {
    if (!node?.height || !node?.position || !node.positionAbsolute) return;
    const { height } = node;
    const n = {
      id: uuid(),
      data: node.data,
      position: {
        x: node.position.x,
        y: node.position.y + height + 25,
      },
      positionAbsolute: {
        x: node.positionAbsolute.x,
        y: node.positionAbsolute.y + height + 25,
      },
      type: node.type,
    };
    const d = JSON.parse(JSON.stringify(n));
    takeSnapshot();

    setNodes((nds) => nds.concat(d));
    log("flowbuilder", "Node duplicado", n);
  };

  const onSaveNode = async (id: string, props: INodeProps) => {
    let refresh = false;

    setNodes((nds: Node[]) =>
      nds.map((n: INode) => {
        if (n.id === id) {
          if (n.data) {
            if (n.data.category === "menu") {
              const oldOrder = n.data.props?.options?.map((o: any) => o.id);
              const newOrder = props?.options?.map((o: any) => o.id);
              if (oldOrder?.join() !== newOrder?.join() && oldOrder?.length === newOrder?.length) {
                refresh = true;
              }
            }
            n.data.props = {
              ...props,
              ...{
                updatedAt: new Date().toISOString(),
              },
            };
          }
        }
        return n;
      })
    );
    setShowSidebar(false);
    await onSave(false);
    await checkErrors();
    log("flowbuilder", "Node foi editado e salvo!", props);
    if (refresh) {
      window.location.reload();
    }
  };

  const zoomToNode = (node: Node) => {
    if (node?.position) {
      setShowErrors(false);
      setPage("map");
      const { x, y } = node.position;
      const { width, height } = node;
      reactFlowInstance?.setCenter(x + (width || 0) / 2, y + (height || 0) / 2, { duration: 800 });
    }
  };

  const onPaste = () => {
    const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
    const calculateOffsetForPastedNodes = (clipboardNodes: any, firstNodePosition: any, mouseX: any, mouseY: any) => {
      const firstNode = clipboardNodes[0];
      const offsetX = mouseX - firstNodePosition.x;
      const offsetY = mouseY - firstNodePosition.y;
      const offsetBetweenFirstNodeAndOriginal = {
        x: firstNode.position.x - firstNodePosition.x,
        y: firstNode.position.y - firstNodePosition.y,
      };

      return { offsetX, offsetY, offsetBetweenFirstNodeAndOriginal };
    };
    if (!reactFlowBounds) return;
    const projection = reactFlowInstance?.project({
      x: mousePosition.current.x,
      y: mousePosition.current.y,
    });
    navigator.clipboard
      .readText()
      .then((text) => {
        const node = JSON.parse(text);
        if (node?.id) {
          const { data, type } = node;
          const n = {
            id: uuid(),
            data,
            position: projection,
            positionAbsolute: projection,
            type,
          };
          const d = JSON.parse(JSON.stringify(n));
          takeSnapshot();
          setNodes((nds) => nds.concat(d));
          log("flowbuilder", "Node colado", n);
          toast.success("Bloco colado");
        } else if (node?.nodes) {
          const { nodes, edges } = node;
          const newEdges: Edge[] = [];
          const firstNode = nodes[0];
          const offset = calculateOffsetForPastedNodes(nodes, firstNode.position, projection?.x, projection?.y);
          const newNodes = nodes.map((node: Node | any) => {
            const p = {
              x: node.position.x - offset.offsetBetweenFirstNodeAndOriginal.x + offset.offsetX,
              y: node.position.y - offset.offsetBetweenFirstNodeAndOriginal.y + offset.offsetY,
            };
            const newNode = { ...node, id: uuid(), position: p, positionAbsolute: p, selected: true, oldId: node.id };
            if (node.type === "other-node") {
              newNode.type = node.data.id;
            }
            return newNode;
          });
          newNodes.forEach((newNode: Node | any) => {
            edges.forEach((edge: Edge) => {
              if (edge.source === newNode.oldId) {
                const targetId = newNodes.find((nd: Node | any) => nd.oldId === edge.target)?.id;
                const ed = {
                  ...edge,
                  id: uuid(),
                  target: edge.target === newNode.oldId ? newNode.id : newNodes.find((nd: Node | any) => nd.oldId === edge.target)?.id || "",
                  source: edge.source === newNode.oldId ? newNode.id : newNodes.find((nd: Node | any) => nd.oldId === edge.source)?.id || "",
                  sourceHandle: edge.sourceHandle ? edge.sourceHandle.replace(newNode.oldId, newNode.id) : edge.sourceHandle,
                  targetHandle: targetId ? edge.targetHandle?.replace(edge.target, targetId) : edge.targetHandle,
                };
                if (ed.target && ed.source && ed.sourceHandle && ed.targetHandle) {
                  newEdges.push(ed);
                }
              }
            });
          });
          takeSnapshot();
          setNodes((nds) => nds.concat(newNodes));
          setEdges((eds) => eds.concat(newEdges));
          log("flowbuilder", "Seleção Colada", newNodes, newEdges);
          toast.success("Seleção Colada");
        }
      })
      .catch((err) => {
        log("error", "Falha ao colar node", err);
      });
  };

  useEffect(() => {
    const handleKeyDown = async (e: KeyboardEvent) => {
      if ((e.key === "k" || e.key === "f") && (e.metaKey || e.ctrlKey)) {
        if (e.repeat || showSidebar) return;
        e.preventDefault();
        setOpenDialog(true);
      }
      if (e.key === "c" && (e.metaKey || e.ctrlKey)) {
        const selectedNodes = nodes?.filter((node) => node.selected);
        if (selectedNodes?.length) {
          if (e.repeat || showSidebar) return;
          e.preventDefault();
          if (selectedNodes.length === 1) {
            copyNode(selectedNodes[0]);
          } else {
            copySelection();
          }
        }
      }
      if (e.key === "s" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        if (e.repeat) return;
        if (showSidebar) {
          document.querySelector<HTMLElement>("#button-sidebar-save")?.click();
        } else {
          if (e.shiftKey) {
            document.querySelector<HTMLElement>("#button-navbar-publish")?.click();
          } else {
            document.querySelector<HTMLElement>("#button-navbar-save")?.click();
          }
        }
      }
      if (e.key === "z" && (e.metaKey || e.ctrlKey)) {
        if (showSidebar) return;
        e.preventDefault();
        if (e.shiftKey) {
          redo();
        } else {
          undo();
        }
      }
      if (e.key === "1" && (e.metaKey || e.ctrlKey) && e.shiftKey) {
        if (e.repeat || showSidebar) return;
        e.preventDefault();
        document.querySelector<HTMLElement>("#button-navbar-test-whatsapp")?.click();
      }
      if (e.key === "2" && (e.metaKey || e.ctrlKey) && e.shiftKey) {
        if (e.repeat || showSidebar) return;
        e.preventDefault();
        document.querySelector<HTMLElement>("#button-navbar-test-telegram")?.click();
      }
      if (e.key === "p" && (e.metaKey || e.ctrlKey) && e.shiftKey) {
        if (e.repeat || showSidebar) return;
        e.preventDefault();
        document.querySelector<HTMLElement>("#button-navbar-download")?.click();
      }
      if (e.key === "e" && (e.metaKey || e.ctrlKey) && e.shiftKey) {
        if (e.repeat || showSidebar) return;
        e.preventDefault();
        document.querySelector<HTMLElement>(".navbar-button.error-sign")?.click();
        setTimeout(() => {
          document.querySelector<HTMLElement>(".at-modal button")?.focus();
        }, 200);
      }
      if (e.key === "Escape") {
        if (e.repeat) return;
        setOpenDialog(false);
        setShowErrors(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [nodes, showSidebar, showErrors, setOpenDialog, setShowErrors, activeNode]);

  useEffect(() => {
    if (reactFlowInstance) {
      checkErrors();
    }
  }, [reactFlowInstance]);

  useEffect(() => {
    if (reactFlowWrapper?.current) {
      const onMouseMove = (event: MouseEvent | any) => {
        const bounds = reactFlowWrapper?.current?.getBoundingClientRect();
        mousePosition.current = {
          x: event.clientX - (bounds?.left ?? 0),
          y: event.clientY - (bounds?.top ?? 0),
        };
      };

      reactFlowWrapper?.current?.addEventListener("mousemove", onMouseMove);
      return () => {
        reactFlowWrapper?.current?.removeEventListener("mousemove", onMouseMove);
      };
    }
  }, [reactFlowWrapper]);

  return (
    <>
      <Toaster toastOptions={toastOptions} containerStyle={toastContainerStyle} />

      <div className="app-flowbuilder">
        <div className="app-flow-builder-pages">
          <div className="app-flow-builder-page" data-active={page === "map" ? "true" : null}>
            <Sidebar />
            <div className="at-u-flex-grow at-u-flex at-u-flex-column at-u-relative">
              <AnimatePresence>{openDialog && <CommandMenu nodes={nodes} hide={() => setOpenDialog(false)} saveFlow={onSave} />}</AnimatePresence>
              <Navbar saveFlow={onSave} page={page} errors={errors} openLogs={() => setShowErrors(true)} openSearch={() => setOpenDialog(true)} setDark={setDark} dark={dark} flow={flowData?.config ?? {}} />
              <ReactFlow onPaste={onPaste} minZoom={0} onSelectionContextMenu={onContextSelection} onContextMenu={onContextMenu} onSelectionDragStart={onSelectionDragStart} onNodeDragStart={onNodeDragStart} onNodesDelete={onNodesDelete} onEdgesDelete={onEdgesDelete} ref={reactFlowWrapper} connectionMode={ConnectionMode.Loose} onNodeClick={onClick} onNodeDoubleClick={onEditNode} nodeTypes={nodeTypes} nodes={nodes} edges={edges} onNodesChange={onNodesChange} onInit={initializeFlow} onEdgesChange={onEdgesChange} onConnect={onConnect} snapToGrid={true} className="animate__fadeInUp animate__animated flowbuilder-container" snapGrid={[1, 1]} onDrop={onDrop} onDragOver={onDragOver} connectionRadius={30} deleteKeyCode={showSidebar ? [] : ["Backspace", "Delete"]} selectionMode={SelectionMode.Partial} autoPanOnNodeDrag={true} proOptions={{ hideAttribution: true }}>
                <Background gap={24} color={"#95a7b888"} size={2} />
                <MiniMap pannable={true} zoomable={true} />
                <Controls />
                <HelperLines horizontal={helperLineHorizontal} vertical={helperLineVertical} />
              </ReactFlow>
            </div>
          </div>
        </div>
        {showErrors && <ErrorModal errors={errors} zoomToNode={zoomToNode} hideModal={() => setShowErrors(false)} />}
        {showSidebar && <NodeSidebar data={activeNode} show={showSidebar} hideSidebar={() => setShowSidebar(false)} onSaveNode={onSaveNode} />}
      </div>
    </>
  );
};

const FlowBuilder = (props: IFlowBuilder) => {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
};

export default FlowBuilder;
